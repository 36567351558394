import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { camelCase, startCase, throttle } from 'lodash-es';
import moment from 'moment';
import s from './LastUpdated.module.scss';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { ProductForecastDataFreshnessDocument } from './gql/__generated__/productForecastDataFreshness.query';
import { convertWeekToPeriodWeekString } from 'common/helpers/fiscalCalendar';
import CheckmarkIcon from 'assets/icons/inventory/checkmark.svg?react';
import WarningIcon from 'assets/icons/inventory/warning.svg?react';
import { NOT_AVAILABLE } from 'common/constants';

const stringifyBackendValue = (text: string) => {
  const abbreviations = new Set(['DP', 'PO']);

  return text
    .split('_')
    .map((word) => {
      if (abbreviations.has(word)) return word;
      return startCase(camelCase(word));
    })
    .join(' ');
};

const formatString = 'M/D/YY h:mm A'; //TODO: do we have a helper function we can utilize for this conversion?
const MINIMUM_REFETCH_TIME = 30_000;

const FreshStatusIcon = ({ isFresh }: { isFresh: boolean }) => {
  return isFresh ? <CheckmarkIcon /> : <WarningIcon />;
};

export const LastUpdated = () => {
  const [isOpen, setOpen] = useState(false);
  const { data, loading, error, refetch } = useQuery(ProductForecastDataFreshnessDocument);

  // Refetch no more often than 1 time in MINIMUM_REFETCH_TIME.
  const throttledRefetch = useMemo(
    () => throttle(refetch, MINIMUM_REFETCH_TIME, { leading: true, trailing: false }),
    [refetch]
  );

  if (error) return <></>;
  const onOpenChange = (open: boolean) => {
    if (open) {
      throttledRefetch();
    }
    setOpen(open);
  };

  const isFresh = !!data?.productForecastDataFreshness?.every(
    (x) => x.isSnowflakeUpToDate && x.isSynchronizedWithSnowflake
  );

  return (
    <AlloyTooltip
      placement="bottomLeft"
      overlayClassName={s.overlay}
      open={isOpen}
      onOpenChange={(open) => onOpenChange(open)}
      overlay={
        <>
          {loading
            ? 'Loading...'
            : data?.productForecastDataFreshness && (
                <div className={s.overlay_wrapper}>
                  {' '}
                  {data?.productForecastDataFreshness.map((x) => (
                    <div key={x.dataReference} className={s.status}>
                      <span>{stringifyBackendValue(x.dataReference)}</span>
                      <div className={s.updated_string}>
                        {`${convertWeekToPeriodWeekString(x.latestPeriodWeek.week)} ${x.latestPeriodWeek.year}`}
                        : Ingested at{' '}
                        {x.lastIngestedTimestamp
                          ? moment(x.lastIngestedTimestamp).format(formatString)
                          : NOT_AVAILABLE}
                        <FreshStatusIcon
                          isFresh={x.isSnowflakeUpToDate && x.isSynchronizedWithSnowflake}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
        </>
      }
    >
      <span className={s.last_update_date} data-testid="inventory-visibility-last-updated">
        {loading ? (
          'Loading...'
        ) : (
          <>
            <span>{isFresh ? 'All sources up to date' : 'Delay in some sources'}</span>
            <FreshStatusIcon isFresh={isFresh} />
          </>
        )}
      </span>
    </AlloyTooltip>
  );
};
