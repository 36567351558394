import React, { ReactElement, ReactNode } from 'react';
import unauthorizedIcon from '@/src/assets/icons/errorState/unauthorized_icon.svg';
import genericErrorIcon from '@/src/assets/icons/errorState/generic_error_icon.svg';
import s from './ErrorState.module.scss';

export interface ErrorStateProps {
  type?: 'unauthorized' | 'generic';
  title?: string;
  explanation?: string;
  instruction?: ReactNode;
  buttons?: ReactElement;
  'data-testid'?: string;
}

const ErrorState = ({
  type = 'generic',
  title,
  explanation,
  instruction,
  buttons,
  'data-testid': dataTestId
}: ErrorStateProps) => {
  return (
    <div className={s.error_state} data-testid={dataTestId}>
      <div className={s.error_state_icon_container}>
        <img
          className={s.error_state_icon}
          src={type === 'unauthorized' ? unauthorizedIcon : genericErrorIcon}
          alt=""
        />
      </div>
      <div className={s.error_state_title}>{title ? title : 'Generic Error'}</div>
      <div className={s.error_state_text_container}>
        <div className={s.error_state_exp}>
          {explanation
            ? explanation
            : 'Our system encountered an unexpected error while processing your request.'}
        </div>
        <div className={s.error_state_inst}>
          {instruction ? (
            instruction
          ) : (
            <p>
              Please retry your request shortly. If the issue continues, clear your browser&apos;s
              cache and/or check your internet connection.
            </p>
          )}
        </div>
      </div>
      <div className={s.error_state_buttons}>{buttons}</div>
    </div>
  );
};

export default ErrorState;
