import { useQuery, useMutation } from '@apollo/client';
import { ORDER_ACCEPTED_CONFIRMATION_TYPE } from 'common/constants';
import { compareDates, dateFormat } from 'common/helpers/date';
import LoaderSpinner from 'components/LoaderSpinner';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ExpectedDeliveryDateField } from '../../../../../../components/PurchaseOrders/ExpectedDeliveryDate/ExpectedDeliveryDate';
import { SubmitPurchaseOrderConfirmationsDocument } from './gql/__generated__/submitPurchaseOrderConfirmations.mutation';
import {
  InitialConfirmationOrdersDocument,
  InitialConfirmationOrdersQuery
} from './gql/__generated__/initialConfirmationOrders.query';
import { InferNodeType } from 'common/helpers/mappingHelper';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { App } from 'ant5';
import { PurchaseOrderSearchCountsDocument } from 'pages/OrdersPage/components/OrdersSummary/gql/__generated__/purchaseOrderSearchCounts.query';
import { PurchaseOrderSearchListDocument } from 'pages/OrdersPage/gql/__generated__/purchaseOrderSearchList.query';

type PurchaseOrder = InferNodeType<InitialConfirmationOrdersQuery, 'purchaseOrders'>;
type PurchaseOrderConfirmation = PurchaseOrder['purchaseOrderConfirmations'][number];

interface ConfirmatingPurchaseOrder {
  id: string;
  customerPo: string;
  orderDate: string;
  deliveryWindowEnd: string;
  confirmation: PurchaseOrderConfirmation;
}

interface ConfirmationModalProps {
  purchaseOrderIds: string[];
  setShowConfirmationModal: (value: boolean) => void;
}

export const ConfirmationModal = ({
  // maybe rename this to BulkProcessConfirmationModal to not get confused by common ConfirmationModal component
  purchaseOrderIds,
  setShowConfirmationModal
}: ConfirmationModalProps) => {
  const { message } = App.useApp();
  const [purchaseOrders, setPurchaseOrders] = useState<ConfirmatingPurchaseOrder[]>([]);
  const [selectedRows, setSelectedRows] = useState<ConfirmatingPurchaseOrder[]>([]);

  const { data, loading } = useQuery(InitialConfirmationOrdersDocument, {
    variables: {
      ids: purchaseOrderIds,
      first: 1000
    }
  });

  const [sendConfirmation] = useMutation(SubmitPurchaseOrderConfirmationsDocument, {
    refetchQueries: [PurchaseOrderSearchListDocument, PurchaseOrderSearchCountsDocument]
  });

  useEffect(() => {
    if (!data) return;
    const newPurchaseOrders = (data?.purchaseOrders?.edges || []).reduce((previous, current) => {
      const { node } = current;
      if (!node) return previous;
      const confirmation = node.purchaseOrderConfirmations.find(
        (conf) =>
          conf.confirmationType === ORDER_ACCEPTED_CONFIRMATION_TYPE && conf.status === 'GENERATED'
      );
      if (confirmation) {
        previous.push({
          id: node.id,
          customerPo: node.customerPo || '',
          orderDate: node.orderDate,
          deliveryWindowEnd: node.deliveryWindowEnd,
          confirmation: confirmation
        });
      }
      return previous;
    }, [] as ConfirmatingPurchaseOrder[]);
    setPurchaseOrders(newPurchaseOrders);
    setSelectedRows(newPurchaseOrders);
  }, [data]);

  const columns: ColumnsType<ConfirmatingPurchaseOrder> = [
    {
      title: 'PO #',
      render: (_, po) => po.customerPo,
      width: '120px',
      sorter: (a, b) => safeLocaleCompare(a?.customerPo, b?.customerPo)
    },
    {
      title: 'Order date',
      render: (_, po) => dateFormat(po.orderDate),
      width: '100px',
      sorter: (a, b) => compareDates(a.orderDate, b.orderDate)
    },
    {
      title: 'MABD',
      render: (_, po) => dateFormat(po.deliveryWindowEnd),
      width: '100px',
      sorter: (a, b) => compareDates(a.deliveryWindowEnd, b.deliveryWindowEnd)
    },
    {
      title: 'Expected Date',
      render: (_, po) => <ExpectedDeliveryDateField confirmation={po.confirmation} />,
      width: '130px',
      sorter: (a, b) =>
        compareDates(a.confirmation.expectedDeliveryDate, b.confirmation.expectedDeliveryDate)
    }
  ];

  const onSendConfirmation = async () => {
    const result = await sendConfirmation({
      variables: {
        input: {
          ids: selectedRows.map((po) => po.confirmation.id)
        }
      }
    });
    const count = result.data?.submitPurchaseOrderConfirmations?.confirmationsEnqueued || 0;
    message.success(`Initial Order Confirmation has been sent for ${count} order(s)`);
    setShowConfirmationModal(false);
  };

  return (
    <AlloyModal
      data-testid="initial-confirmation-modal"
      width={610}
      onCancel={() => setShowConfirmationModal(false)}
      cancelText={<span data-testid="initial-confirmation-cancel-button">Cancel</span>}
      onOk={() => {
        onSendConfirmation();
      }}
      okText={<span data-testid="initial-confirmation-confirm-button">Confirm</span>}
      title={<span data-testid="initial-confirmation-title">Initial order confirmation</span>}
      open={true}
    >
      {loading ? (
        <LoaderSpinner />
      ) : (
        <AlloyTable
          data-testid="initial-confirmation-table"
          rowKey={(record) => record.id}
          rowSelection={{
            onChange: (keys, rows) => {
              setSelectedRows(rows);
            },
            type: 'checkbox',
            selectedRowKeys: selectedRows.map((row) => row.id),
            //@ts-ignore
            getCheckboxProps(record) {
              return { 'data-testid': record.customerPo };
            }
          }}
          pagination={false}
          columns={columns}
          dataSource={purchaseOrders}
        />
      )}
    </AlloyModal>
  );
};
