import React, { useState, useEffect, useCallback, useRef, useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import s from './OrdersPage.module.scss';
import { DateRange, LocationUpdate, NumberRange } from 'common/interfaces';
import { SearchOutlined } from '@ant-design/icons';
import { SortingType } from 'common/types';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { parse, ParsedQuery, stringify } from 'query-string';
import OrdersListToolbar from 'pages/OrdersPage/components/OrdersListToolbar/OrdersListToolbar';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import ExportItemsModal, {
  ExportType
} from 'pages/OrdersPage/components/ExportItemsModal/ExportItemsModal';
import ExportPoModal from 'pages/OrdersPage/components/ExportPoModal/ExportPoModal';
import ReleaseOrdersModal from 'pages/OrdersPage/components/ReleaseOrdersModal/ReleaseOrdersModal';
import ExportSoItemsModal from 'pages/OrdersPage/components/ExportSoItemsModal/ExportSoItemsModal';
import BulkDownloadFileModal from 'pages/OrdersPage/components/BulkDownloadFileModal/BulkDownloadFileModal';
import { Paginator } from 'components/Paginator/Paginator';
import { isEqual } from 'lodash-es';
import {
  SortOrderDirection,
  PurchaseOrderSearchSort,
  PurchaseOrderSort
} from 'graphql/__generated__/types';
import {
  InferNodeType,
  getNodesFromEdges,
  locationPropertyToStringOrNull
} from 'common/helpers/mappingHelper';
import { ExportSeedDataModal } from 'pages/OrdersPage/components/OrdersListToolbar/components/ExportSeedDataModal';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { App } from 'ant5';
import {
  PurchaseOrderSearchListDocument,
  PurchaseOrderSearchListQuery
} from './gql/__generated__/purchaseOrderSearchList.query';
import { OrdersTableForSearchList } from './components/OrdersTable/OrdersTableForSearchList';
import { dateFormat } from 'common/helpers/date';
import { searchStringToQueryFilterForSearchList } from './components/AdvancedFilters/AdvancedFiltersForNewSearch';
import { NEW_PO_SEARCH_TOUR_LOCAL_STORAGE_KEY, SearchTour } from './components/SearchTour';
import { OrdersSummaryForNewSearch } from './components/OrdersSummary/OrdersSummaryForNewSearch';
import { PurchaseOrderSearchCountsDocument } from './components/OrdersSummary/gql/__generated__/purchaseOrderSearchCounts.query';
import { UserContext } from 'context/userContext';
import { getItem, setItem } from 'common/services/persistentStorageServices';
import { isE2ETestSession } from 'common/helpers/identificateEnvironment';
import { PurchaseOrderSearchSort as PurchaseOrderSearchSortEnum } from 'graphql/__generated__/enums';

interface ExportModalState {
  visible: boolean;
  type: ExportType;
}

interface SortConfig {
  columnKey: string;
  order: string;
  searchText: string | null;
  limit: string | null;
}

type AllExportTypes = 'PO' | ExportType;

const PAGE_SIZE_LOCAL_STORAGE_FIELD_NAME = 'pageSize';

const NewSortColumnKeyMap: Map<PurchaseOrderSort, PurchaseOrderSearchSort> = new Map([
  ['BUSINESS_UNIT', 'BUSINESS_UNIT_CODE'],
  ['CUSTOMER_PO', 'CUSTOMER_PO'],
  ['DELIVERY_WINDOW_END', 'PO_DELIVERY_WINDOW_END'],
  ['ORDER_DATE', 'ORDER_DATE'],
  ['RETAILER_ADDRESS', 'SHIP_TO_EXTERNAL_ID'],
  ['STATUS', 'PRIMARY_OPERATIVE_STATUS_INDEX'],
  ['TRADING_PARTNER_CODE', 'TRADING_PARTNER_EXTERNAL_ID'],
  ['UPDATED_AT', 'LAST_UPDATED_AT'],
  ['VENDOR_MARKET', 'RETAILER_CHANNEL_EXTERNAL_ID']
]);

export const getSortConfig = (location: ParsedQuery): SortConfig => {
  return Object.assign(
    {
      columnKey: 'ORDER_DATE',
      order: 'DESC',
      searchText: null,
      limit: null
    },
    location
  );
};
//add new filter name and value here
const defaultFilters = {
  before: null,
  after: null,
  deliveryType: null,
  tradingPartnerIds: null,
  distributionCenterIds: null,
  retailerChannelIds: null,
  deliveryDestinationIds: null,
  mustArriveByDate_start: null,
  mustArriveByDate_end: null,
  salesOrderMustArriveByDate_start: null,
  salesOrderMustArriveByDate_end: null,
  businessUnitIds: null,
  purchaseOrderErrors: null,
  purchaseOrderConfirmation: null,
  dateReceived_start: null,
  dateReceived_end: null,
  deliveryWindow_start: null,
  deliveryWindow_end: null,
  labels: null,
  searchIds: null,
  withCuts: null,
  shipmentsRange_min: null,
  shipmentsRange_max: null,
  // for old filters
  distributionCenters: null,
  businessUnits: null,
  customers: null,
  tradingPartners: null,
  deliveryDestinations: null
};

export type PurchaseOrderForSearchListItem = InferNodeType<
  PurchaseOrderSearchListQuery,
  'purchaseOrderSearchList'
>;

export const getPrimaryStatus = (po: PurchaseOrderForSearchListItem) =>
  po.operativeStatuses.length > 0 ? po.operativeStatuses[0] : '';

export const OrdersPageWithNewSearch = () => {
  const history = useHistory();
  const routerLocation = useLocation();
  const location = parse(history.location.search);
  const sortConfig = getSortConfig(location);

  const { message } = App.useApp();

  const [limit] = useQueryParam(
    'limit',
    withDefault(
      NumberParam,
      getItem(PAGE_SIZE_LOCAL_STORAGE_FIELD_NAME)?.OrdersPage ?? DEFAULT_PAGE_SIZE
    )
  );

  const [exportModalState, setExportModalState] = useState<ExportModalState>({
    visible: false,
    type: 'FULL'
  });
  const [shareEmailModalVisibility, setShareEmailModalVisibility] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<PurchaseOrderForSearchListItem[]>([]);
  const [exportPoModalVisible, setExportPoModalVisible] = useState(false);
  const [exportSoItemsModalVisible, setExportSoItemsModalVisible] = useState(false);
  const [showBulkDownloadFileModal, setShowBulkDownloadFileModal] = useState(false);
  const [exportSeedDataModalVisible, setExportSeedDataModalVisible] = useState(false);

  const searchComponentRef = useRef<HTMLDivElement>(null);

  const clearSelectedOrders = () => {
    setSelectedOrders([]);
  };

  const { error, data, loading } = useQuery(PurchaseOrderSearchListDocument, {
    variables: {
      first: location?.after || !location?.before ? limit : null,
      last: !location?.after && location?.before ? limit : null,
      after: locationPropertyToStringOrNull(location?.after),
      before:
        location?.before && !location?.after
          ? locationPropertyToStringOrNull(location?.before)
          : null,
      sort: {
        column: sortConfig.columnKey as PurchaseOrderSearchSort,
        direction: sortConfig.order as SortOrderDirection
      },
      filter: searchStringToQueryFilterForSearchList(history.location.search).filterData,
      advancedFilter: searchStringToQueryFilterForSearchList(history.location.search)
        .advancedFilterData
    },
    fetchPolicy: 'network-only',
    skip: isQueryParamsHasWrongSortOrFilters(history.location.search)
  });

  const { data: summaryData, loading: summaryLoading } = useQuery(
    PurchaseOrderSearchCountsDocument,
    {
      variables: {
        filter: {
          ...searchStringToQueryFilterForSearchList(history.location.search).filterData,
          operativeStatuses: undefined
        },
        advancedFilter: searchStringToQueryFilterForSearchList(history.location.search)
          .advancedFilterData
      },
      fetchPolicy: 'network-only'
    }
  );

  const purchaseOrders = useMemo(() => getNodesFromEdges(data?.purchaseOrderSearchList), [data]);

  const toggleBulkDownloadFileModal = () =>
    setShowBulkDownloadFileModal(!showBulkDownloadFileModal);

  const toggleExportItemsModal = (type: AllExportTypes) => {
    if (type === 'PO') setExportPoModalVisible(true);
    else setExportModalState({ visible: !exportModalState.visible, type });
  };

  const toggleExportSoItemsModal = () => {
    setExportSoItemsModalVisible(!exportSoItemsModalVisible);
  };

  const toggleShareEmailModal = () => {
    setShareEmailModalVisibility(!shareEmailModalVisibility);
  }; // can we rename this to toggleReleaseOrdersModal? Where did this name come from? Is it necessary?

  const onSelectionAction = (selectedRows: PurchaseOrderForSearchListItem[]) => {
    setSelectedOrders(selectedRows);
  };

  const handlePageSizeChange = async (value: number) => {
    updatePaging({ limit: value, after: null, before: null });
    saveLastPagingToLocalStorage(value);
  };

  const toggleExportSeedDataModal = () => {
    setExportSeedDataModalVisible(!exportSeedDataModalVisible);
  };

  const updatePaging = useCallback(
    (update?: LocationUpdate, clearState: boolean = true, replace: boolean = false) => {
      const location = parse(history.location.search);
      if (update) {
        Object.keys(update).forEach((key) => {
          const t = update[key];
          location[key] = t === undefined ? null : typeof t === 'number' ? `${t}` : t;

          // w/o !== 0 we would remove 0 from numeric queryparams, and we need them.
          // not sure if we want to preserve '' values, so let's keep it like that;
          if (!update[key] && update[key] !== 0) {
            delete location[key];
          }
        });
      }

      const locationNew = {
        ...routerLocation,
        search: stringify(location),
        state: clearState ? undefined : routerLocation.state
      };
      if (replace) {
        history.replace(locationNew);
      } else {
        history.push(locationNew);
      }
    },
    [history, routerLocation]
  );

  //used to reset the table when the user clicks on PO Management from the hamburger menu
  useEffect(() => {
    // This redirect needs because we have different sort column names under feature flag
    // Remove it with schip_po_page_new_search feature flag (should be `if (history.location.search) return`)
    // if (history.location.search) return
    if (history.location.search) {
      const updatePagingLocation = parse(history.location.search);
      const sortColumnKey = locationPropertyToStringOrNull(updatePagingLocation.columnKey);
      if (isQueryParamsHasWrongSortOrFilters(history.location.search)) {
        updatePaging(
          {
            ...updatePagingLocation,
            columnKey: NewSortColumnKeyMap.get(sortColumnKey as PurchaseOrderSort),
            searchIds: updatePagingLocation.searchIds ?? updatePagingLocation.customerPos,
            customerPos: undefined
          },
          false,
          true
        );
      } else {
        return;
      }
    } else {
      updatePaging(
        {
          columnKey: 'ORDER_DATE',
          order: 'DESC',
          searchText: '',
          cursor: null,
          limit: limit,
          before: null,
          after: null
        },
        false,
        true
      );
    }
  }, [history.location.search, limit, updatePaging]);

  // TODO redirect here

  useEffect(() => {
    if (history.location.search && !routerLocation.state) {
      window.scrollTo(0, 0);
    }
  }, [history.location.search, routerLocation.state]);

  // new search tour
  const { user } = useContext(UserContext);
  const [closedByUser, setClosedByUser] = useState(false);
  const showNewSearchTour = useMemo(
    () =>
      !closedByUser &&
      !getItem(`${NEW_PO_SEARCH_TOUR_LOCAL_STORAGE_KEY}[${user?.name}]`) &&
      !loading &&
      !summaryLoading &&
      !isE2ETestSession(),
    [closedByUser, loading, summaryLoading, user?.name]
  );

  if (error)
    return (
      <div className="permissions">
        <ErrorDisplay error={error} />
      </div>
    );

  const nextPage = async () => {
    updatePaging({ after: data?.purchaseOrderSearchList?.pageInfo.endCursor, before: null });
  };

  const prevPage = async () => {
    updatePaging({ before: data?.purchaseOrderSearchList?.pageInfo.startCursor, after: null });
  };

  const handleOrdersFilter = (
    filterName: string,
    filterValue: string | string[] | DateRange | NumberRange
  ) => {
    let newFilter;
    if (
      (filterValue as { start: string; end: string })?.start ||
      (filterValue as { start: string; end: string })?.start === ''
    ) {
      newFilter = {
        ...parse(history.location.search),
        [`${filterName}_start`]: (filterValue as { start: string; end: string }).start,
        [`${filterName}_end`]: (filterValue as { start: string; end: string }).end
      };
    } else if (
      ((filterValue as NumberRange).min !== null &&
        (filterValue as NumberRange).min !== undefined) ||
      ((filterValue as NumberRange).max !== null && (filterValue as NumberRange).max !== undefined)
    ) {
      newFilter = {
        ...parse(history.location.search),
        [`${filterName}_min`]: (filterValue as NumberRange).min,
        [`${filterName}_max`]: (filterValue as NumberRange).max
      };
    } else {
      newFilter = {
        ...parse(history.location.search),
        [filterName]: filterValue as string | string[]
      };
    }
    delete newFilter.after;
    delete newFilter.before;
    // clear statuses when update PO search not to hide found POs in not selected statuses
    if (filterName === 'searchIds') {
      delete newFilter.operativeStatuses;
    }
    history.push({
      search: stringify(newFilter)
    });
    clearSelectedOrders();
  };

  const handleTableSorting = (columnKey: string, order: string) => {
    const searchText = sortConfig.searchText;
    updatePaging({
      columnKey,
      searchText,
      order
    });
  };

  const getSortOrder = (columnKey: string) =>
    sortConfig.columnKey === columnKey
      ? ((sortConfig.order === 'ASC' ? 'ascend' : 'descend') as SortingType)
      : undefined;

  const onApplyAdvancedFilter = (update: LocationUpdate, name?: string) => {
    const currentLocationSearch = parse(history.location.search);
    const previousSearch = {
      ...defaultFilters,
      ...currentLocationSearch
    };
    const newLocationSearch = {
      operativeStatuses: null,
      ...defaultFilters,
      ...update
    };
    if (!isEqual(previousSearch, newLocationSearch)) {
      clearSelectedOrders();
      updatePaging(newLocationSearch);
    }

    if (name) {
      message.success(
        <span data-testid="advanced-filter-applied-message">Filter {name} has been applied</span>
      );
    }
  };

  const { searchIds } = parse(history.location.search);

  return (
    <>
      <OrdersListToolbar
        clearSelectedOrders={clearSelectedOrders}
        toggleExportSoItemsModal={toggleExportSoItemsModal}
        toggleExportModal={toggleExportItemsModal}
        toggleShareEmailModal={toggleShareEmailModal}
        toggleBulkDownloadFileModal={toggleBulkDownloadFileModal}
        selectedRows={selectedOrders
          .map(
            (selectedPo) =>
              purchaseOrders.find((po) => po.purchaseOrderId === selectedPo.purchaseOrderId) ||
              selectedPo
          )
          .map((po) => ({
            id: po.purchaseOrderId,
            customerPo: po.customerPo,
            deliveryType: po.deliveryType,
            availableActions: po.availableActions,
            labelIds: po.labelIds,
            primaryStatus: getPrimaryStatus(po),
            salesOrderIds: po.salesOrderIds,
            shipTo: po.shipTo
              ? {
                  id: po.shipTo.id,
                  name: po.shipTo.name ?? '',
                  externalId: po.shipTo.externalId ?? ''
                }
              : undefined
          }))}
        handleOrdersFilter={handleOrdersFilter}
        onApplyAdvancedFilter={onApplyAdvancedFilter}
        toggleExportSeedDataModal={toggleExportSeedDataModal}
      />
      <AlloyRow>
        <OrdersSummaryForNewSearch
          handleOrdersFilter={handleOrdersFilter}
          purchaseOrderSearchCounts={summaryData?.purchaseOrderSearchCounts}
          loading={summaryLoading || loading}
        />
      </AlloyRow>
      <div data-testid="po-search-bar" className={s.po_search_bar}>
        <MultipleValuesInput
          ref={searchComponentRef}
          loading={loading}
          placeholder="Search by PO, SAP order, or UPC, GTIN, Customer Product ID"
          value={searchIds ? (typeof searchIds === 'string' ? [searchIds] : searchIds) : []}
          onChange={(value) => {
            handleOrdersFilter('searchIds', value);
          }}
          allowClear={true}
          prefix={<SearchOutlined width="14px" height="14px" />}
          splitInputValue={/[^0-9a-zA-Z-]+/g}
          disabled={showNewSearchTour}
        />
        <SearchTour
          searchFieldRef={searchComponentRef}
          open={showNewSearchTour}
          setClosedByUser={setClosedByUser}
        />
      </div>
      <div className={s.po_list_quantity_and_paginator}>
        <div className={s.po_list_quantity}>
          <QuantityDisplay
            count={
              selectedOrders.length > 0
                ? selectedOrders.length
                : data?.purchaseOrderSearchList?.totalCount
            }
            titleSingular={`${selectedOrders.length > 0 ? 'Selected ' : ''} Order`}
            titleMultiple={`${selectedOrders.length > 0 ? 'Selected ' : ''} Orders`}
          />
          {selectedOrders.length > 0 && (
            <AlloyButton
              data-testid="clear-selection-button"
              onClick={() => onSelectionAction([])}
              className={s.clear_selection_button}
            >
              Clear selection
            </AlloyButton>
          )}
        </div>
        <Paginator
          pageSize={limit}
          hasNextPage={!!data?.purchaseOrderSearchList?.pageInfo.hasNextPage}
          hasPreviousPage={!!data?.purchaseOrderSearchList?.pageInfo.hasPreviousPage}
          handlePageSizeChange={handlePageSizeChange}
          prevPage={prevPage}
          nextPage={nextPage}
          onlyButtons={false}
        />
      </div>
      <OrdersTableForSearchList
        loading={loading}
        purchaseOrders={purchaseOrders}
        handleTableSorting={handleTableSorting}
        selectedOrders={selectedOrders.map(
          (selectedPo) =>
            purchaseOrders.find((po) => po.purchaseOrderId === selectedPo.purchaseOrderId) ||
            selectedPo
        )}
        getSortOrder={getSortOrder}
        onSelectionAction={onSelectionAction}
      />
      <AlloyRow className={s.po_list_bottom_paginator}>
        <Paginator
          pageSize={limit}
          hasNextPage={!!data?.purchaseOrderSearchList?.pageInfo.hasNextPage}
          hasPreviousPage={!!data?.purchaseOrderSearchList?.pageInfo.hasPreviousPage}
          handlePageSizeChange={handlePageSizeChange}
          prevPage={prevPage}
          nextPage={nextPage}
          onlyButtons={true}
        />
      </AlloyRow>
      <BulkDownloadFileModal
        visibility={showBulkDownloadFileModal}
        toggleModal={toggleBulkDownloadFileModal}
        selectedRowIds={selectedOrders.map((selectedOrder) => selectedOrder.purchaseOrderId)}
      />
      <ExportItemsModal
        visibility={exportModalState.visible}
        type={exportModalState.type}
        toggleModal={toggleExportItemsModal}
        selectedPurchaseOrderIds={selectedOrders
          .map(
            (selectedPo) =>
              purchaseOrders.find((po) => po?.purchaseOrderId === selectedPo?.purchaseOrderId) ??
              selectedPo
          )
          .map((row) => row.purchaseOrderId)}
      />
      <ExportPoModal
        visibility={exportPoModalVisible}
        toggleModal={() => setExportPoModalVisible(false)}
        selectedPurchaseOrders={selectedOrders
          .map(
            (selectedPo) =>
              purchaseOrders.find((po) => po?.purchaseOrderId === selectedPo?.purchaseOrderId) ??
              selectedPo
          )
          .map((po) => ({
            id: po.purchaseOrderId,
            customerPo: po.customerPo || '',
            customer: po.shipTo?.name || '',
            shipTo: po.shipToExternalId || '',
            orderDate: dateFormat(po.orderDate),
            deliveryWindowEnd: dateFormat(po.poDeliveryWindowEnd),
            bu: po.businessUnit.code || ''
          }))}
      />
      <ReleaseOrdersModal
        visibility={shareEmailModalVisibility}
        toggleModal={toggleShareEmailModal}
        selectedRowIds={selectedOrders.map((row) => row.purchaseOrderId)}
        clearSelection={clearSelectedOrders}
      />
      <ExportSoItemsModal
        visibility={exportSoItemsModalVisible}
        toggleModal={() => setExportSoItemsModalVisible(false)}
        selectedRowIds={selectedOrders.map((po) => po.purchaseOrderId)}
      />
      <ExportSeedDataModal
        open={exportSeedDataModalVisible}
        toggleModal={toggleExportSeedDataModal}
        selectedRowIds={selectedOrders.map((po) => po.purchaseOrderId)}
      />
    </>
  );
};

const isQueryParamsHasWrongSortOrFilters = (search: string) => {
  const updatePagingLocation = parse(search);
  const sortColumnKey = locationPropertyToStringOrNull(updatePagingLocation.columnKey);
  const result =
    (!!sortColumnKey &&
      !Object.values(PurchaseOrderSearchSortEnum).includes(
        sortColumnKey as PurchaseOrderSearchSortEnum
      )) ||
    !!updatePagingLocation.customerPos;
  return result;
};

const saveLastPagingToLocalStorage = (value: number) => {
  setItem(PAGE_SIZE_LOCAL_STORAGE_FIELD_NAME, {
    OrdersPage: value
  });
};
