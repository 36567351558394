import React, { useMemo, useState } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useQueryParam, withDefault, ArrayParam, StringParam } from 'use-query-params';
import s from './ForecastPlanning.module.scss';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
// import bookmarkAddIcon from 'assets/icons/bookmark_add.svg';
import clsx from 'clsx';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';
import { ProductCard } from './components/ProductCard/ProductCard';
import { ProductDetails } from './components/ProductDetails/ProductDetails';
import { ForecastTable } from './components/ForecastTable/ForecastTable';
import { useQuery } from '@apollo/client';
import { ProductForecastScorecardDocument } from './gql/__generated__/productForecastScorecard.query';
import { getCurrentPeriod } from 'components/PeriodCalendar/helpers';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { notEmpty } from 'common/helpers/notEmpty';
import { AvailableProductFiltersDocument } from './gql/__generated__/availableProductFilters.query';
import { transformToTitleCase, WORD_EXCEPTIONS } from './helpers';
import {
  ProductForecastFilters,
  ProductForecastSortDatapoint,
  SortOrderDirection
} from 'graphql/__generated__/types';
import { AlloyPagination } from 'components/ui/AlloyPagination/AlloyPagination';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'common/constants';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { ProductForecastNumberOfProductsDocument } from './gql/__generated__/productForecastNumberOfProducts.query';
import { LastUpdated } from './components/LastUpdated/LastUpdated';

const CURRENT_PERIOD = getCurrentPeriod(true);

const sortingOptions: { label: string; value: ProductForecastSortDatapoint }[] = [
  { label: 'By Material ID', value: 'SAP_MATERIAL_ID' },
  { label: 'By Ending WoC', value: 'ENDING_WOC' },
  { label: 'By 5WK FR Trend', value: 'FILL_RATE_TREND_FIVE_WEEK' }
];

export const ForecastPlanning = () => {
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const filters = useQuery(AvailableProductFiltersDocument);

  const [bus, setBus] = useQueryParam('bus', withDefault(ArrayParam, ['all']));
  const [vendorCodes, setVendorCodes] = useQueryParam(
    'vendorCodes',
    withDefault(ArrayParam, ['all'])
  );
  const [brands, setBrands] = useQueryParam('brands', withDefault(ArrayParam, ['all']));
  const [searchTerm, setSearchTerm] = useQueryParam('search', withDefault(ArrayParam, []));
  const nonEmptySearch = searchTerm.filter(notEmpty) as string[];

  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, 'SAP_MATERIAL_ID'));
  const [direction, setDirection] = useQueryParam('direction', withDefault(StringParam, 'ASC'));
  const toggleDirection = () => {
    if (direction === 'ASC') {
      setDirection('DESC');
    } else {
      setDirection('ASC');
    }
  };

  const [selected, setSelected] = useQueryParam('selected', withDefault(StringParam, ''));

  const businessUnitsOptions = useMemo(
    () => filters.data?.availableProductFilters?.businessUnits || [],
    [filters.data?.availableProductFilters?.businessUnits]
  );
  const vendorCodesOptions = useMemo(
    () => filters.data?.availableProductFilters?.vendorCodes || [],
    [filters.data?.availableProductFilters?.vendorCodes]
  );
  const brandCodesOptions = useMemo(
    () => filters.data?.availableProductFilters?.brands || [],
    [filters.data?.availableProductFilters?.brands]
  );

  const businessUnitCodes = useDeepCompareMemo(
    // empty array = all values
    () => getValueWithAll(bus as string[], []),
    [bus]
  );

  const vendorCodeCodes = useDeepCompareMemo(
    // empty array = all values
    () => getValueWithAll(vendorCodes as string[], []),
    [vendorCodes]
  );
  const brandCodes = useDeepCompareMemo(
    // empty array = all values
    () => getValueWithAll(brands as string[], []),
    [brands]
  );

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_OPTIONS[0]);

  const [page, setPage] = useState(1);
  const resetPagination = () => {
    setPage(1);
  };

  const selectedFilters: ProductForecastFilters = useDeepCompareMemo(
    () => ({
      fiscalCalendarWeek: {
        year: CURRENT_PERIOD.year,
        week: CURRENT_PERIOD.week,
        period: CURRENT_PERIOD.period
      },
      brands: brandCodes,
      vendorCodes: vendorCodeCodes,
      businessUnits: businessUnitCodes
    }),
    [brandCodes, businessUnitCodes, vendorCodeCodes]
  );

  const totalData = useQuery(ProductForecastNumberOfProductsDocument, {
    variables: {
      filters: {
        smartSearchTerms: nonEmptySearch,
        ...selectedFilters
      }
    }
  });
  const productCards = useQuery(ProductForecastScorecardDocument, {
    variables: {
      filters: {
        page,
        pageSize,
        smartSearchTerms: nonEmptySearch,
        sortBy: {
          direction: direction as SortOrderDirection,
          datapoint: sort as ProductForecastSortDatapoint
        },
        ...selectedFilters
      }
    },
    onCompleted: (data) => {
      if (!selected) {
        const cards = data?.productForecastScorecard || [];
        if (cards.length > 0) {
          setSelected(cards[0].sapMaterialId);
        }
      }
    }
  });

  const allProducts = useMemo(
    () => productCards.data?.productForecastScorecard || [],
    [productCards.data?.productForecastScorecard]
  );

  const selectedProduct = useMemo(
    () => allProducts.find((x) => x.sapMaterialId === selected),
    [allProducts, selected]
  );

  if (productCards.error) return <ErrorDisplay error={productCards.error} />;

  return (
    <div>
      <div className={s.header}>
        <div className={s.page_title}>
          <PageHeader className={s.title}>Forecast</PageHeader>
          <LastUpdated />
        </div>
        <div className={s.page_filters}>
          <AlloySelect
            loading={filters.loading}
            className={clsx(s.select, bus.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={bus}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], bus as string[], setBus);
              resetPagination();
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL BUs', value: 'all' },
              ...businessUnitsOptions.map((bu) => ({
                label: transformToTitleCase(bu, WORD_EXCEPTIONS),
                value: bu
              }))
            ]}
          />{' '}
          <AlloySelect
            loading={filters.loading}
            className={clsx(s.select, vendorCodes.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={vendorCodes}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], vendorCodes as string[], setVendorCodes);
              resetPagination();
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL Vendor Codes', value: 'all' },
              ...vendorCodesOptions.map((vendorCode) => ({
                label: vendorCode,
                value: vendorCode
              }))
            ]}
          />{' '}
          <AlloySelect
            loading={filters.loading}
            className={clsx(s.select, brands.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={brands}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], brands as string[], setBrands);
              resetPagination();
            }}
            style={{ width: '220px' }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL Brands', value: 'all' },
              ...brandCodesOptions.map((brand) => ({
                label: transformToTitleCase(brand, WORD_EXCEPTIONS),
                value: brand
              }))
            ]}
          />
          {/* <AlloyButton size="large" icon={<img src={bookmarkAddIcon} alt="Add bookmark" />} /> */}
          <AlloyButton size="large" type="secondary" onClick={() => setExportModalVisible(true)}>
            Export
          </AlloyButton>
        </div>
      </div>
      <div className={s.main}>
        <AlloySpin spinning={productCards.loading}>
          <div className={s.product_list_wrapper}>
            <div className={s.inputs_wrapper}>
              <div className={s.search_wrapper}>
                <MultipleValuesInput
                  placeholder="Search"
                  value={
                    nonEmptySearch
                      ? typeof nonEmptySearch === 'string'
                        ? [nonEmptySearch]
                        : nonEmptySearch
                      : []
                  }
                  onChange={setSearchTerm}
                  allowClear={true}
                  prefix={<SearchOutlined width="14px" height="14px" />}
                  splitInputValue={/[^0-9a-zA-Z-]+/g}
                />
              </div>
              <div className={s.sort_and_total}>
                <div className={s.sorting_container}>
                  <AlloySelect
                    options={sortingOptions}
                    value={sort}
                    onChange={(value) => {
                      setSort(value);
                      resetPagination();
                    }}
                    style={{ width: '150px' }}
                  />
                  <AlloyTooltip
                    title={`Click to sort ${direction === 'ASC' ? 'descending' : 'ascending'}`}
                  >
                    <AlloyButton
                      onClick={() => {
                        toggleDirection();
                        resetPagination();
                      }}
                      type="text"
                      className={s.sort_order_button}
                    >
                      <div className={s.inner}>
                        <CaretUpOutlined
                          className={clsx({ [s.active]: direction === 'ASC' }, s.up)}
                        />
                        <CaretDownOutlined
                          className={clsx({ [s.active]: direction === 'DESC' }, s.down)}
                        />
                      </div>
                    </AlloyButton>
                  </AlloyTooltip>
                </div>
                <div className={s.amount}>
                  {totalData.loading ||
                  typeof totalData.data?.productForecastNumberOfProducts.value === 'undefined'
                    ? '...'
                    : `${productCards.data?.productForecastScorecard?.length ?? '...'} of ${totalData.data?.productForecastNumberOfProducts.value}`}
                </div>
              </div>
            </div>
            <div className={s.product_list}>
              {allProducts.map((product) => (
                <ProductCard
                  key={product.sapMaterialId}
                  product={product}
                  isSelected={selected === product.sapMaterialId}
                  setSelected={setSelected}
                />
              ))}
            </div>
            <AlloyPagination
              className={s.pagination}
              size="small"
              total={totalData.data?.productForecastNumberOfProducts.value}
              current={page}
              showLessItems
              simple
              pageSize={pageSize}
              pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
              onChange={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }}
            />
          </div>
        </AlloySpin>
        <div className={s.details}>
          {selected && (
            <ProductDetails
              preloadedProduct={selectedProduct}
              sapMaterialId={selected}
              filters={selectedFilters}
            />
          )}
          {selected && (
            <ForecastTable
              exportModalVisible={exportModalVisible}
              setExportModalVisible={setExportModalVisible}
              sapMaterialId={selected}
              vendorCodes={[]}
              filters={selectedFilters}
              totalProducts={totalData.data?.productForecastNumberOfProducts.value}
            />
          )}
        </div>
      </div>
    </div>
  );
};
