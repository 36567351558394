import React from 'react';
import * as Sentry from '@sentry/browser';
import ErrorState from '@/src/components/ErrorState/ErrorState';

interface ErrorDisplayProps {
  error: Error;
  header?: string;
  'data-testid'?: string;
  instruction?: string;
  shouldReportToSentry?: boolean;
}

const ErrorDisplay = ({
  header,
  error,
  'data-testid': dataTestId,
  instruction,
  shouldReportToSentry = true
}: ErrorDisplayProps) => {
  if (shouldReportToSentry) {
    Sentry.captureException(error);
  }
  console.log('ERROR:', error);

  return (
    <ErrorState
      title={header || error.name}
      explanation={error.message}
      data-testid={dataTestId}
      instruction={instruction}
    />
  );
};

export default ErrorDisplay;
