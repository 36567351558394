import { ChargebacksReportDetailsByPeriodForFiltersQuery } from './gql/__generated__/chargebacksReportDetailsByPeriodForFilters.query';

type ChargebackReportByPeriod =
  ChargebacksReportDetailsByPeriodForFiltersQuery['chargebackDetailsReportsByPeriod'][number];

export type ChargebackReportByPeriodForFilters = Omit<
  ChargebackReportByPeriod,
  '__typename' | 'financialCharge' | 'fiscalCalendarWeek'
>;

export type Dimension = keyof Omit<ChargebackReportByPeriodForFilters, 'totalValue'>;

export const ALL = '__ALL__';

type AllowedChargebackDimensions =
  | 'BUSINESS_UNIT'
  | 'ISSUE_TYPE'
  | 'STATUS'
  | 'SUB_TYPE_OF_THE_NON_COMPLIANCE'
  | 'TRADING_PARTNER';

export const dimensionToField: { [K in AllowedChargebackDimensions]?: Dimension } = {
  BUSINESS_UNIT: 'businessUnit',
  TRADING_PARTNER: 'tradingPartner',
  ISSUE_TYPE: 'issueType',
  SUB_TYPE_OF_THE_NON_COMPLIANCE: 'subTypeOfTheNonCompliance',
  STATUS: 'status'
} as const;

export const fieldToDimension: { [K in Dimension]?: AllowedChargebackDimensions } = {
  businessUnit: 'BUSINESS_UNIT',
  tradingPartner: 'TRADING_PARTNER',
  issueType: 'ISSUE_TYPE',
  subTypeOfTheNonCompliance: 'SUB_TYPE_OF_THE_NON_COMPLIANCE',
  status: 'STATUS'
} as const;

export const ALL_AVAILABLE_DIMENSIONS = Object.keys(fieldToDimension) as Dimension[];
export const ALL_AVAILABLE_CHARGEBACK_DIMENSIONS = Object.values(
  fieldToDimension
) as AllowedChargebackDimensions[];

export const filters: { label: string; value: Dimension }[] = [
  { label: 'BU', value: 'businessUnit' },
  { label: 'VC', value: 'tradingPartner' },
  { label: 'Issue', value: 'issueType' },
  { label: 'Sub-Issue', value: 'subTypeOfTheNonCompliance' },
  { label: 'Status', value: 'status' }
];

export const createInitialFilterObject = (filters: { value: Dimension }[]) => {
  const initialfilterObject = {} as Record<Dimension, string[]>;
  filters.forEach((filter) => {
    initialfilterObject[filter.value] = [ALL];
  });
  return initialfilterObject;
};

export const filterChargebacksData = <T extends { [K in Dimension]?: string | null }>(
  items: T[],
  filters: { [K in Dimension]?: string[] },
  dimensionsToSkip: Dimension[] = []
) => {
  let filtered = items;
  for (const filter in filters) {
    // We are NOT filtering by BU here
    if (dimensionsToSkip.includes(filter as Dimension)) continue;

    if (filters[filter as Dimension]?.includes(ALL)) continue;
    filtered = filtered.filter((x) =>
      filters[filter as Dimension]?.includes(x[filter as Dimension] || '')
    );
  }
  return filtered;
};
