import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { DrawerTitle } from 'components/Common/ViewMode/DrawerTitle/DrawerTitle';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyDrawer } from 'components/ui/AlloyDrawer/AlloyDrawer';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import moment from 'moment';
import { WhseOpenOrdersProcessed } from 'pages/WarehouseOrderProcessingPage/types';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import s from './ShipDateRDDDrawer.module.scss';
import { DATE_FORMAT } from 'common/helpers/date';

export type ShipDateRDDFormValues = {
  shipDate: moment.Moment | null;
  suggestedRdd: moment.Moment | null;
};

type ShipDateRDDDrawerProps = {
  open: boolean;
  orders: WhseOpenOrdersProcessed[];
  onSubmit: (values: ShipDateRDDFormValues) => void;
  onClose: () => void;
  pending: boolean;
};

const ShipDateRDDDrawer: React.FC<ShipDateRDDDrawerProps> = ({
  open,
  orders,
  onSubmit,
  onClose,
  pending
}) => {
  const initialValues: ShipDateRDDFormValues = useMemo(() => {
    return {
      shipDate: moment(orders[0]?.shipDate) || null,
      suggestedRdd: moment(orders[0]?.rdd) || null
    };
  }, [orders, open]);

  return (
    <AlloyDrawer
      data-testid="open-orders-edit-ship-date-rdd-drawer"
      open={open}
      onClose={onClose}
      closeIcon={false}
      title={
        <DrawerTitle
          title={
            <span data-testid="open-orders-edit-ship-date-rdd-drawer-title">
              ORDER NUMBER:{' '}
              <ListOfItemsColumn splitToLines items={orders.map((item) => item.orderNumber)} />
            </span>
          }
          onCancel={onClose}
        />
      }
      width={455}
      className={s.drawer}
    >
      <Form<ShipDateRDDFormValues>
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(v) => {
          console.log(v);
          return {};
        }}
      >
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className={s.form}>
            <div className={s.fieldsWrap}>
              <AlloyFormField
                data-testid="edit-ship-date-form-field-input"
                name="shipDate"
                component="datepicker"
                fieldProps={{
                  title: 'Ship Date',
                  format: DATE_FORMAT,
                  suffixIcon: null,
                  allowClear: false
                }}
              />

              <AlloyFormField
                data-testid="edit-rdd-form-field-input"
                name="suggestedRdd"
                component="datepicker"
                fieldProps={{
                  title: 'Suggested RDD',
                  format: DATE_FORMAT,
                  suffixIcon: null,
                  allowClear: false
                }}
              />
            </div>

            <div className={s.footer}>
              <AlloyButton
                data-testid="cancel-edit-ship-to-rdd-button"
                style={{ width: '50%' }}
                htmlType="reset"
                onClick={() => {
                  form.reset();
                  onClose();
                }}
                disabled={pending}
                type="secondary"
              >
                Cancel
              </AlloyButton>
              <AlloyButton
                data-testid="save-edit-ship-to-rdd-button"
                disabled={!form.getState().dirty}
                style={{ width: '50%' }}
                htmlType="submit"
                type="primary"
                loading={pending}
              >
                Save
              </AlloyButton>
            </div>
          </form>
        )}
      </Form>
    </AlloyDrawer>
  );
};

export default ShipDateRDDDrawer;
