/*
This component contains code for Uploading New items for scenario Creation using excel sheet
Accepts excel File format for upload , reads data, performs validation checks and displays in the UI.
*/
import React, { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';
import { WorkBook as XLSXWorkBook } from 'non_npm_dependencies/xlsx/types';
import { Link } from 'react-router-dom';
import s from './NewScenarioAddItem.module.scss';
import { App } from 'ant5';
import errorWarningIcon from '../../../../assets/icons/inventoryOptimization/error_warning.svg';
import uploadIcon from '../../../../assets/icons/inventoryOptimization/upload_icon.svg';
import { DeleteOutlined } from '@ant-design/icons';
import { read as XLSXread, utils as XLSXutils } from 'non_npm_dependencies/xlsx/xlsx.mjs';
import { InsertScenarioDocument } from 'pages/InventoryOptimizationPage/gql/__generated__/insertScenario.mutation';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GetScenarioListDataDocument } from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioList.query';
import { ScenarioConstraintsData } from './SKUScenariosConstraints';
import {
  GetGtin14sDocument,
  CheckScenarioNameDocument
} from 'pages/InventoryOptimizationPage/gql/__generated__/createScenarioValidations.query';
import {
  GetTradingPartnerDocument,
  GetDCsDocument
} from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioCreate.query';
import {
  invConstDcCodes,
  invConstPlantCodes,
  dcSourceName,
  plantSourceName
} from 'pages/InventoryOptimizationPage/inventoryOptimizationConstants';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyUpload, UploadProps, UploadFile } from 'components/ui/AlloyUpload/AlloyUpload';

interface NewItemData {
  key: string;
  gtin: string;
  pepMaterialNumber: string | null;
  overallDemand: number | null;
  daysOnHand: number | null;
  marketPlace: string | null;
  marketPlaceName: string | null;
  plants: string[];
  plantNames: string[];
  dcLocations: string[];
  dcLocationNames: string[];
}
type ScenarioConstraintsNewItems = {
  gtin14ProductId: number;
  tradingPartnerExternalId: string;
  dcIds: number[];
  plantIds: number[];
  pepMaterialNumber: string;
  daysOnHand: number;
  overallDemand: number;
};
type DistributionCenter = {
  dcCode: string | null | undefined;
  dcId: number | null | undefined;
};

type Gtin14Product = {
  id: string;
  gtin14: string | undefined;
};
interface NewScenarioProps {
  ScenarioConstraintsData: ScenarioConstraintsData[];
}
const dcCodeNames = invConstDcCodes.DC_CODES.map((dcCode) => {
  return {
    code: dcCode,
    source: dcSourceName
  };
});
const plantCodeNames = invConstPlantCodes.PLANT_CODES.map((plantCode) => {
  return {
    code: plantCode,
    source: plantSourceName
  };
});

const NewScenarioAddItem: React.FC<NewScenarioProps> = ({ ScenarioConstraintsData }) => {
  const { message } = App.useApp();
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<number | null>(1);
  const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
  const [isUploadFileModal, setIsUploadFileModal] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [data, setData] = useState<NewItemData[]>();
  const [scenarioName, setScenarioName] = useState('');
  const [scenarioDescription, setScenarioDescription] = useState('');
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<React.Key[]>([]);
  const [rowValidation, setRowValidation] = useState<{
    [key: string]: boolean;
  }>({});
  const [invalidRowIndex, setInvalidRowIndex] = useState<string[]>([]);
  const [scenarioConstraintsNewItems, setScenarioConstraintsNewIems] =
    useState<ScenarioConstraintsNewItems[]>();

  const [fetchAllGtin14s, { data: gtin14List, loading: gtinLoading }] =
    useLazyQuery(GetGtin14sDocument);
  const [fetchMarketPlaces, { data: marketPlaceData, loading: marketPlaceLoading }] =
    useLazyQuery(GetTradingPartnerDocument);
  const [fetchDCs, { data: dcData, loading: dcLoading }] = useLazyQuery(GetDCsDocument);
  const [checkScenarioName, { data: checkScenarioData, loading: checkScenarioLoading }] =
    useLazyQuery(CheckScenarioNameDocument, {
      onCompleted: (data) => {
        if (
          data.getScenariosList &&
          data.getScenariosList.map((sn) => sn?.scenarioName).length > 0
        ) {
          message.error('This scenario name already exist');
        }
      }
    });
  const scenarioNameError = checkScenarioData?.getScenariosList
    ? checkScenarioData?.getScenariosList?.map((data) => data?.scenarioName).length > 0
    : false;

  const dcs = useMemo(
    () =>
      dcData?.getDcs?.map((dc) => {
        return {
          dcCode: dc?.distributionCenterCode,
          dcId: dc?.distributionCenterId
        };
      }),
    [dcData]
  );
  const gtin14sData = useMemo(
    () =>
      gtin14List?.getGtin14s?.map((gtins) => {
        return {
          id: String(gtins?.id),
          gtin14: gtins?.gtin14
        };
      }),
    [gtin14List]
  );

  const scenarioConstraintDcDetails = ScenarioConstraintsData.map((data) => {
    return {
      tradingPartnerExternalId: data.tpExternalId,
      gtin14ProductId: Number(data.gtin14ProductId),
      scenarioConstraintDcDetails: data.scenarioConstraintDcDetails?.map((dcIds) => {
        return {
          dcId: Number(dcIds)
        };
      }),
      scenarioConstraintExistingDcDetails: data.scenarioConstraintExistingDcDetails?.map(
        (dcIds) => {
          return {
            dcId: Number(dcIds)
          };
        }
      )
    };
  });

  const columns: ColumnsType<NewItemData> = [
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'GTIN',
      render: (_, record) =>
        invalidRowIndex.includes(record.key) ? (
          <span>
            <img src={errorWarningIcon} alt="error warning icon" style={{ marginRight: '5px' }} />
            {record.gtin}
          </span>
        ) : (
          <span style={{ marginLeft: '20px' }}>{record.gtin}</span>
        )
    },
    {
      title: 'TRADING PARTNER',
      dataIndex: 'marketPlaceName',
      key: 'marketPlaceName'
    },
    {
      title: 'PEP MATERIAL NUMBER',
      dataIndex: 'pepMaterialNumber',
      key: 'pepMaterialNumber'
    },
    {
      title: 'DC LOCATIONS',
      dataIndex: 'dcLocationNames',
      key: 'dcLocations',
      render: (_, { dcLocationNames }) => dcLocationNames.join(', ')
    },
    {
      title: 'PLANTS',
      dataIndex: 'plants',
      key: 'plants',
      render: (_, { plantNames }) => plantNames.join(', ')
    },
    {
      title: 'OVERALL DEMAND',
      dataIndex: 'overallDemand',
      key: 'overallDemand'
    },
    {
      title: 'DAYS ON HAND',
      dataIndex: 'daysOnHand',
      key: 'daysOnHand'
    }
  ];
  const validateData = (data: NewItemData): boolean | undefined => {
    const validateMarketPlace = marketPlaceData?.getTradingPartners?.some(
      (marketPlace) => marketPlace?.externalId === data.marketPlace
    )
      ? true
      : false;
    const validateGtins = gtin14sData?.some((product) =>
      product.gtin14 === data.gtin.toString() ? true : false
    );
    const validateDcLocations = data.dcLocations.every((code) =>
      dcs?.some((dc) => dc?.dcCode === code)
    );
    const validatePlants = data.plants.every((code) =>
      dcs?.some((plant) => plant?.dcCode === code)
    );
    const validation =
      data.marketPlace !== null &&
      validateMarketPlace &&
      validateGtins &&
      validateDcLocations &&
      validatePlants &&
      data.gtin !== null &&
      data.overallDemand !== null &&
      data.overallDemand > 1 &&
      data.overallDemand < 61513 &&
      data.daysOnHand !== null &&
      data.daysOnHand > 6 &&
      data.daysOnHand < 42 &&
      data.pepMaterialNumber !== null;
    return validation;
  };
  const getRowClassName = (record: NewItemData) => {
    return rowValidation[record.key];
  };

  const validateAndSetRowValidation = () => {
    const newRowValidation: { [key: string]: boolean } = {};
    data?.forEach((record) => {
      const validation = !validateData(record);
      newRowValidation[record.key] = validation;
      if (validation) setInvalidRowIndex((prevState) => [...prevState, record.key]);
    });
    setRowValidation(newRowValidation);
  };

  const panelHeader = isPanelOpen ? 'New Item' : 'New Items';
  const [createScenario, { data: scenarioCreation, loading }] = useMutation(
    InsertScenarioDocument,
    {
      refetchQueries: [
        {
          query: GetScenarioListDataDocument
        }
      ],
      onCompleted: () => {
        message.success('Scenario Generation Started, you will notified once it is completed');
      },
      onError: (error) => {
        message.error(error.message);
      }
    }
  );

  const getDCIds = (dcCodes: string[], dcInfo: DistributionCenter[] | undefined): number[] => {
    const resultingArray: number[] = [];
    dcCodes.forEach((code) => {
      const foundDc = dcInfo?.find((dc) => dc.dcCode === code);
      if (foundDc) {
        resultingArray.push(Number(foundDc.dcId));
      }
    });
    return resultingArray;
  };
  function getGtin14ProductId(gtin: string, gtin14s: Gtin14Product[] | undefined): number | null {
    const matchedGtin = gtin14s?.find((gtinObject) => gtinObject.gtin14 === gtin.toString());

    return matchedGtin ? +atob(matchedGtin.id).split(':')[1] : null;
  }

  const getUniqueArray = (inputArray: string[]) => {
    return Array.from(new Set(inputArray));
  };
  const checkLength = (inputStr: string, totalLength: number) => {
    const paddedStr = inputStr.padStart(totalLength, '0');
    return paddedStr;
  };

  const readExcelFile = (file: UploadFile | undefined | any): Promise<XLSXWorkBook> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        if (data) {
          const workbook = XLSXread(data, { type: 'binary' });
          resolve(workbook);
        } else {
          reject(new Error('Unable to read file.'));
        }
      };
      reader.readAsBinaryString(file);
    });
  };

  const readSheetData = (workbook: XLSXWorkBook, sheetName: string) => {
    const worksheet = workbook.Sheets[sheetName];
    return XLSXutils.sheet_to_json(worksheet, { header: 1, defval: '' });
  };

  /* Function to transform excel data
  1. Prefill GTIN number with 0s if the length of the number is less than 14
  2. Prefill Pep Material number if the lenght of the number is less than 18
  3. If the user has select ALL mark DC locations as all selected
  4. IF the user has selected all markets mark all markets seleted
  */
  const transformData = (dcDetailsData: any[], skuDetailsData: any[]): NewItemData[] => {
    const transformedData = dcDetailsData.slice(1).map((dcRow, index) => {
      const dcCodes: string[] = dcRow.slice(2).map((data: string) => data.split('|')[1]);
      const dcNames: string[] = dcRow.slice(2).map((data: string) => data.split('|')[0]);
      const gtin14 = String(dcRow[0]);
      const marketPlaceName = dcRow[1].split('|')[0];
      const marketplace: string = dcRow[1].split('|')[1];
      const dcLocations =
        dcCodes.includes('9999') || dcCodes.filter((dc) => dc !== undefined).length === 0
          ? invConstDcCodes.DC_CODES
          : dcCodes.filter((dc) => dc !== undefined);
      const dcLocationNames: string[] =
        dcNames.includes('All') || dcCodes.filter((dc) => dc !== undefined).length === 0
          ? ['All DC locations']
          : dcNames;

      const skuRow = skuDetailsData.slice(1).find((skuRow) => String(skuRow[0]) === gtin14);

      if (skuRow) {
        const plantCodes: string[] = skuRow.slice(4).map((data: string) => data.split('|')[1]);
        const plantName: string[] = skuRow.slice(4).map((data: string) => data.split('|')[0]);
        const pepMaterialNumber = String(skuRow[1]);
        const overallSkuDemand: number = skuRow[2];
        const daysOnHand: number = skuRow[3];
        const plants =
          plantCodes.includes('9999') ||
          plantCodes.filter((plant) => plant !== undefined).length === 0
            ? invConstPlantCodes.PLANT_CODES
            : plantCodes.filter((plant) => plant !== undefined);
        const plantNames =
          plantName.includes('All') ||
          plantCodes.filter((plant) => plant !== undefined).length === 0
            ? ['All Plants']
            : plantName;

        return {
          key: `${gtin14}+${marketplace}+${index}`,
          gtin: gtin14.length < 14 && gtin14.length > 0 ? checkLength(gtin14, 14) : gtin14,
          pepMaterialNumber:
            pepMaterialNumber.length < 18 && pepMaterialNumber.length > 0
              ? checkLength(pepMaterialNumber, 18)
              : pepMaterialNumber,
          overallDemand: overallSkuDemand || null,
          daysOnHand: daysOnHand || null,
          marketPlace: marketplace || null,
          marketPlaceName: marketPlaceName || null,
          plants: getUniqueArray(plants.filter((plant: string) => plant !== '')) || null,
          plantNames: getUniqueArray(plantNames.filter((plant: string) => plant !== '')) || null,
          dcLocations: getUniqueArray(dcLocations.filter((dc: string) => dc !== '')) || null,
          dcLocationNames: getUniqueArray(dcLocationNames.filter((dc: string) => dc !== '')) || null
        };
      }

      return {
        key: `${gtin14}+${marketplace}+${index}`,
        gtin: gtin14.toString().length < 14 ? checkLength(gtin14.toString(), 14) : gtin14,
        pepMaterialNumber: null,
        overallDemand: null,
        daysOnHand: null,
        marketPlace: marketplace || null,
        marketPlaceName: marketPlaceName || null,
        plants: [''],
        plantNames: [''],
        dcLocations: getUniqueArray(dcLocations.filter((dc: string) => dc !== '')) || null,
        dcLocationNames: getUniqueArray(dcLocationNames.filter((dc: string) => dc !== '')) || null
      };
    });

    return transformedData.filter((data) => data.gtin.length > 0);
  };

  const props: UploadProps = {
    name: 'file',
    customRequest: (fileUpload: any) => {
      fileUpload.onSuccess('ok');
    },

    onChange(info) {
      if (info.file.status === 'done') {
        setIsFileUploaded(true);
        setIsUploadFileModal(false);
        handleUpload(info.file.originFileObj);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  //Function to upload excel file and read sheets data from the excel file.

  const handleUpload = async (file: UploadFile | undefined) => {
    try {
      const workbook = await readExcelFile(file);
      const dcDetailsData = readSheetData(workbook, 'DC_Details');
      const skuDetailsData = readSheetData(workbook, 'SKU & Plant Details');
      const transformedData = transformData(dcDetailsData, skuDetailsData);
      const gtinData = transformedData.map((uploadData) => uploadData?.gtin + '');
      const tpExternalIds = transformedData.map(
        (uploadData) => uploadData?.marketPlace?.toString() + ''
      );
      await fetchAllGtin14s({ variables: { gtin14: gtinData } });
      await fetchMarketPlaces({
        variables: { tpExternalIds: tpExternalIds }
      });
      await fetchDCs({
        variables: {
          dcCodes: [...dcCodeNames, ...plantCodeNames]
        }
      });
      setData(transformedData);
    } catch (error) {
      message.error('Error reading/uploading file. Please try again.');
    }
  };

  const handlePanelChange = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const handleActiveButton = (buttonId: number) => {
    setActiveButton(buttonId === activeButton ? null : buttonId);
  };

  const showScenarioModalHandle = () => {
    setIsScenarioModalOpen(true);
  };

  const ScenarioHandleSave = async () => {
    await createScenario({
      variables: {
        input: {
          scenarioName: scenarioName,
          description: scenarioDescription,
          base: false,
          scenarioConstraints: scenarioConstraintDcDetails,
          scenarioConstraintsNewItems: scenarioConstraintsNewItems || []
        }
      }
    });
    setIsScenarioModalOpen(false);
  };

  const ScenarioHandleCancel = () => {
    setIsScenarioModalOpen(false);
  };

  const uploadModalHandle = () => {
    setIsUploadFileModal(true);
  };

  const handleUploadCancel = () => {
    setIsUploadFileModal(false);
  };
  const handleDelete = () => {
    const updatedData = data?.filter((data) => !selectedRowsKeys.includes(data.key));
    setData(updatedData);
    setInvalidRowIndex(
      invalidRowIndex.filter((invalidRow) => !selectedRowsKeys.includes(invalidRow))
    );
    setSelectedRowsKeys([]);
  };

  const locale = {
    emptyText: (
      <div className={s.addItmesBtn}>
        {activeButton === 1 && <span onClick={uploadModalHandle}>+ Add Items</span>}
      </div>
    )
  };
  useEffect(() => {
    validateAndSetRowValidation();
    const scenarioConstraintsNewItems = data?.map((data) => {
      return {
        gtin14ProductId: Number(getGtin14ProductId(data.gtin, gtin14sData)),
        tradingPartnerExternalId: String(data.marketPlace),
        dcIds: getDCIds(data.dcLocations, dcs),
        plantIds: getDCIds(data.plants, dcs),
        pepMaterialNumber: String(data.pepMaterialNumber),
        daysOnHand: Number(data.daysOnHand),
        overallDemand: Number(data.overallDemand)
      };
    });
    setScenarioConstraintsNewIems(scenarioConstraintsNewItems);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowsKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowsKeys,
    onChange: onSelectChange
  };

  const handleScenarioNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setScenarioName(newName);
  };
  const scenarioCheckFunction = async () => {
    await checkScenarioName({ variables: { scenarioName: scenarioName } });
  };

  const debouncedFunction = debounce(scenarioCheckFunction, 1500);

  useEffect(() => {
    debouncedFunction();
    return debouncedFunction.cancel;
  }, [scenarioName]);

  const handleTemplateDownload = () => {
    fetch('/Ecomm_Inventory_Optimization_New_Items_Template.xlsx').then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Ecomm_Inventory_Optimization_New_Items_Template.xlsx';
        alink.click();
      });
    });
  };
  return (
    <>
      <div className={s.newItemContainer}>
        <AlloyCollapse
          onChange={handlePanelChange}
          items={[
            {
              key: '1',
              label: panelHeader,
              children: (
                <>
                  <div className={s.newItemBtnHeader}>
                    <div className={s.subNewItemBtnContent}>
                      <button
                        className={s.allItemBtn}
                        style={{
                          backgroundColor: activeButton === 1 ? '#0597f2' : '#ffffff',
                          color: activeButton === 1 ? '#ffffff' : '#1c1c1c'
                        }}
                        onClick={() => handleActiveButton(1)}
                      >
                        {data ? `All(${data.length.toString().padStart(2, '0')})` : 'All(00)'}
                      </button>

                      <button
                        className={s.errorItemBtn}
                        style={{
                          backgroundColor: activeButton === 2 ? '#CF1322' : '#ffffff',
                          color: activeButton === 2 ? '#ffffff' : '#1c1c1c'
                        }}
                        onClick={() => handleActiveButton(2)}
                      >
                        {data
                          ? `Error(${data
                              ?.filter((data) => invalidRowIndex.includes(data.key))
                              .length.toString()
                              .padStart(2, '0')})`
                          : 'Error(00)'}
                      </button>
                    </div>

                    {invalidRowIndex.length > 0 && (
                      <span>
                        <img src={errorWarningIcon} alt="error warning icon" /> Error message
                      </span>
                    )}
                    <span>
                      <AlloyButton
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={handleDelete}
                        className={selectedRowsKeys.length ? s.active : s.inActive}
                      >
                        Delete
                      </AlloyButton>
                    </span>
                  </div>

                  {activeButton === 1 && (
                    <AlloyTable<NewItemData>
                      loading={gtinLoading || marketPlaceLoading || dcLoading}
                      dataSource={isFileUploaded ? data : []}
                      rowSelection={{ ...rowSelection, selectedRowKeys: selectedRowsKeys }}
                      locale={locale}
                      rowClassName={(record) => (getRowClassName(record) ? s.error_row : '')}
                      columns={columns}
                      pagination={false}
                    />
                  )}
                  {activeButton === 2 && (
                    <AlloyTable<NewItemData>
                      dataSource={
                        isFileUploaded
                          ? data?.filter((data) => invalidRowIndex.includes(data.key))
                          : []
                      }
                      rowSelection={rowSelection}
                      locale={locale}
                      rowClassName={(record) => (getRowClassName(record) ? s.error_row : '')}
                      columns={columns}
                      pagination={false}
                    />
                  )}
                </>
              )
            }
          ]}
        />
      </div>

      <div className={s.createScenarioModalContainer}>
        <AlloyButton
          disabled={
            ScenarioConstraintsData.length < 1 ||
            (data && data?.filter((data) => invalidRowIndex.includes(data.key)).length > 0)
          }
          onClick={showScenarioModalHandle}
          className={s.createScenarioBtn}
          type="primary"
        >
          Create Scenario
        </AlloyButton>

        {isScenarioModalOpen && (
          <AlloyModal
            title={<div className={s.modalTitle}>Create Scenario</div>}
            centered
            bodyStyle={{ height: 250 }}
            open={isScenarioModalOpen}
            onCancel={ScenarioHandleCancel}
            width={340}
            footer={[
              <AlloyButton className={s.modalCancelBtn} key="back" onClick={ScenarioHandleCancel}>
                Cancel
              </AlloyButton>,
              <Link key="save" to={'/inventory-optimization'}>
                <AlloyButton
                  loading={loading || checkScenarioLoading}
                  type="primary"
                  onClick={ScenarioHandleSave}
                  disabled={
                    !scenarioName ||
                    !scenarioDescription ||
                    checkScenarioLoading ||
                    scenarioNameError
                  }
                >
                  Save
                </AlloyButton>
              </Link>
            ]}
          >
            <p className={s.modalContent}>
              Scenario Name <br />
              <input
                className={scenarioNameError ? s.nameError : s.modalInputFiled}
                value={scenarioName}
                onChange={handleScenarioNameChange}
              />
            </p>
            <p className={s.modalContent}>
              Scenario Description <br />
              <textarea
                className={s.modalInputFiled}
                style={{ height: '90px' }}
                value={scenarioDescription}
                onChange={(e) => setScenarioDescription(e.target.value)}
              ></textarea>
            </p>
          </AlloyModal>
        )}
      </div>

      <AlloyModal
        title={<div className={s.modalTitle}>Add New Item</div>}
        footer={null}
        open={isUploadFileModal}
        onCancel={handleUploadCancel}
      >
        <div className={s.uploadModalContainer}>
          <AlloyUpload.Dragger {...props} accept=".xlsx" style={{ border: 'none' }}>
            <img width="80px" height="70px" src={uploadIcon} alt="upload_image" />
            <p>
              <span style={{ color: '#000000', cursor: 'pointer' }}>
                Drag & drop your file here, or
              </span>
              <span style={{ color: '#0597F2', cursor: 'pointer' }}>Browse</span>
            </p>
          </AlloyUpload.Dragger>
          <p onClick={handleTemplateDownload}>
            <span style={{ color: '#757575' }}>Supports xlsx |</span>
            <span style={{ color: '#88B441' }}>Download Format</span>
          </p>
        </div>
      </AlloyModal>
    </>
  );
};

export default NewScenarioAddItem;
