import React, { useRef, useState } from 'react';
import s from './ExportModal.module.scss';
import { FiscalCalendarWeekInputWithWeekOnly } from 'components/PeriodCalendar/helpers';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { camelCase, mapKeys, startCase } from 'lodash-es';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ChargebackDetailsRawDataDocument,
  ChargebackDetailsRawDataQuery
} from './gql/__generated__/chargebackDetailsRawData.query';
import { InferNodeType } from 'common/helpers/mappingHelper';
import { notEmpty } from 'common/helpers/notEmpty';
import { App } from 'ant5';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import moment from 'moment';
import { AlloyAlert } from 'components/ui/AlloyAlert/AlloyAlert';
import { exportDataToXlsx } from 'common/helpers/exportToXlsx';

// 50_000/per page seems to be fine. Otherwise, overhead is too large.
const EXPORT_PAGE_SIZE = 50_000;
// 50_000 record are downloaded in 12 seconds. So, approximately 4000 rows/second.
const DOWNLOAD_SPEED_ROWS_PER_SECOND = 4000;

type ExportNode = InferNodeType<ChargebackDetailsRawDataQuery, 'chargebackDetailsRawData'>;

function transformKeys(object: ExportNode) {
  return mapKeys(object, (_value, key) => {
    if (key.toLowerCase() === 'upc') {
      return 'UPC';
    } else if (key.toLowerCase() === 'ean') {
      return 'EAN';
    } else {
      return startCase(camelCase(key));
    }
  });
}

export const ExportModal = ({
  fiscalCalendarWeeks,
  isVisible,
  setIsVisible
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
}) => {
  const cancelExport = () => {
    resetModal();
    message.warning('Chargebacks export is cancelled');
  };

  const resetModal = () => {
    shouldBeRunning.current = false;
    setExporting(false);
    setLoadedFiles(0);
    setIsVisible(false);
  };

  const { data, loading, error } = useQuery(ChargebackDetailsRawDataDocument, {
    variables: {
      // Shared filters across all pages
      filters: {
        fiscalCalendarWeeks,
        countryCode: 'US'
      },
      first: 1
    },
    skip: !isVisible
  });

  // LazyQuery + Usual query seems to be the easiest way
  const [fetchExportChargebacksData] = useLazyQuery(ChargebackDetailsRawDataDocument);

  const totalRecords = data?.chargebackDetailsRawData?.totalCount || 0;
  const timeInSeconds = data?.chargebackDetailsRawData?.totalCount
    ? totalRecords / DOWNLOAD_SPEED_ROWS_PER_SECOND
    : 0;
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  const formattedMinutes = minutes === 1 ? '1 minute' : `${minutes} minutes`;
  const formattedSeconds = seconds === 1 ? '1 second' : `${seconds} seconds`;
  const formattedTime =
    minutes > 0 ? `${formattedMinutes} and ${formattedSeconds}` : formattedSeconds;

  const files = Math.ceil((totalRecords || 0) / EXPORT_PAGE_SIZE);

  // Unfortunately we need a separate loading state
  const [exporting, setExporting] = useState(false);
  const shouldBeRunning = useRef(false);

  const [loadedFiles, setLoadedFiles] = useState(0);
  const { message, modal } = App.useApp();

  const handleCancel = () => {
    if (exporting) {
      modal.confirm({
        title: 'Are you sure you want to stop exporting chargebacks?',
        onOk: () => {
          cancelExport();
        }
      });
    } else {
      setIsVisible(false);
    }
  };

  const exportData = async () => {
    if (exporting) return;

    const first = EXPORT_PAGE_SIZE;
    let hasNextPage = true;
    let after = null;
    let iteration = 1;
    const startTime = moment();

    setExporting(true);
    shouldBeRunning.current = true;
    setLoadedFiles(0);

    while (hasNextPage && shouldBeRunning.current) {
      try {
        const rawData = await fetchExportChargebacksData({
          variables: {
            first,
            after,
            filters: {
              fiscalCalendarWeeks,
              countryCode: 'US'
            }
          }
        });

        // It's extremely sub-optimal since we are not actually canceling the request
        // but abort controller is acting up
        if (!shouldBeRunning.current) {
          return;
        }

        if (rawData.data && rawData.data?.chargebackDetailsRawData?.totalCount === 0) {
          message.error(`Error: no data to export.`);
          return;
        }

        if (rawData.data && rawData.data.chargebackDetailsRawData) {
          const pageData = rawData.data.chargebackDetailsRawData.edges
            .map((edge) => edge.node)
            .filter(notEmpty)
            .map(transformKeys);

          const fileName = `chargebacks-data-${startTime.format('MM-DD-YYYY_hh-mm-ss_A')}-${iteration}.xlsx`;

          // It's extremely sub-optimal since we are not actually canceling the request
          // but abort controller is acting up
          if (!shouldBeRunning.current) {
            return;
          }

          exportDataToXlsx(fileName, [{ tabName: 'Chargebacks', itemsToExport: pageData }], {
            autoSetColumnWidth: true
          });

          hasNextPage = rawData.data.chargebackDetailsRawData.pageInfo.hasNextPage;
          after = rawData.data.chargebackDetailsRawData.pageInfo.endCursor;

          setLoadedFiles(iteration);
          message.success(`File ${fileName} loaded. (${iteration}/${files})`);
          iteration++;
        } else {
          message.error(
            'An error occurred while fetching data: no data for current file. Stopping the process.'
          );
          hasNextPage = false;
        }
      } catch (error) {
        message.error('An error occurred while fetching data:', error);
        hasNextPage = false;
      }
    }
    resetModal();
  };

  return (
    <AlloyModal
      title="Export chargebacks data"
      open={isVisible}
      onCancel={handleCancel}
      width={400}
      destroyOnClose
      footer={
        <>
          <div className={s.modal_buttons}>
            <AlloyButton
              type="secondary"
              className={s.bold}
              size="large"
              onClick={handleCancel}
              data-testid="chargebacks-export-modal-close-button"
            >
              Cancel
            </AlloyButton>
            <AlloyButton
              size="large"
              style={{ width: 'auto' }}
              type="primary"
              onClick={() => exportData()}
              loading={exporting}
              data-testid="chargebacks-export-modal-download-button"
              disabled={totalRecords === 0}
            >
              Download
            </AlloyButton>
          </div>
          {error && <p className={s.error}>Error occured while loading xlsx, try again later.</p>}
        </>
      }
    >
      <AlloySpin
        spinning={exporting}
        percent={(loadedFiles / files) * 100}
        tip={loadedFiles >= 0 && <div className={s.tip}>{`${loadedFiles}/${files}`}</div>}
      >
        <AlloySpin spinning={loading}>
          {totalRecords > 0 || loading ? (
            <>
              <p className={s.text}>
                We will export <span className={s.bold}>ALL</span> chargebacks data into an{' '}
                <code>.xlsx</code> file.
              </p>
              {files > 1 && (
                <div className={s.warnings}>
                  <AlloyAlert
                    message={`${data?.chargebackDetailsRawData?.totalCount.toLocaleString()} records will be downloaded. It will take at least ${formattedTime} to download them all, and result will be split into ${files} files.`}
                    type="info"
                  />
                  <AlloyAlert
                    message={`Please not close or change tabs after pressing "Download" button: it might interfere with export process.`}
                    type="warning"
                  />
                </div>
              )}
            </>
          ) : (
            <p className={s.text}>There are 0 records to export in selected period</p>
          )}
        </AlloySpin>
      </AlloySpin>
    </AlloyModal>
  );
};
