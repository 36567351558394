import { InferNodeType } from 'common/helpers/mappingHelper';
import { WarehouseTruckloadsQuery } from '../TruckloadsView/gql/__generated__/warehouseTruckloads.query';
import moment from 'moment';
import { exportDataToXlsx } from 'common/helpers/exportToXlsx';
import { dateFormat } from 'common/helpers/date';

type WarehouseTruckloads = InferNodeType<WarehouseTruckloadsQuery, 'warehouseTruckloads'>;

type Orders = NonNullable<NonNullable<WarehouseTruckloads['orders']>[number]>;

type PlceDocRow = {
  truck: WarehouseTruckloads['truck'];
  plant: WarehouseTruckloads['plant'];
  sapNumber: Orders['orderNumber'];
  shipToName: WarehouseTruckloads['shipToName'];
  shipToCity: WarehouseTruckloads['shipToCity'];
  suggestedRdd: WarehouseTruckloads['suggestedRdd'];
  totalWeights: Orders['totalWeights'];
  units: Orders['unitsOrdered'];
  totalPallets: Orders['totalPallets'];
  customerPo: Orders['customerPo'];
};

export const exportTruckloadsData = (trucks: WarehouseTruckloads[]) => {
  const fileName = `Joilet_${moment().format('MM.DD.YY')}.xlsx`;

  const flatOrders: PlceDocRow[] = trucks.reduce((acc: PlceDocRow[], truck) => {
    const orders = truck.orders.reduce((orderAcc: Orders[], order) => {
      if (!order) return orderAcc;
      return [...orderAcc, order];
    }, []);

    const rowItems = orders.map((order) => ({
      truck: truck.truck,
      plant: truck.plant,
      sapNumber: order?.orderNumber,
      customerPo: order.customerPo,
      shipToName: truck.shipToName,
      shipToCity: truck.shipToCity,
      suggestedRdd: truck.suggestedRdd,
      totalWeights: order.totalWeights,
      units: order.unitsOrdered,
      totalPallets: order.totalPallets
    }));

    return [...acc, ...rowItems];
  }, []);

  exportDataToXlsx(fileName, [
    {
      tabName: 'Truckloads',
      // @ts-expect-error: formatting the cell throws a type error because it doesn't recognize it as part of the type
      itemsToExport: flatOrders.map((item) => ({
        Truck: item.truck,
        'Origin ID': item.plant,
        'SAP #': item.sapNumber,
        'Customer PO#': item.customerPo,
        'Ship-to-Loc': item.shipToName,
        'Ship-to-City': item.shipToCity,
        'Original RDD': dateFormat(item.suggestedRdd),
        'Total Weight': item.totalWeights,
        Units: item.units,
        Pallets: {
          v: item.totalPallets,
          t: 'n' as const,
          z: '#,##0.00'
        },
        'Pickup Requests': '',
        'Updated RDD': ''
      })),
      headers: [
        { name: 'Truck', width: 75 },
        { name: 'Origin ID', width: 75 },
        { name: 'SAP #', width: 100 },
        { name: 'Customer PO#', width: 100 },
        { name: 'Ship-to-Loc', width: 250 },
        { name: 'Ship-to-City', width: 150 },
        { name: 'Original RDD', width: 100 },
        { name: 'Total Weight', width: 75 },
        { name: 'Units', width: 75 },
        { name: 'Pallets', width: 75 },
        { name: 'Pickup Requests', width: 100 },
        { name: 'Updated RDD', width: 100 }
      ]
    }
  ]);
};
