import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import React, { useEffect, useMemo, useState } from 'react';
import s from './TruckloadsView.module.scss';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { Paginator } from 'components/Paginator/Paginator';
import { useMutation, useQuery } from '@apollo/client';
import {
  WarehouseTruckloadsDocument,
  WarehouseTruckloadsQuery
} from './gql/__generated__/warehouseTruckloads.query';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { SortOrderDirection, TruckloadSortColumn } from 'graphql/__generated__/types';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'common/constants';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { formatNumber } from 'common/helpers/formatNumber';
import { calculatePercentageOfCapacity } from '../helper';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { notEmpty } from 'common/helpers/notEmpty';
import { App, Empty } from 'ant5';
import { dateFormat } from 'common/helpers/date';
import AlloyProgressBar from 'components/ui/AlloyProgressBar/AlloyProgressBar';
import clsx from 'clsx';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { RemoveWarehouseTruckloadsDocument } from './gql/__generated__/removeWarehouseTruckloads.mutation';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { SearchOutlined } from '@ant-design/icons';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { WhseTruckBuilderOrdersDocument } from '../OrdersView/gql/__generated__/whseTruckBuilderOrders.query';
import Copy from 'components/Common/Copy';
import { SendWarehouseTruckloadsToPlceDocument } from './gql/__generated__/sendWarehouseTruckloadsToPlce.mutation';
import { exportTruckloadsData } from '../utils/exportTruckloadsData';

type WarehouseTruckloads = InferNodeType<WarehouseTruckloadsQuery, 'warehouseTruckloads'>;

type Orders = NonNullable<NonNullable<WarehouseTruckloads['orders']>[number]>;

const getRowKey = (record: WarehouseTruckloads) => record.truck;
const getExpandedRowKey = (order: Orders) => order.orderNumber;

const TRUCKLOADS_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[3];
const TABLE_WIDTH = 1590;

export const TruckloadsView = () => {
  const { message } = App.useApp();
  const [selectedRows, setSelectedRows] = useState<WarehouseTruckloads[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const [pageSize, setPageSize] = useQueryParam(
    'limit',
    withDefault(NumberParam, TRUCKLOADS_DEFAULT_PAGE_SIZE)
  );
  const [sortColumn, setSortColumn] = useQueryParam('truckloads_column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('truckloads_order', StringParam);
  const [after, setAfter] = useQueryParam('truckloads_after', StringParam);
  const [before, setBefore] = useQueryParam('truckloads_before', StringParam);

  const { data, loading, error } = useQuery(WarehouseTruckloadsDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as TruckloadSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      filter: { statuses: ['TRUCKLOAD_BUILT'] },
      after,
      before
    }
  });

  const [sendWarehouseTruckloadsToPlce, { loading: loadingSendToPlce }] = useMutation(
    SendWarehouseTruckloadsToPlceDocument,
    {
      onCompleted: (data) => {
        message.success(
          <span data-testid="send-to-plce-success-message">
            {data.sendWarehouseTruckloadsToPlce?.message}
          </span>
        );
      },
      onError: (error) => {
        message.error(<span data-testid="send-to-plce-error-message">{error.message}</span>);
        console.error(error.message);
      },
      refetchQueries: [WarehouseTruckloadsDocument]
    }
  );

  const truckloadsList = useMemo(() => getNodesFromEdges(data?.warehouseTruckloads), [data]);

  const [removeWarehouseTruckloads, { loading: removeWarehouseTruckloadsLoading }] = useMutation(
    RemoveWarehouseTruckloadsDocument,
    {
      onCompleted: (data) => {
        message.success({
          content: (
            <span data-testid="remove-truckloads-success-message">
              {data.removeWarehouseTruckloads?.message}
            </span>
          ),
          duration: 4.5
        });
        setSelectedRows([]);
      },
      onError: (error) => {
        message.error(<span data-testid="remove-truckloads-error-message">{error.message}</span>);
        console.error(error.message);
      },
      refetchQueries: [WarehouseTruckloadsDocument, WhseTruckBuilderOrdersDocument]
    }
  );

  const handleRemoveWarehouseTruckloads = async () => {
    const trucks = selectedRows.map((row) => row.truck);
    await removeWarehouseTruckloads({
      variables: {
        input: {
          trucks
        }
      }
    });
  };

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(TRUCKLOADS_DEFAULT_PAGE_SIZE);
      setSortColumn('SHIPMENT');
      setSortOrder('DESC');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  const nextPage = async () => {
    setAfter(data?.warehouseTruckloads?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.warehouseTruckloads?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const truckloadsColumns: ColumnsType<WarehouseTruckloads> = [
    AlloyTable.SELECTION_COLUMN,
    {
      title: <span data-testid="truckloads-table-shipments-column-title">{'Shipments'}</span>,
      key: 'SHIPMENT',
      width: 198,
      render: (_, record) =>
        record.isLtl ? (
          <div className={s.shipment_column_container}>
            <div className={s.shipment}>{`Truck ${record.truck} `}</div>
            <div data-testid="ltl-indicator" className={s.ltl}>{`(LTL)`}</div>
          </div>
        ) : (
          <span>{`Truck ${record.truck}`}</span>
        ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIPMENT'),
      sorter: true
    },
    // {
    //   title: 'BOL',
    //   width: '198px',
    //   render: (_, record) => record.bolId //TODO: this column is waiting for BE and Design
    // },
    {
      title: <span data-testid="truckloads-table-orders-column-title">{'Orders'}</span>,
      key: 'ORDER_NUMBER',
      width: 198,
      render: (_, record) => {
        const orderNumbersToCopy = record.orders.map((order) => order?.orderNumber).join(' , ');
        if (record.orders && record.orders.length > 1) {
          return (
            <Copy
              data-testid="copy-order-number-button"
              customValue={orderNumbersToCopy}
            >{`${record.orders.length} orders`}</Copy>
          );
        } else if (record.orders && record.orders.length === 1) {
          return <Copy data-testid="copy-multiple-order-numbers-button">{orderNumbersToCopy}</Copy>;
        }
      }
    },
    {
      title: <span data-testid="truckloads-table-ship-to-column-title">{'Ship To'}</span>,
      key: 'SHIP_TO',
      width: 198,
      align: 'left',
      render: (_, record) => record.shipTo,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_TO'),
      sorter: true
    },
    {
      title: (
        <span data-testid="truckloads-table-total-weights-column-title">{'Total Weights'}</span>
      ),
      key: 'TOTAL_WEIGHTS',
      width: 198,
      align: 'right',
      render: (_, record) => (
        <>
          <span className={clsx(s.total_weights, s.number_value, { [s.ltl]: record.isLtl })}>
            {formatNumber(record.totalWeights)}
          </span>
          <span className={clsx(s.total_weights, s.number_value, { [s.ltl]: record.isLtl })}>
            {`(${calculatePercentageOfCapacity(record.totalWeights || 0)}%)`}
          </span>
        </>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_WEIGHTS'),
      sorter: true
    },
    {
      title: <span data-testid="truckloads-table-progress-bar-column-no-title">{''}</span>,
      key: 'LOAD_PROGRESS',
      width: 140,
      render: (_, record) => (
        <AlloyProgressBar
          style={{ width: 95 }}
          progress={calculatePercentageOfCapacity(record.totalWeights || 0)}
        />
      )
    },
    {
      title: <span data-testid="truckloads-table-pallets-column-title">{'Pallets'}</span>,
      key: 'PALLETS',
      align: 'right',
      width: 104,
      render: (_, record) => (
        <span className={s.number_value}>{parseFloat(record.totalPallets || '0').toFixed(2)}</span>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'PALLETS'),
      sorter: true
    },
    {
      title: <span data-testid="truckloads-table-ship-date-column-title">{'Ship Date'}</span>,
      key: 'SHIP_DATE',
      minWidth: 96,
      render: (_, record) => dateFormat(record.shipDate),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_DATE'),
      sorter: true
    },
    {
      title: (
        <span data-testid="truckloads-table-suggested-rdd-column-title">{'Suggested RDD'}</span>
      ),
      key: 'SUGGESTED_RDD',
      minWidth: 96,
      render: (_, record) => dateFormat(record.suggestedRdd),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SUGGESTED_RDD'),
      sorter: true
    }
  ];

  const EmptyCell = () => {};

  const FAKE_SELECT_AND_TRUCKLOADS_COLUMN = {
    title: '',
    key: 'expanderSelectAndTruckloads',
    width: 230,
    render: EmptyCell
  };

  const truckloadOrderColumns: ColumnsType<Orders> = [
    FAKE_SELECT_AND_TRUCKLOADS_COLUMN,
    {
      key: 'ORDER_NUMBER',
      width: 198,
      render: (_, record) => record.orderNumber
    },
    {
      key: 'TOTAL_WEIGHTS',
      width: 198,
      align: 'right',
      render: (_, record) => (
        <>
          <span className={clsx(s.total_weights, s.number_value)}>
            {formatNumber(record.totalWeights)}
          </span>
          <span>{`(${calculatePercentageOfCapacity(record.totalWeights || 0)}%)`}</span>
        </>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_WEIGHTS'),
      sorter: true
    },
    {
      key: 'LOAD_PROGRESS',
      width: 140,
      render: (_, record) => (
        <AlloyProgressBar
          style={{ width: 95 }}
          progress={calculatePercentageOfCapacity(record.totalWeights || 0)}
        />
      )
    },
    {
      key: 'PALLETS',
      align: 'right',
      width: 198,
      render: (_, record) => (
        <span className={s.number_value}>{parseFloat(record.totalPallets || '0').toFixed(2)}</span>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'PALLETS'),
      sorter: true
    },
    {
      key: 'SHIP_DATE',
      minWidth: 82,
      render: (_, record) => dateFormat(record.shipDate),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_DATE'),
      sorter: true
    },
    {
      key: 'SUGGESTED_RDD',
      minWidth: 82,
      render: (_, record) => dateFormat(record.suggestedRdd),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SUGGESTED_RDD'),
      sorter: true
    }
  ];

  const currentPageTruckloadsExpandableKeys = useMemo(() => {
    return truckloadsList.map(getRowKey);
  }, [truckloadsList]);

  const areAllKeysExpandedOnThePage = useMemo(
    () =>
      currentPageTruckloadsExpandableKeys.every((truckload) => expandedRowKeys.includes(truckload)),
    [expandedRowKeys, currentPageTruckloadsExpandableKeys]
  );

  const expandableTruckloadRows = useMemo(() => {
    return truckloadsList.filter((truckload) => truckload.orders.length > 1);
  }, [truckloadsList]);

  const truckloadsTableEmptyText = (
    <div data-testid="truckloads-table-empty-text" className={s.truckloads_table_empty_state_title}>
      No truckloads found
    </div>
  );

  //TODO: do we need a success message here?
  const onSendToPlce = async () => {
    const trucksNumbers = selectedRows.map((row) => row.truck);
    const truckloads = truckloadsList.filter((truck) => trucksNumbers.includes(truck.truck));
    await sendWarehouseTruckloadsToPlce({
      variables: {
        input: {
          trucks: trucksNumbers
        }
      }
    });

    try {
      exportTruckloadsData(truckloads);
    } catch (e) {
      message.error(<span data-testid="send-to-plce-error-message">{e.message}</span>);
      console.error(e.message);
    }
  };

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <AlloySpin data-testid="truckloads-view-loader-spinner" spinning={loading}>
        <div className={s.table_tools_container}>
          <div
            data-testid="truckloads-table-search-and-filters-container"
            className={s.search_and_filters_container}
          >
            <div className={s.search_bar}>
              <AlloyInput
                data-testid="truckloads-table-search-input"
                placeholder="Search"
                allowClear={true}
                prefix={<SearchOutlined width="14px" height="14px" />}
                disabled={true}
              />
            </div>
            <AlloySelect
              data-testid="truckloads-table-filters"
              className={s.select}
              placeholder="Filter"
              allowClear
              options={[
                {
                  key: 'all',
                  value: 'all',
                  label: 'All'
                }
              ]}
              disabled={true}
            />
          </div>

          <div
            data-testid="truckloads-table-actions-container"
            className={s.table_actions_container}
          >
            <AlloyButton
              data-testid="remove-truckloads-button"
              disabled={selectedRows.length === 0}
              loading={removeWarehouseTruckloadsLoading}
              onClick={handleRemoveWarehouseTruckloads}
              type="secondary"
            >
              Remove
            </AlloyButton>
            <AlloyButton
              data-testid="send-plce-button"
              style={{ marginLeft: 'auto' }}
              loading={loadingSendToPlce}
              disabled={selectedRows.length === 0}
              onClick={onSendToPlce}
              type="primary"
            >
              Send PLCE
            </AlloyButton>
          </div>
        </div>

        <div
          data-testid="truckloads-table-quantity-displya-and-paginator-container"
          className={s.quantity_display_and_paginator_container}
        >
          <div>
            <QuantityDisplay
              data-testid="truckloads-table-quantity-display"
              count={
                selectedRows?.length > 0
                  ? selectedRows.length
                  : data?.warehouseTruckloads?.totalCount
              }
              titleSingular={`${selectedRows?.length > 0 ? 'Selected ' : ''} Shipment`}
              titleMultiple={`${selectedRows?.length > 0 ? 'Selected ' : ''} Shipments`}
            />
          </div>
          <Paginator
            pageSize={pageSize || TRUCKLOADS_DEFAULT_PAGE_SIZE}
            hasNextPage={!!data?.warehouseTruckloads?.pageInfo.hasNextPage}
            hasPreviousPage={!!data?.warehouseTruckloads?.pageInfo.hasPreviousPage}
            handlePageSizeChange={handlePageSizeChange}
            prevPage={prevPage}
            nextPage={nextPage}
            onlyButtons={false}
          />
        </div>
        <AlloyTable
          data-testid="truckloads-table"
          scroll={{ x: TABLE_WIDTH }}
          sticky
          pagination={false}
          columns={truckloadsColumns.filter(notEmpty)}
          dataSource={truckloadsList}
          onChange={(_pagination, _filters, sorter) => {
            const column = (sorter as SorterResult<WarehouseTruckloads>).columnKey || 'SHIPMENT';
            const order =
              (sorter as SorterResult<WarehouseTruckloads>).order === 'ascend' ||
              !(sorter as SorterResult<WarehouseTruckloads>).order
                ? 'ASC'
                : 'DESC';
            handleTableSorting(column.toString(), order);
          }}
          rowKey={getRowKey}
          rowSelection={{
            preserveSelectedRowKeys: true,
            onChange: (_keys, rows) => {
              setSelectedRows(rows);
            },
            type: 'checkbox',
            selectedRowKeys: selectedRows?.map((row) => row.truck),
            // @ts-expect-error: CheckboxProps partial type has no properties in common with { data-testid: string } type
            getCheckboxProps(record) {
              return { 'data-testid': record.truck };
            }
          }}
          locale={{
            emptyText: (
              <Empty
                data-testid="truckloads-table-empty-state"
                className={s.truckloads_table_empty_state}
                description={truckloadsTableEmptyText}
              />
            )
          }}
          expandable={{
            columnTitle: currentPageTruckloadsExpandableKeys.length > 0 &&
              expandableTruckloadRows.length > 1 && (
                <ExpandButton
                  expanded={areAllKeysExpandedOnThePage}
                  onClick={(expanded) => {
                    return expanded
                      ? setExpandedRowKeys(
                          expandedRowKeys.filter(
                            (x) => !currentPageTruckloadsExpandableKeys.includes(x)
                          )
                        )
                      : setExpandedRowKeys([
                          ...new Set([...currentPageTruckloadsExpandableKeys, ...expandedRowKeys])
                        ]);
                  }}
                  collapseMessage="Collapse all"
                  expandMessage="Expand all"
                />
              ),
            expandedRowKeys: expandedRowKeys,
            expandedRowRender: ({ orders }) => (
              <AlloyTable
                data-testid="truckloads-orders-table"
                columns={truckloadOrderColumns.filter(notEmpty)}
                dataSource={orders?.map((order) => order).filter(notEmpty)}
                pagination={false}
                rowKey={getExpandedRowKey}
                showHeader={false}
              />
            ),
            onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as number[]),
            rowExpandable: (record) => !!record.orders && record.orders.length > 1
          }}
        />
        <div data-testid="truckloads-table-bottom-paginator" className={s.bottom_paginator}>
          <Paginator
            pageSize={pageSize || TRUCKLOADS_DEFAULT_PAGE_SIZE}
            hasNextPage={!!data?.warehouseTruckloads?.pageInfo.hasNextPage}
            hasPreviousPage={!!data?.warehouseTruckloads?.pageInfo.hasPreviousPage}
            handlePageSizeChange={handlePageSizeChange}
            prevPage={prevPage}
            nextPage={nextPage}
            onlyButtons={true}
          />
        </div>
      </AlloySpin>
    </>
  );
};
