// Cypress variable is present if Cypress opens current session
declare global {
  interface Window {
    Cypress?: unknown;
    isUnderTest: boolean;
    _DATADOG_SYNTHETICS_BROWSER?: unknown;
  }
}

declare const REACT_APP_GIT_COMMIT: string;
declare const REACT_APP_SENTRY_RELEASE: string;

// vite doesn't seem to work properly with optional chaining in env variables
// That's why we can not do import?.meta?.env and need to write manual fallback to ""
// "backend" is service name when running in CI
export const isProductionBackend = () => {
  const backendUrl = import.meta.env.REACT_APP_API_URL || '';
  return (
    !backendUrl.includes('staging') &&
    !backendUrl.includes('shipyard') &&
    !backendUrl.includes('localhost') &&
    !backendUrl.includes('backend')
  );
};

export const isStagingBackend = () => {
  const backendUrl = import.meta.env.REACT_APP_API_URL || '';
  return (
    backendUrl.includes('staging') ||
    backendUrl.includes('shipyard') ||
    backendUrl.includes('localhost') ||
    backendUrl.includes('backend')
  );
};

export type FeEnvironments = 'production' | 'staging' | 'shipyard' | 'localhost' | 'unknown';

export const getFrontendEnvironmentName = (): FeEnvironments => {
  const link = window?.location?.href;

  if (link?.includes('pepsi-ecom.com')) return 'production';
  if (link?.includes('shipyard.host')) return 'shipyard';
  if (link?.includes('staging')) return 'staging';
  if (link?.includes('localhost')) return 'localhost';

  return 'unknown';
};

export const getGitCommit = (): string => {
  return REACT_APP_GIT_COMMIT || 'unknown';
};

export const getSentryRelease = (): string => {
  return REACT_APP_SENTRY_RELEASE;
};

export const isE2ETestSession = (): boolean => {
  return typeof window.Cypress !== 'undefined' || typeof window.isUnderTest !== 'undefined';
};

// https://docs.datadoghq.com/synthetics/guide/identify_synthetics_bots/?tab=singleandmultistepapitests#browser-variables
export const isDatadogSyntheticSession = (): boolean => {
  return typeof window._DATADOG_SYNTHETICS_BROWSER !== 'undefined';
};
