import { RetailerDeliveryDestination } from 'pages/ShipToPage/components/ShipToViewDrawer/ShipToViewDrawer';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import React, { useMemo } from 'react';
import s from './TpSpecificSettingsViewTab.module.scss';
import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import clsx from 'clsx';
import expandIcon from 'assets/icons/editing/expand_icon.svg';
import { ViewTable } from 'components/Common/ViewMode/ViewTable/ViewTable';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';

type TradingPartnerRetailerDeliveryDestinations = NonNullable<
  NonNullable<RetailerDeliveryDestination['tradingPartnerRetailerDeliveryDestinations']>[number]
>;

export const TpSpecificSettingsViewTab = ({
  shipTo
}: {
  shipTo: RetailerDeliveryDestination | null | undefined;
}) => {
  return (
    <>
      {shipTo?.tradingPartnerRetailerDeliveryDestinations?.map((tpRdd, index) =>
        tpRdd ? (
          <ViewRow
            key={index}
            title={tpRdd?.tradingPartner?.name || 'TP'}
            content={<TpContent tpRdd={tpRdd} />}
            bordered={
              index !== (shipTo.tradingPartnerRetailerDeliveryDestinations?.length || 0) - 1
            }
          />
        ) : null
      )}
    </>
  );
};

const TpContent = ({ tpRdd }: { tpRdd: TradingPartnerRetailerDeliveryDestinations }) => {
  const soldTos = useMemo(() => {
    const newSoldTos = [...tpRdd.dcSoldTos];
    if (tpRdd.alternateSoldTo) newSoldTos.push({ dcCode: '--', soldTo: tpRdd.alternateSoldTo });
    if (!newSoldTos.length) newSoldTos.push({ dcCode: '--', soldTo: '--' });
    return newSoldTos;
  }, [tpRdd]);

  return (
    <>
      <div className={s.sold_to_dc_table_container}>
        <ViewTable
          size="small"
          data={soldTos}
          columns={[
            {
              title: 'SAP SOLD - TO',
              dataIndex: 'soldTo',
              withTooltip: true
            },
            {
              title: 'Distribution Center',
              dataIndex: 'dcCode',
              withTooltip: true
            }
          ]}
        />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="First Delivery Date" value={tpRdd.firstDeliveryDate} />
        <ViewField type="INPUT" title="Route Number" value={tpRdd.routeNumber} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Release Method" value={tpRdd.releaseMethod} />
        <ViewField type="INPUT" title="Forecast Algorithm" value={tpRdd.defaultForecastingSource} />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Business Unit Delivery Destination Code"
          value={tpRdd.businessUnitDeliveryDestinationCode}
        />
        <ViewField type="INPUT" title="Rounding" value={tpRdd.rounding} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Store Capacity" value={tpRdd.storeCapacity} />
        <ViewField
          type="INPUT"
          title="Capacity Cubic Feet Boh"
          value={tpRdd.capacityCubicFeetBoh}
        />
      </div>
      <ViewField
        type="INPUT"
        title="Front Of House Fill Percentage"
        value={tpRdd.frontOfHouseFillPercentage}
      />
      <div className={s.subtitle}>Additional features</div>
      <div className={s.features_container}>
        <ViewField type="SWITCH" title="Auto Forecast" value={!!tpRdd.autoForecast} />
        <ViewField type="SWITCH" title="Auto Fulfill" value={!!tpRdd.autoFulfill} />
        <ViewField type="SWITCH" title="Extra Day Of Cover" value={!!tpRdd.extraDayOfCover} />
        <ViewField
          type="SWITCH"
          title="Override Expiration Date From Shipment Item"
          value={!!tpRdd.overrideExpirationDateFromShipmentItem}
        />
      </div>
      {tpRdd.deliverySchedule && tpRdd.deliverySchedule.length > 0 && (
        <>
          <div className={clsx(s.subtitle, s.margin_top)}>Delivery Schedule</div>
          <AlloyCollapse
            className={s.delivery_schedule}
            expandIcon={(props) => (
              <img
                alt=""
                src={expandIcon}
                style={!props.isActive ? { transform: 'rotate(270deg)' } : {}}
              />
            )}
            items={tpRdd.deliverySchedule.map((deliveryScheduleItem, index) => ({
              showArrow: true,
              key: index,
              label: (
                <div className={s.row}>
                  <ViewField
                    type="INPUT"
                    title="Day Of Week"
                    value={deliveryScheduleItem?.dayOfWeek}
                    className={s.remove_margin_bottom}
                  />
                  <ViewField
                    type="INPUT"
                    title="Cutoff Time"
                    value={deliveryScheduleItem?.cutoffTime}
                    className={s.remove_margin_bottom}
                  />
                </div>
              ),
              children: (
                <div className={s.delivery_schedule_content}>
                  <div className={s.row}>
                    <ViewField
                      type="INPUT"
                      title="Expected Asn Hours Before Delivery"
                      value={deliveryScheduleItem?.expectedAsnHoursBeforeDelivery}
                    />
                    <ViewField
                      type="INPUT"
                      title="Expected Delivery Time"
                      value={deliveryScheduleItem?.expectedDeliveryTime}
                    />
                  </div>
                  <div className={s.row}>
                    <ViewField
                      type="INPUT"
                      title="Expected Invoice Hours After Delivery"
                      value={deliveryScheduleItem?.expectedInvoiceHoursAfterDelivery}
                    />
                    <ViewField
                      type="INPUT"
                      title="Expected PO Acknowledgement Hours Before Delivery"
                      value={deliveryScheduleItem?.expectedPoAcknowledgementHoursBeforeDelivery}
                    />
                  </div>
                  <div className={s.row}>
                    <ViewField
                      type="INPUT"
                      title="Expected Purchase Order Hours Before Delivery"
                      value={deliveryScheduleItem?.expectedPurchaseOrderHoursBeforeDelivery}
                    />
                    <ViewField
                      type="INPUT"
                      title="Expected Sales Order Hours Before Delivery"
                      value={deliveryScheduleItem?.expectedSalesOrderHoursBeforeDelivery}
                    />
                  </div>
                </div>
              )
            }))}
          />
        </>
      )}
    </>
  );
};
