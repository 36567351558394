import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import ApolloContextProvider from 'context/ApolloContext';
import { datadogRum } from '@datadog/browser-rum';
import {
  FeEnvironments,
  getFrontendEnvironmentName,
  getGitCommit,
  isDatadogSyntheticSession,
  isE2ETestSession
} from 'common/helpers/identificateEnvironment';
import { ConfigProvider, App as Ant5App } from 'ant5';
import { prefixCls, customTheme } from 'theme';

const env = getFrontendEnvironmentName();
const commit = getGitCommit();

// We are not recoding shipyard sessions since they don't have much value. production + staging only
const allowedDDEnvironments: FeEnvironments[] = ['production', 'staging'];

if (allowedDDEnvironments.includes(env) && !isE2ETestSession() && !isDatadogSyntheticSession()) {
  datadogRum.init({
    applicationId: 'bcb41be7-ff6c-4e25-9550-452a99d8f1de',
    clientToken: 'pub7981bff84cb4f9ea71f919e122d17ef1',
    site: 'datadoghq.com',
    service: 'order-excellence-frontend',
    env: env,
    version: `${commit}:${env}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    beforeSend: (event: any) => {
      if (
        event.type === 'error' &&
        (event?.error?.message.includes('ResizeObserver loop limit exceeded') ||
          event?.error?.message.includes(
            'ResizeObserver loop completed with undelivered notifications'
          ))
      ) {
        return false;
      }
      return true;
    },
    defaultPrivacyLevel: 'allow'
  });

  datadogRum.startSessionReplayRecording();

  Sentry.setContext('Datadog RUM', {
    rum_url: datadogRum.getSessionReplayLink()
  });
}

ReactDOM.render(
  <ConfigProvider prefixCls={prefixCls} theme={customTheme}>
    <Ant5App>
      <ApolloContextProvider>
        <App />
      </ApolloContextProvider>
    </Ant5App>
  </ConfigProvider>,
  document.getElementById('root')
);
