import React, { useState } from 'react';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import moment from 'moment';
import s from './CollapsibleConfirmationTable.module.scss';
import { ExpectedDeliveryDateField } from 'components/PurchaseOrders/ExpectedDeliveryDate/ExpectedDeliveryDate';
import { ORDER_ACCEPTED_CONFIRMATION_TYPE } from 'common/constants';
import {
  PurchaseOrderConfirmation,
  PurchaseOrderItemConfirmation
} from 'pages/OrderDetailsPage/types';
import { ConfirmationType } from 'graphql/__generated__/types';
import { EMPTY } from 'common/constants';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';

interface ConfirmationRowProps {
  confirmation: PurchaseOrderConfirmation;
  onChange?: (confirmation: PurchaseOrderConfirmation) => void;
}

const confirmationsMap: Record<ConfirmationType, string> = {
  ORDER_ACCEPTED: 'Initial Confirmation',
  DOWN_CONFIRM: 'Down Confirmation',
  SHIPMENT_CONFIRMATION: 'Shipment Confirmation'
};

const ConfirmationRow = ({ confirmation }: ConfirmationRowProps) => {
  const columns: ColumnsType<PurchaseOrderItemConfirmation> = [
    {
      title: 'UPC',
      key: 'product',
      width: '138px',
      render: (_, item) =>
        item.purchaseOrderItem.catalogProduct?.gtin12 ??
        item.purchaseOrderItem.product?.upc ??
        EMPTY
    },
    {
      title: 'External ID',
      key: 'product',
      width: '138px',
      render: (_, item) =>
        item.purchaseOrderItem.externalId === '' || item.purchaseOrderItem.externalId === null
          ? EMPTY
          : item.purchaseOrderItem.externalId
    },
    {
      title: 'Description',
      key: 'product',
      render: (_, item) =>
        item.purchaseOrderItem.catalogProduct?.name ?? item.purchaseOrderItem.product?.name ?? EMPTY
    },
    {
      title: 'Order Qty',
      key: 'product',
      width: '138px',
      render: (_, item) => (item.orderedUnits === null ? EMPTY : item.orderedUnits)
    },
    {
      title: 'Accepted Qty',
      key: 'product',
      width: '138px',
      render: (_, item) => (item.acceptedUnits === null ? EMPTY : item.acceptedUnits)
    }
  ];

  const withConfirmedQtyColumns: ColumnsType<PurchaseOrderItemConfirmation> = [
    ...columns,
    {
      title: 'Confirmed Qty',
      key: 'product',
      width: '138px',
      render: (_, item) => {
        const hasOutstandingAsns = (item?.purchaseOrderItem?.asnStatus?.outstanding || 0) > 0;
        const hasEqualQtys = item.orderedUnits !== item.acceptedUnits;
        const confirmedByUser = confirmation.status === 'SUCCESS';

        return hasOutstandingAsns ||
          (hasEqualQtys && confirmedByUser) ||
          !confirmedByUser ||
          item.acceptedUnits === null
          ? EMPTY
          : item.acceptedUnits;
      }
    }
  ];

  return (
    <AlloyTable
      className={s.confirmation_table}
      pagination={false}
      rowKey={(row) => row.id}
      dataSource={confirmation.purchaseOrderItemConfirmations.filter(
        ({ purchaseOrderItem, acceptedUnits, orderedUnits }) =>
          purchaseOrderItem?.asnStatus?.outstanding !== 0 ||
          (acceptedUnits !== null && acceptedUnits !== orderedUnits)
      )}
      columns={
        confirmation?.confirmationType === 'DOWN_CONFIRM' ? withConfirmedQtyColumns : columns
      }
    />
  );
};

export const CollapsibleConfirmationTable = ({
  confirmations
}: {
  confirmations: PurchaseOrderConfirmation[];
}) => {
  const [data, setData] = useState(confirmations);

  return (
    <div style={{ height: `calc(100vh - 75px - 246px)`, overflowY: 'scroll', marginTop: '20px' }}>
      <AlloyCollapse
        bordered={false}
        className={s.confirmation_collapse}
        items={confirmations.map((confirmation) => {
          const header = (
            <div className={s.confirmation_header}>
              <div className={s.left_container}>
                <div className={s.confirmation_type}>
                  {confirmationsMap[confirmation.confirmationType] || confirmation.confirmationType}
                </div>
                <div className={s.status}>{confirmation.status}</div>
              </div>

              <div
                className={s.right_container}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className={s.expected_date_container}>
                  <AlloyTooltip
                    data-testid="expected-date-tooltip"
                    placement="topRight"
                    title="PO will remain open until this date"
                  >
                    <div data-testid="expected-date-title" className={s.title}>
                      Expected Date:
                    </div>
                  </AlloyTooltip>

                  {confirmation.confirmationType === ORDER_ACCEPTED_CONFIRMATION_TYPE &&
                  confirmation.status === 'GENERATED' ? (
                    <div data-testid="expected-delivery-date-editable-field">
                      <ExpectedDeliveryDateField confirmation={confirmation} />
                    </div>
                  ) : confirmation.expectedDeliveryDate !== null ? (
                    <div data-testid="expected-delivery-date-non-editable">
                      {moment(confirmation.expectedDeliveryDate).format('M/D/YY')}
                    </div>
                  ) : (
                    <div data-testid="expected-delivery-date-invalid" className={s.invalid_date}>
                      invalid
                    </div>
                  )}
                </div>

                <div className={s.created_container}>
                  <div data-testid="created-on-title" className={s.title}>
                    Created On:
                  </div>
                  <div data-testid="created-on-date-and-time">
                    {moment(
                      confirmation.acceptedAt ||
                        confirmation.rejectedAt ||
                        confirmation.submittedAt ||
                        confirmation.generatedAt
                    ).format('MM/DD/YY, hh:mma')}
                  </div>
                </div>
              </div>
            </div>
          );

          return {
            key: confirmation.id,
            className: s.panel,
            label: header,
            children: (
              <ConfirmationRow
                confirmation={confirmation}
                onChange={(confirmation) => {
                  const target = data.findIndex((c) => c.id === confirmation.id);
                  const updatedData = [...data];
                  updatedData[target] = confirmation;
                  setData(updatedData);
                }}
              />
            )
          };
        })}
      />
    </div>
  );
};
