import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useRef } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import {
  getCoordinatesValidation,
  getLengthValidation,
  parseIntValue,
  validateEmails,
  validateRequired
} from 'common/helpers/validationHelper';
import s from './GeneralSettingsForm.module.scss';
import { useEnumValue } from 'common/useEnumValue';
import moment from 'moment';
import { useForm } from 'react-final-form';
import { DcSourceSystemParamsCheck } from '../DcSourceSystemParamsCheck';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'components/LoaderSpinner';
import { EditDistributionCenterFacilitiesDocument } from 'pages/DistributionCentersPage/gql/__generated__/editDistributionCenterFacilitiesData.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { AddFacilityForm } from '../AddFacilityForm/AddFacilityForm';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { AlloyDivider } from 'components/ui/AlloyDivider/AlloyDivider';

interface GeneralSettingsFormProps {
  upserting: boolean;
}

export const GeneralSettingsForm = ({ upserting }: GeneralSettingsFormProps) => {
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const {
    enumValues: distributionCenterSourceValues,
    loading: distributionCenterSourceValuesLoading
  } = useEnumValue('DistributionCenterSource');
  const { enumValues: inventorySourceValues, loading: inventorySourceValuesLoading } =
    useEnumValue('InventorySource');

  const form = useForm();

  /***********   FACILITIES: CALL & PREPARE LIST  *************/
  const { data: facilitiesData, loading: facilitiesDataLoading } = useQuery(
    EditDistributionCenterFacilitiesDocument
  );

  if (facilitiesDataLoading && !facilitiesData) return <LoaderSpinner />;

  const facilitiesList = getNodesFromEdges(facilitiesData?.distributionCenterFacilities);
  /********************************************************* */

  return (
    <div style={{ flex: '1' }}>
      <AlloyRow className={s.title_row}>General Settings</AlloyRow>
      <AlloyRow gutter={16} style={{ margin: 0 }}>
        <AlloyCol span={24} className={s.integration_settings_info}>
          <DcSourceSystemParamsCheck codeFieldName="code" sourceFieldName="source" />
        </AlloyCol>
        <AlloyCol span={12} style={{ paddingLeft: '0' }}>
          <AlloyFormField
            component="input"
            name="code"
            fieldProps={{
              title: 'Code'
            }}
            validate={!upserting ? validateRequired : undefined}
            required
          />
          <AlloyFormField
            component="select"
            name="source"
            required
            validate={!upserting ? validateRequired : undefined}
            fieldProps={{
              title: 'Source',
              loading: distributionCenterSourceValuesLoading,
              options: (distributionCenterSourceValues || []).map((dcSource) => ({
                value: dcSource,
                label: dcSource
              }))
            }}
          />
        </AlloyCol>
        <AlloyCol span={12} style={{ paddingRight: '0' }}>
          <AlloyFormField
            component="input"
            name="name"
            fieldProps={{
              title: 'Name'
            }}
            validate={!upserting ? validateRequired : undefined}
            required
          />
          <AlloyFormField
            component="select"
            data-testid="dc-facility-select"
            name="distributionCenterFacilityId"
            fieldProps={{
              title: 'Facility',
              options: facilitiesList.map((facility) => ({
                value: facility.id,
                label: facility.name
              })),
              dropdownRender: (menu) => (
                <>
                  {menu}
                  <AlloyDivider style={{ margin: '8px 0' }} />
                  <AddFacilityForm
                    onFacilityAdded={(id) => {
                      form.mutators.setDistributionCenterFacilityId(id);
                      addButtonRef.current?.focus();
                    }}
                  />
                </>
              ),
              loading: facilitiesDataLoading
            }}
          />
        </AlloyCol>
      </AlloyRow>

      {/* Identifiers and Sources section */}
      <FieldArray name="siblings">
        {({ fields }) => (
          <>
            <AlloyRow className={s.title_row}>Identifiers and Sources</AlloyRow>
            <AlloyRow
              style={{
                display: !form.getFieldState('siblings')?.value ? 'none' : undefined
              }}
            >
              {fields.map((name, index) => (
                <div key={index} style={{ width: '100%' }}>
                  <div key={index} className={s.blackout_item}>
                    <AlloyFormField
                      component="input"
                      name={`${name}.code`}
                      fieldProps={{
                        title: 'Code',
                        disabled: true
                      }}
                      required
                      validate={upserting ? validateRequired : undefined}
                    />
                    <AlloyFormField
                      component="select"
                      name={`${name}.source`}
                      fieldProps={{
                        title: 'Source',
                        loading: distributionCenterSourceValuesLoading,
                        options: (distributionCenterSourceValues || []).map((source) => ({
                          value: source,
                          label: source
                        })),
                        disabled: true
                      }}
                      required
                      validate={upserting ? validateRequired : undefined}
                    />
                  </div>
                </div>
              ))}
            </AlloyRow>
          </>
        )}
      </FieldArray>

      <AlloyRow className={s.title_row}>Address</AlloyRow>
      <AlloyRow gutter={16} justify="space-between" style={{ margin: 0 }}>
        <AlloyCol span={24} style={{ paddingLeft: '0', paddingRight: '0' }}>
          <AlloyFormField component="input" name="careOf" fieldProps={{ title: 'Care Of' }} />
        </AlloyCol>
        <AlloyCol span={12} style={{ paddingLeft: '0' }}>
          <AlloyFormField component="input" name="address" fieldProps={{ title: 'Address' }} />
          <AlloyFormField
            component="input"
            name="state"
            fieldProps={{ title: 'State' }}
            validate={getLengthValidation(2)}
          />
          <AlloyFormField
            component="input"
            name="lat"
            validate={getCoordinatesValidation(/^((-?|\+?)?\d+(\.\d+)?)/g)}
            fieldProps={{
              title: 'Latitude'
            }}
          />
          <AlloyFormField component="input" name="phone" fieldProps={{ title: 'Phone' }} />
        </AlloyCol>
        <AlloyCol span={12} style={{ paddingRight: '0' }}>
          <AlloyFormField component="input" name="city" fieldProps={{ title: 'City' }} />
          <AlloyFormField component="input" name="zip" fieldProps={{ title: 'Zip' }} />
          <AlloyFormField
            component="input"
            name="lng"
            validate={getCoordinatesValidation(/^((-?|\+?)?\d+(\.\d+)?)/g)}
            fieldProps={{
              title: 'Longitude'
            }}
          />
          <AlloyFormField
            component="select"
            name="emails"
            fieldProps={{
              title: 'Emails',
              mode: 'tags',
              maxTagCount: undefined,
              options: [],
              suffixIcon: null,
              dropdownStyle: { display: 'none' }
            }}
            validate={validateEmails}
          />
        </AlloyCol>
      </AlloyRow>

      <AlloyRow className={s.title_row}>Truck capacity</AlloyRow>
      <AlloyRow gutter={16} style={{ margin: 0 }}>
        <AlloyCol span={12} style={{ paddingLeft: '0' }}>
          <AlloyFormField
            component="inputNumber"
            name="truckCapacityPallets"
            fieldProps={{
              title: 'Pallets',
              parser: parseIntValue
            }}
            required
            validate={validateRequired}
          />
        </AlloyCol>
        <AlloyCol span={12} style={{ paddingRight: '0' }}>
          <AlloyFormField
            component="inputNumber"
            name="truckCapacityWeight"
            fieldProps={{
              title: 'Weight (lb)',
              parser: parseIntValue
            }}
            required
            validate={validateRequired}
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyRow className={s.title_row}>Cost & Capacity</AlloyRow>
      <AlloyRow gutter={16} justify="space-between" style={{ margin: 0 }}>
        <AlloyCol span={12} style={{ paddingLeft: '0' }}>
          <AlloyFormField
            component="inputNumber"
            name="currentPalletCapacity"
            fieldProps={{
              title: 'Current Pallet Capacity',
              min: 0,
              parser: parseIntValue
            }}
          />
          <AlloyFormField
            component="inputNumber"
            name="monthlyHoldingCostPerPalletPosition"
            fieldProps={{
              title: 'Monthly Holding Cost Per Pallet Position (US$)',
              min: 0
            }}
          />
          <AlloyFormField
            component="inputNumber"
            name="operatingCostPerHour"
            fieldProps={{
              title: 'Operating Cost Per Hour (US$)',
              min: 0
            }}
          />
        </AlloyCol>
        <AlloyCol span={12} style={{ paddingRight: '0' }}>
          <AlloyFormField
            component="inputNumber"
            name="maxPalletCapacity"
            fieldProps={{
              title: 'Max Pallet Capacity',
              min: 0,
              parser: parseIntValue
            }}
          />
          <AlloyFormField
            component="inputNumber"
            name="monthlyLeaseCost"
            fieldProps={{
              title: 'Monthly Lease Cost (US$)',
              min: 0
            }}
          />
          <AlloyFormField
            component="inputNumber"
            name="operatingCostPerCase"
            fieldProps={{
              title: 'Operating Cost Per Case (US$)',
              min: 0
            }}
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyCollapse
        bordered={false}
        className={s.collapse}
        activeKey={[form.getFieldState('useWmsConfig')?.value ? '1' : '']}
        items={[
          {
            key: '1',
            label: (
              <div className={s.wms_config}>
                WMS config
                <AlloyFormField
                  component="switch"
                  name="useWmsConfig"
                  fieldProps={{
                    title: ''
                  }}
                  data-testid="edit-dc-wmsConfig-switch"
                />
              </div>
            ),
            children: form.getFieldState('useWmsConfig')?.value ? (
              <AlloyRow gutter={16} style={{ margin: 0 }}>
                <AlloyCol span={24} style={{ paddingLeft: '0', paddingRight: '0' }}>
                  <AlloyFormField
                    component="input"
                    fieldProps={{
                      title: 'External ID'
                    }}
                    name="wmsConfigExternalId"
                    data-testid="edit-dc-wmsConfig-externalId"
                    required
                  />
                </AlloyCol>
              </AlloyRow>
            ) : (
              <div className={s.not_active}>Not active</div>
            )
          }
        ]}
      />
      <AlloyRow className={s.title_row}>Additional settings</AlloyRow>
      <AlloyRow gutter={16} style={{ margin: 0 }}>
        <AlloyCol span={12} style={{ paddingLeft: '0' }}>
          <AlloyFormField
            component="input"
            name="dummySapSoldTo"
            fieldProps={{ title: 'Dummy SAP Sold To' }}
          />
          <AlloyFormField
            component="input"
            name="transaverRef"
            fieldProps={{ title: 'Transaver Reference' }}
          />
        </AlloyCol>
        <AlloyCol span={12} style={{ paddingRight: '0' }}>
          <AlloyFormField
            component="select"
            name="inventorySource"
            fieldProps={{
              title: 'Inventory Source',
              loading: inventorySourceValuesLoading,
              options: (inventorySourceValues || []).map((source) => ({
                value: source,
                label: source
              }))
            }}
          />
        </AlloyCol>
      </AlloyRow>
      <FieldArray name="blackouts">
        {({ fields }) => (
          <>
            <AlloyRow className={s.title_row}>
              Blackouts
              <AlloyButton
                shape="circle"
                type="text"
                onClick={() => {
                  fields.push({ date: null, dayOfWeek: null });
                }}
              >
                <PlusOutlined />
              </AlloyButton>
            </AlloyRow>
            <AlloyRow style={{ margin: 0 }}>
              {fields.map((name, index) => (
                <div key={index} className={s.blackout_item}>
                  <AlloyFormField
                    component="datepicker"
                    name={`${name}.date`}
                    fieldProps={{
                      title: 'Date',
                      disabled: fields.value[index].dayOfWeek
                    }}
                  />
                  <p> / </p>
                  <AlloyFormField
                    component="select"
                    name={`${name}.dayOfWeek`}
                    fieldProps={{
                      title: 'Day Of Week',
                      disabled: fields.value[index].date,
                      options: moment.weekdays().map((d) => ({
                        value: d.toUpperCase(),
                        label: d.toUpperCase()
                      })),
                      filterSort: undefined
                    }}
                  />
                  <AlloyButton
                    shape="circle"
                    type="text"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <CloseOutlined />
                  </AlloyButton>
                </div>
              ))}
            </AlloyRow>
          </>
        )}
      </FieldArray>
    </div>
  );
};
