import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import React, { useMemo } from 'react';
import s from './WarehouseOrderDetailsPage.module.scss';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { AlloyDivider } from 'components/ui/AlloyDivider/AlloyDivider';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  WhseOpenOrderDetailsDocument,
  WhseOpenOrderDetailsQuery
} from './gql/__generated__/whseOpenOrderDetails.query';
import { dateFormat } from 'common/helpers/date';
import backButtonIcon from 'assets/icons/back_button_icon.svg';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import {
  formatStatus,
  MATERIAL_VIEW_URL_STATE_KEY
} from 'pages/WarehouseOrderProcessingPage/helpers';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { notEmpty } from 'common/helpers/notEmpty';
import { getTempItem } from 'common/services/temporaryStorageServices';

interface MatchParams {
  id: string;
}

export type WarehouseOrderDetails = NonNullable<WhseOpenOrderDetailsQuery['whseOpenOrderDetails']>;

export type WarehouseOrderDetailsMaterials = NonNullable<
  NonNullable<WarehouseOrderDetails['materials']>[number]
>;

const getRowKey = (record: WarehouseOrderDetailsMaterials) => record.materialId;

const TABLE_WIDTH = 1590;

export const WarehouseOrderDetailsPage = (props: RouteComponentProps<MatchParams>) => {
  const materialOrderNumber = props.match.params.id;

  const { loading, error, data } = useQuery(WhseOpenOrderDetailsDocument, {
    variables: { orderNumber: materialOrderNumber }
  });

  const warehouseOrderDetails = useMemo(() => data?.whseOpenOrderDetails, [data]);

  const warehouseOrderDetailsMaterials = useMemo(
    () => data?.whseOpenOrderDetails?.materials,
    [data]
  );

  const warehouseOrderDetailsMaterialsColumns: ColumnsType<WarehouseOrderDetailsMaterials> = [
    {
      title: 'Material ID',
      key: 'MATERIAL_ID',
      render: (_, record) => record.materialId,
      width: 175
    },
    {
      title: 'Description',
      key: 'DESCRIPTION',
      render: (_, record) => record.description
    },
    {
      title: 'Status',
      key: 'STATUS',
      render: (_, record) => formatStatus(record.status),
      width: 175
    },
    {
      title: 'Units Ordered',
      key: 'UNITS_ORDERED',
      align: 'right',
      width: 175,
      render: (_, record) => <span className={s.number_value}>{record.unitsOrdered}</span>
    },
    {
      title: 'Inventory',
      key: 'INVENTORY',
      align: 'right',
      width: 175,
      render: (_, record) => <span className={s.number_value}>{record.inventory}</span>
    },
    {
      title: 'Total Weight (lbs)',
      key: 'TOTAL_WEIGHT',
      align: 'right',
      width: 175,
      render: (_, record) => <span className={s.number_value}>{record.totalWeight}</span>
    }
  ];

  const materialViewURLState = getTempItem(MATERIAL_VIEW_URL_STATE_KEY);

  const backLink = materialViewURLState
    ? `/warehouse-processing${materialViewURLState}`
    : '/warehouse-processing?cut_column=SHIP_TO&cut_order=DESC&limit=200&material_column=MATERIAL_ID&material_order=DESC&mode=materials&order_column=SHIP_TO&order_order=DESC';

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <AlloySpin spinning={loading}>
        <AlloyRow
          data-testid="warehouse_order-details-header"
          className={s.warehouse_order_details_header}
        >
          <div data-testid="page-title" className={s.page_title}>
            <Link<{ materialOrderNumber?: string }>
              data-testid="warehouse-order-details-link"
              to={backLink}
            >
              <img data-testid="back-button-icon" src={backButtonIcon} alt="" />
            </Link>

            <PageHeader className={s.page_title}>
              <AlloySpin spinning={loading}>
                {`Order: ${warehouseOrderDetails?.orderNumber}`}
              </AlloySpin>
            </PageHeader>
          </div>
        </AlloyRow>
        <AlloyDivider />
        <AlloyRow data-testid="warehouse-order-details-info">
          <div className={s.warehouse_order_details_info_container}>
            <div className={s.info_item}>
              <div className={s.info_item_title}>Customer PO</div>
              <div className={s.info_item_data}>{warehouseOrderDetails?.customerPo}</div>
            </div>

            <div className={s.divider} />

            <div className={s.info_item}>
              <div className={s.info_item_title}>Ship To</div>
              <div className={s.info_item_data}>{warehouseOrderDetails?.shipTo}</div>
            </div>

            <div className={s.divider} />

            <div className={s.info_item}>
              <div className={s.info_item_title}>Ordered Date</div>
              <div className={s.info_item_data}>
                {dateFormat(warehouseOrderDetails?.orderedDate)}
              </div>
            </div>

            <div className={s.divider} />

            <div className={s.info_item}>
              <div className={s.info_item_title}>Suggested Date</div>
              <div className={s.info_item_data}>
                {dateFormat(warehouseOrderDetails?.suggestedRdd)}
              </div>
            </div>

            <div className={s.divider} />

            <div className={s.info_item}>
              <div className={s.info_item_title}>Total Units</div>
              <div className={s.info_item_data}>
                {warehouseOrderDetails?.totalUnits?.toLocaleString()}
              </div>
            </div>

            <div className={s.divider} />

            <div className={s.info_item}>
              <div className={s.info_item_title}>Total Weights</div>
              <div className={s.info_item_data}>
                {warehouseOrderDetails?.totalWeights?.toLocaleString()}
              </div>
            </div>
          </div>
        </AlloyRow>

        <AlloyTable
          data-testid="warehouse-order-details-materials-table"
          className={s.warehouse_order_materials_table}
          loading={loading}
          scroll={{ x: TABLE_WIDTH }}
          sticky
          pagination={false}
          columns={warehouseOrderDetailsMaterialsColumns.filter(notEmpty)}
          dataSource={warehouseOrderDetailsMaterials?.filter(notEmpty)}
          rowKey={getRowKey}
        />
      </AlloySpin>
    </>
  );
};
