import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { throttle } from 'lodash-es';
import s from './LastUpdated.module.scss';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import CheckmarkIcon from 'assets/icons/inventory/checkmark.svg?react';
import WarningIcon from 'assets/icons/inventory/warning.svg?react';
import { NOT_AVAILABLE } from 'common/constants';
import { ChargebacksLastUpdatedDocument } from './gql/__generated__/chargebacksLastUpdated.query';
import { FiscalCalendarWeekInputWithWeekOnly } from 'components/PeriodCalendar/helpers';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

const DATE_FORMAT_STRING = 'M/D/YY'; // Since received date is date only, we don't want time.
const MINIMUM_REFETCH_TIME = 30_000;

const FreshStatusIcon = ({ isFresh }: { isFresh: boolean }) => {
  return isFresh ? <CheckmarkIcon /> : <WarningIcon />;
};

export const LastUpdated = ({
  fiscalCalendarWeeks
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
}) => {
  const [isOpen, setOpen] = useState(false);
  const { data, loading, error, refetch } = useQuery(ChargebacksLastUpdatedDocument, {
    variables: { filters: { fiscalCalendarWeeks, countryCode: 'US' } }
  });

  // Refetch no more often than 1 time in MINIMUM_REFETCH_TIME.
  const throttledRefetch = useMemo(
    () => throttle(refetch, MINIMUM_REFETCH_TIME, { leading: true, trailing: false }),
    [refetch]
  );

  const onOpenChange = (open: boolean) => {
    if (open) {
      throttledRefetch();
    }
    setOpen(open);
  };

  const lastUpdatedDate = useMemo(
    () => data?.chargebackDetailsRawData?.lastUpdatedDate,
    [data?.chargebackDetailsRawData?.lastUpdatedDate]
  );

  // Data is updated at 10AM ET Each Monday
  // So it is considered fresh if it was updated after this time
  // TODO: this should be calculated at BE
  const isChargebacksDataFresh = useMemo(() => {
    if (!lastUpdatedDate) return false;
    // Get the current date and time in the user's local timezone
    const userNow = dayjs();

    const EASTERN_TIMEZONE = 'America/New_York';

    // Get this week's Monday at 10 AM ET
    const thisMonday10AMET = dayjs()
      .tz(EASTERN_TIMEZONE)
      .day(1)
      .set('hour', 10)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);

    // Determine the appropriate threshold for freshness
    let freshnessThreshold;

    if (userNow.isBefore(thisMonday10AMET)) {
      // If the local current time is before this Monday at 10 AM ET, use last week's Monday
      freshnessThreshold = thisMonday10AMET.subtract(1, 'week').startOf('day');
    } else {
      // If the local current time is after or equal to this Monday at 10 AM ET, use this week's Monday
      freshnessThreshold = thisMonday10AMET.startOf('day');
    }

    // Parse the lastUpdated date (assuming format YYYY-MM-DD)
    // We also set it as an end of day, so it's considered fresh at Monday
    const lastUpdated = dayjs(lastUpdatedDate).endOf('day');

    // Check if the lastUpdated date is on or after the freshness threshold
    return lastUpdated.isSameOrAfter(freshnessThreshold);
  }, [lastUpdatedDate]);

  if (error) return <></>;

  return (
    <AlloyTooltip
      placement="bottomLeft"
      overlayClassName={s.overlay}
      open={isOpen}
      onOpenChange={(open) => onOpenChange(open)}
      overlay={
        <>
          {loading
            ? 'Loading...'
            : lastUpdatedDate && (
                <div className={s.overlay_wrapper}>
                  <div className={s.updated_string}>
                    Last updated at{' '}
                    {lastUpdatedDate
                      ? dayjs(lastUpdatedDate).format(DATE_FORMAT_STRING)
                      : NOT_AVAILABLE}
                    <FreshStatusIcon isFresh={isChargebacksDataFresh} />
                  </div>
                </div>
              )}
        </>
      }
    >
      <span className={s.last_update_date} data-testid="chargebacks-last-updated">
        {loading ? (
          'Loading...'
        ) : (
          <>
            <span>
              {isChargebacksDataFresh ? 'All sources up to date' : 'Delay in some sources'}
            </span>
            <FreshStatusIcon isFresh={isChargebacksDataFresh} />
          </>
        )}
      </span>
    </AlloyTooltip>
  );
};
