import React from 'react';
import { Collapse as AntCollapse, CollapseProps } from 'ant5';
import s from './AlloyCollapse.module.scss';
import clsx from 'clsx';

export function AlloyCollapse({ className, ...otherProps }: CollapseProps) {
  return <AntCollapse className={clsx(s.alloy_collapse, className)} {...otherProps} />;
}

export type { CollapseProps };
