import {
  CellObject,
  WorkSheet,
  utils as XLSXutils,
  writeFileXLSX
} from 'non_npm_dependencies/xlsx';

export type ExportItem = {
  [name: string]: string | number | boolean | string[] | undefined | null;
};
export type ExportArray = (string | number | boolean | string[] | undefined | null | CellObject)[];

export interface Tab {
  tabName: string;
  itemsToExport: (ExportItem | ExportArray)[];
  headers?: {
    name: string;
    width?: number;
  }[];
}

export interface Options {
  autoSetColumnWidth: boolean;
}

export const prepareWorkbook = (
  tabs: Tab[],
  options: Options = {
    autoSetColumnWidth: false
  }
) => {
  const workbook = XLSXutils.book_new();
  tabs.forEach((tab) => {
    let worksheet: WorkSheet;

    if (
      tab.itemsToExport.length > 0 &&
      typeof tab.itemsToExport[0] === 'object' &&
      !Array.isArray(tab.itemsToExport[0])
    ) {
      // Case of array of objects
      worksheet = XLSXutils.json_to_sheet(tab.itemsToExport as ExportItem[], {
        header: tab.headers?.map((header) => header.name)
      });
    } else {
      // Case of array of arrays
      worksheet = XLSXutils.aoa_to_sheet(tab.itemsToExport as ExportArray[]);
    }

    if (options.autoSetColumnWidth) {
      autoSetColumnWidths(worksheet);
    }

    if (tab.headers) {
      worksheet['!cols'] = tab.headers.map((header) => ({ wpx: header.width }));
    }

    XLSXutils.book_append_sheet(workbook, worksheet, tab.tabName);
  });
  return workbook;
};

export const exportDataToXlsx = (
  fileName: string,
  tabs: Tab[],
  options: Options = {
    autoSetColumnWidth: false
  }
) => {
  const workbook = prepareWorkbook(tabs, options);
  writeFileXLSX(workbook, fileName, { compression: true });
};

function autoSetColumnWidths(worksheet: WorkSheet) {
  const columnWidths: number[] = [];
  const json: Record<string, string>[] = XLSXutils.sheet_to_json(worksheet);

  if (json[0]) {
    Object.entries(json[0]).forEach(([title, value], index) => {
      const titleLength = (title || '').length;
      const valueLength = (value || '').length;

      const maxLength = Math.max(titleLength, valueLength);

      // We can go through all the rows but now we use only 1st one
      columnWidths[index] = Math.max(columnWidths[index] || 0, maxLength);
    });
  }

  worksheet['!cols'] = columnWidths.map((width) => ({ width: width + 2 }));

  return worksheet;
}
