import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  ArrayParam,
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault
} from 'use-query-params';
import s from './WarehouseOrderProcessingPage.module.scss';
import { App, Empty } from 'ant5';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { AlloyAlert } from 'components/ui/AlloyAlert/AlloyAlert';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyUpload, UploadChangeParam } from 'components/ui/AlloyUpload/AlloyUpload';
import { LastUpdated } from './components/LastUpdated/LastUpdated';
import CancelIcon from 'assets/icons/common/cross.svg';
import UploadIcon from 'assets/icons/inventoryOptimization/upload_icon.svg';
import { UploadFile } from 'ant5/es/upload/interface';
import { ValidateOrdersSpreadsheetDocument } from './gql/__generated__/validateOrdersSpreadsheet.mutation';
import { ValidateInventorySpreadsheetDocument } from './gql/__generated__/validateInventorySpreadsheet.mutation';
import {
  WhseOpenOrdersProcessedDocument,
  WhseOpenOrdersProcessedQuery
} from './gql/__generated__/whseOpenOrdersProcessed.query';
import {
  OrderSortColumn,
  SortOrderDirection,
  ValidateOrdersSpreadsheetPayload,
  WarehouseOrderStatus
} from 'graphql/__generated__/types';
import { SaveOrdersSpreadsheetDocument } from './gql/__generated__/saveOrdersSpreadsheet.mutation';
import { SaveInventorySpreadsheetDocument } from './gql/__generated__/saveInventorySpreadsheet.mutation';
import { ProcessOrdersAndInventoryDocument } from './gql/__generated__/processOrdersAndInventory.mutation';
import { notEmpty } from 'common/helpers/notEmpty';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { dateFormat } from 'common/helpers/date';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { Paginator } from 'components/Paginator/Paginator';
import { DEFAULT_PAGE_SIZE_OPTIONS, EMPTY } from 'common/constants';
import { getColumnSortOrder } from 'common/helpers/sorting';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import { CutsView } from './components/CutsView/CutsView';
import { MoveToTruckBuildDocument } from './gql/__generated__/moveToTruckBuild.mutation';
import { useHistory } from 'react-router-dom';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { MaterialsView } from './components/MaterialsView/MaterialsView';
import { formatStatus, validateWOPSearchTerms } from './helpers';
import { Link } from 'react-router-dom';
import ShipDateRDDDrawer, {
  ShipDateRDDFormValues
} from './components/ShipDateRDDDrawer/ShipDateRDDDrawer';
import { EditWarehouseOpenOrdersDocument } from './gql/__generated__/editWarehouseOpenOrders.mutation';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { TableFilters } from 'components/TableFilters';
import { Filter } from 'components/TableFilters/TableFilters';
import { OpenOrdersDashboardInfoDocument } from './gql/__generated__/openOrdersDashboardInfo.query';

export type DisplayMode = 'orders' | 'materials' | 'cuts';
const DEFAULT_TAB: DisplayMode = 'orders';

type WarehouseOrderProcessingTab = {
  value: DisplayMode;
  label: React.ReactNode;
  disabled?: boolean;
};
const ORDERS_TAB: WarehouseOrderProcessingTab = {
  value: 'orders',
  label: 'Orders',
  disabled: false
};
const MATERIALS_TAB: WarehouseOrderProcessingTab = {
  value: 'materials',
  label: 'Materials',
  disabled: false
};
const CUTS_TAB: WarehouseOrderProcessingTab = {
  value: 'cuts',
  label: 'Cuts',
  disabled: false
};
const tabs = [ORDERS_TAB, MATERIALS_TAB, CUTS_TAB];

type WhseOpenOrdersProcessed = InferNodeType<
  WhseOpenOrdersProcessedQuery,
  'whseOpenOrdersProcessed'
>;

type Materials = NonNullable<NonNullable<WhseOpenOrdersProcessed['materials']>[number]>;

const ORDERS_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[3];

const getRowKey = (record: WhseOpenOrdersProcessed) => record.orderNumber;
const getExpandedRowKey = (material: Materials) => material.materialId;

const TABLE_WIDTH = 1590;
const MATERIAL_SEARCH = 'search';
const FILTERS_QUERY_PARAM_STRING = 'filters';

export const WarehouseOrderProcessingPage = () => {
  const { message } = App.useApp();
  const history = useHistory();

  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));
  const modeWithType = mode as DisplayMode;

  const [selectedRows, setSelectedRows] = useState<WhseOpenOrdersProcessed[]>([]);
  const [isDateDrawerOpen, setIsDateDrawerOpen] = React.useState(false);

  const OVERRIDE_PAGE_SIZE_OPTIONS = [10, ...DEFAULT_PAGE_SIZE_OPTIONS];
  const [overrideTablePageSize, setOverrideTablePageSize] = useState(OVERRIDE_PAGE_SIZE_OPTIONS[0]);

  const [pageSize, setPageSize] = useQueryParam(
    'limit',
    withDefault(NumberParam, ORDERS_DEFAULT_PAGE_SIZE)
  );
  const [sortColumn, setSortColumn] = useQueryParam('order_column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('order_order', StringParam);
  const [searchTerms, setSearchTerms] = useQueryParam('search', withDefault(ArrayParam, []));
  const nonEmptySearch = searchTerms.filter(notEmpty) as string[];
  const [tableFiltersFromQuery] = useQueryParam<{
    statuses: WarehouseOrderStatus[];
    shipTos: string[];
  }>(FILTERS_QUERY_PARAM_STRING, withDefault(JsonParam, {}));
  const [after, setAfter] = useQueryParam('order_after', StringParam);
  const [before, setBefore] = useQueryParam('order_before', StringParam);

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
  const [ordersFile, setOrdersFile] = useState<UploadFile>();
  const [ordersFileUploadStatus, setOrdersFileUploadStatus] = useState<string | undefined>();
  const [inventoryFile, setInventoryFile] = useState<UploadFile>();
  const [inventoryFileUploadStatus, setInventoryFileUploadStatus] = useState<string | undefined>(
    ''
  );
  const [ordersForOverrides, setOrdersForOverrides] = useState<
    ValidateOrdersSpreadsheetPayload['duplicates']
  >([]);
  const [selectedOrdersForOverride, setSelectedOrdersForOverride] = useState<string[]>([]);

  const onModeChange = (value: DisplayMode) => {
    setSearchTerms([]);
    setMode(value);
  };

  const { data: dashboardInfoData } = useQuery(OpenOrdersDashboardInfoDocument);

  // STEP 2: After user uploads the SAP orders excel file, FE calls the validateOrdersSpreadsheet query
  const [validateOrdersFileData] = useMutation(ValidateOrdersSpreadsheetDocument, {});

  // Step 1: After user uploads the inventory excel file, FE calls the validateInventorySpreadsheet query
  const [validateInventoryFileData] = useMutation(ValidateInventorySpreadsheetDocument, {});

  // STEP 4: Save orders w/without overrides
  const [saveValidatedOrdersFile] = useMutation(SaveOrdersSpreadsheetDocument, {});

  // STEP 3: Save inventory and then execute sendValidatedOrdersFile function
  const [saveValidatedInventoryFile] = useMutation(SaveInventorySpreadsheetDocument, {});

  // STEP 5: process the uploaded orders and inventory before displaying them in the table
  const [processOrderAndInventoryFiles, { loading: processOrdersAndInventoryLoading }] =
    useMutation(ProcessOrdersAndInventoryDocument, {});

  // On page load/render, call the whseOpenOrdersProcessed query

  const { data, loading, error, refetch } = useQuery(WhseOpenOrdersProcessedDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as OrderSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      after,
      before,
      searchTerms: nonEmptySearch,
      filter: tableFiltersFromQuery
    },
    skip: modeWithType !== 'orders',
    onCompleted: () => {
      const validationMessage = validateWOPSearchTerms(nonEmptySearch);
      if (validationMessage) {
        message.warning(validationMessage);
      }
    }
  });

  const openOrdersList = useMemo(() => getNodesFromEdges(data?.whseOpenOrdersProcessed), [data]);

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(ORDERS_DEFAULT_PAGE_SIZE);
      setSortColumn('SHIP_TO');
      setSortOrder('DESC');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  // STEP 2: User uploads the SAP orders excel file, FE calls the validateOrdersSpreadsheet query
  const ordersFileValidation = async (file: UploadFile) => {
    await validateOrdersFileData({
      // Call the query and input the file the user uploaded
      variables: {
        input: {
          data: file.originFileObj as File
        }
      },
      onCompleted: ({ validateOrdersSpreadsheet }) => {
        if (validateOrdersSpreadsheet && validateOrdersSpreadsheet.duplicates.length > 0) {
          setOrdersForOverrides(validateOrdersSpreadsheet.duplicates.filter(notEmpty));
          setOrdersFile(file);
          setOrdersFileUploadStatus('overwrite');
        } else if (validateOrdersSpreadsheet && validateOrdersSpreadsheet?.validOrders > 0) {
          setOrdersFileUploadStatus('done');
          setOrdersFile(file);
        } else {
          setOrdersFile(file);
          message.error(validateOrdersSpreadsheet?.message, 5); //This shows the user the issue with uploading an incorrect file
        }
      },
      onError: (error) => {
        message.error(`Could not validate orders spreadsheet: ${error.message}`);
        console.log(error.message);
      }
    });
  };

  // Step 1: User uploads the inventory excel file, FE calls the validateInventorySpreadsheet query
  const inventoryFileValidation = async (file: UploadFile<any>) => {
    await validateInventoryFileData({
      variables: {
        input: {
          data: file.originFileObj as File
        }
      },
      onCompleted: ({ validateInventorySpreadsheet }) => {
        if (validateInventorySpreadsheet && validateInventorySpreadsheet?.inventoryValidated > 0) {
          setInventoryFileUploadStatus('done');
          setInventoryFile(file);
        } else {
          setInventoryFile(file);
          message.error(validateInventorySpreadsheet?.message, 5); //This shows the user the issue with uploading an incorrect file
        }
      },
      onError: (error) => {
        message.error(`Could not validate inventory spreadsheet: ${error.message}`);
        console.log(error.message);
      }
    });
  };

  // STEP 4: Save orders w/without overrides
  const sendValidatedOrdersFile = async () => {
    await saveValidatedOrdersFile({
      variables: {
        input: {
          accepted: selectedOrdersForOverride,
          rejected: ordersForOverrides
            .filter(notEmpty)
            .filter((order) => !selectedOrdersForOverride.includes(order)),
          data: ordersFile?.originFileObj as File
        }
      },
      onCompleted: () => {
        setUploadFileModalVisible(false);
        processOrdersAndInventoryAfterFileUploads();
      },
      onError: (error) => {
        message.error(`Could not save validated orders: ${error.message}`);
        console.log(error.message);
      }
    });
  };

  // STEP 3: Save inventory and then execute sendValidatedOrdersFile function
  const sendValidatedInventoryFile = async () => {
    await saveValidatedInventoryFile({
      variables: {
        input: {
          data: inventoryFile?.originFileObj as File
        }
      },
      onCompleted: () => {
        sendValidatedOrdersFile();
      },
      onError: (error) => {
        message.error(`Could not save validated inventory: ${error.message}`);
        console.log(error.message);
      }
    });
  };

  //Step 5: process the uploaded orders and inventory before displaying them in the table
  const processOrdersAndInventoryAfterFileUploads = async () => {
    await processOrderAndInventoryFiles({
      onCompleted: () => {
        message.success(`Inventory and Orders processed successfully!`, 5);
        refetch();
      },
      onError: (error) => {
        message.error(`Could not process inventory and orders successfully: ${error.message}`);
        console.log(error.message);
      }
    });
  };

  //TODO: replace this any type with a more explicit type
  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const uploadOrdersProps = {
    accept: '.csv, .xls, .xlsx, .XLSX, .CSV, .XLS',
    name: 'file',
    customRequest: dummyRequest,
    onChange: ({ file }: UploadChangeParam) => {
      if (file.status === 'done') {
        setOrdersFileUploadStatus('loading');
        ordersFileValidation(file);
      }
    }
  };

  const uploadInventoryProps = {
    accept: '.csv, .xls, .xlsx, .XLSX, .CSV, .XLS',
    name: 'file',
    customRequest: dummyRequest,
    onChange: ({ file }: UploadChangeParam) => {
      if (file.status === 'done') {
        setInventoryFileUploadStatus('loading');
        inventoryFileValidation(file);
      }
    }
  };

  const resetOrdersFileUpload = () => {
    setOrdersFile(undefined);
    setOrdersFileUploadStatus(undefined);
  };

  const resetInventoryFileUpload = () => {
    setInventoryFile(undefined);
    setInventoryFileUploadStatus(undefined);
  };

  const clearModalWindowState = () => {
    resetInventoryFileUpload();
    resetOrdersFileUpload();
    setOrdersForOverrides([]);
    setSelectedOrdersForOverride([]);
  };

  const sendUploadedFiles = () => {
    sendValidatedInventoryFile();
  };

  const EmptyTableText = (
    <>
      <div data-testid="orders-table-empty-state-title" className={s.table_empty_state_title}>
        No items found
      </div>
      <div data-testid="orders-table-empty-state-message">Upload orders and inventory files</div>
    </>
  );

  //TODO: do we need this as a separate component? Maybe not necessary
  const UploadButton = (
    <AlloyButton
      data-testid="orders-table-empty-state-upload-files-button"
      onClick={() => setUploadFileModalVisible(true)}
    >
      Upload
    </AlloyButton>
  );

  const UploadFilesFooter = (
    <div className={s.modal_footer}>
      <div>
        <AlloyButton
          data-testid="cancel-upload-files-button"
          onClick={() => setUploadFileModalVisible(false)}
        >
          Cancel
        </AlloyButton>
      </div>
      {ordersFileUploadStatus === 'overwrite' ? (
        <div>
          <AlloyButton
            data-testid="overwrite-orders-submit-button"
            type={'primary'}
            onClick={() => sendUploadedFiles()}
          >
            Overwrite {selectedOrdersForOverride ? selectedOrdersForOverride.length : null} orders
          </AlloyButton>
        </div>
      ) : (
        <div>
          <AlloyButton
            data-testid="upload-files-button"
            type={'primary'}
            disabled={inventoryFileUploadStatus !== 'done' || ordersFileUploadStatus !== 'done'}
            onClick={() => sendUploadedFiles()}
          >
            Upload
          </AlloyButton>
        </div>
      )}
    </div>
  );

  const nextPage = async () => {
    setAfter(data?.whseOpenOrdersProcessed?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.whseOpenOrdersProcessed?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const ordersTableColumns: ColumnsType<WhseOpenOrdersProcessed> = [
    AlloyTable.SELECTION_COLUMN,
    {
      title: <span data-testid="open-order-order-number-column-title">{'Order Number'}</span>,
      key: 'ORDER_NUMBER',
      width: '175px',
      render: (_, item) => item.orderNumber,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'ORDER_NUMBER'),
      sorter: true
    },
    {
      title: <span data-testid="open-order-line-items-column-title">{'Line Items'}</span>,
      key: 'LINE_ITEMS',
      width: '88px',
      render: (_, item) => item.lineItems,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'LINE_ITEMS'),
      sorter: true
    },
    {
      title: <span data-testid="open-order-status-column-title">{'Status'}</span>,
      key: 'STATUS',
      width: '148px',
      render: (_, item) => formatStatus(item.status),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'STATUS'),
      sorter: true
    },
    {
      title: <span data-testid="open-order-order-date-column-title">{'Order Date'}</span>,
      key: 'ORDER_DATE',
      width: '98px',
      render: (_, item) => dateFormat(item.orderDate),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'ORDER_DATE'),
      sorter: true
    },
    {
      title: <span data-testid="open-order-ship-date-column-title">{'Ship Date'}</span>,
      key: 'SHIP_DATE',
      width: '98px',
      render: (_, item) => dateFormat(item.shipDate),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_DATE'),
      sorter: true
    },
    {
      title: <span data-testid="open-order-suggested-rdd-column-title">{'Suggested RDD'}</span>,
      key: 'RDD',
      width: '148px',
      render: (_, item) => dateFormat(item.rdd),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'RDD'),
      sorter: true
    },
    {
      title: <span data-testid="open-order-ship-to-column-title">{'Ship To'}</span>,
      key: 'SHIP_TO',
      render: (_, item) => item.shipTo,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_TO'),
      sorter: true
    },
    {
      title: <span data-testid="open-order-total-units-column-title">{'Total Units'}</span>,
      key: 'TOTAL_UNITS',
      width: '88px',
      align: 'right',
      render: (_, item) => <span className={s.number_value}>{item.totalUnits ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_UNITS'),
      sorter: true
    },
    {
      title: (
        <span data-testid="open-order-total-available-qty-column-title">
          {'Total Available Qty'}
        </span>
      ),
      key: 'TOTAL_AVAILABLE_QTY',
      width: '147px',
      align: 'right',
      render: (_, item) => (
        <span className={s.number_value}>{item.totalAvailableQty ?? EMPTY}</span>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_AVAILABLE_QTY'),
      sorter: true
    },
    {
      title: (
        <span data-testid="open-order-total-discrepancy-column-title">{'Total Discrepancy'}</span>
      ),
      key: 'TOTAL_DISCREPANCY',
      width: '148px',
      align: 'right',
      render: (_, item) => <span className={s.number_value}>{item.totalDiscrepancy ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_DISCREPANCY'),
      sorter: true
    }
  ];

  const overrideColumns: ColumnsType<{ orderNumber: string }> = [
    {
      title: <span data-testid="overwrite-table-order-number-column-title">{'ORDER NUMBER'}</span>,
      key: 'orderNumber',
      dataIndex: 'orderNumber',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber)
    }
  ];

  const overrideTableData = ordersForOverrides.filter(notEmpty).map((duplicate) => {
    return { orderNumber: duplicate };
  });

  const openOrderMaterialColumns: ColumnsType<Materials> = [
    {
      width: '32px'
    },
    {
      title: (
        <span data-testid="open-order-material-material-id-column-title">{'Material ID'}</span>
      ),
      key: 'materialId',
      width: '175px',
      render: (_, item) => {
        if (item.materialId) {
          return (
            <Link
              data-testid="materials-view-single-item-link"
              to={`/warehouse-processing?limit=200&material_column=MATERIAL_ID&material_order=DESC&${MATERIAL_SEARCH}=${item.materialId}&mode=materials&order_column=SHIP_TO&order_order=DESC`}
            >
              {item.materialId}
            </Link>
          );
        }
      }
    },
    {
      width: '118px'
    },
    {
      title: <span data-testid="open-order-material-status-column-title">{'Status'}</span>,
      key: 'status',
      width: '148px',
      render: (_, item) => formatStatus(item.status)
    },
    {
      title: (
        <span data-testid="open-order-material-description-column-title">{'Description'}</span>
      ),
      key: 'description',
      render: (_, item) => item.description
    },
    {
      title: <span data-testid="open-order-material-units-column-title">{'Units'}</span>,
      key: 'units',
      align: 'right',
      width: '148px',
      render: (_, item) => <span className={s.number_value}>{item.units ?? EMPTY}</span>
    },
    {
      title: (
        <span data-testid="open-order-material-available-qty-column-title">{'Available Qty'}</span>
      ),
      key: 'availableQty',
      align: 'right',
      width: '147px',
      render: (_, item) => <span className={s.number_value}>{item.availableQty ?? EMPTY}</span>
    },
    {
      title: (
        <span data-testid="open-order-material-discrepancy-column-title">{'Discrepancy'}</span>
      ),
      key: 'discrepancy',
      align: 'right',
      width: '148px',
      render: (_, item) => <span className={s.number_value}>{item.discrepancy ?? EMPTY}</span>
    }
  ];

  const currentPageOrdersExpandableKeys = useMemo(() => {
    return openOrdersList.map(getRowKey);
  }, [openOrdersList]);

  const areAllKeysExpandedOnThePage = useMemo(
    () => currentPageOrdersExpandableKeys.every((order) => expandedRowKeys.includes(order)),
    [expandedRowKeys, currentPageOrdersExpandableKeys]
  );

  const seeInTruckMessageKey = 'see_in_truck_message';

  const onSeeInTruckClick = () => {
    message.destroy(seeInTruckMessageKey);
    history.push('/warehouse-truck-builder');
  };

  const [moveToTruckMutation, { loading: moveToTruckLoading }] = useMutation(
    MoveToTruckBuildDocument,
    {
      onCompleted: (data) => {
        setSelectedRows([]);
        if (data?.moveToTruckBuild?.message) {
          message.success({
            content: (
              <p data-testid="move-to-truck-build-success-message">
                {data.moveToTruckBuild.message}{' '}
                <AlloyButton
                  data-testid="see-in-truck-button"
                  className={s.see_in_truck_button}
                  onClick={onSeeInTruckClick}
                  type="link"
                >
                  See in Truck Builder
                </AlloyButton>{' '}
              </p>
            ),
            duration: 10,
            key: seeInTruckMessageKey
          });
        }
      },
      onError: (error) => {
        console.error(error.message);
        message.error(
          <span data-testid="move-to-truck-build-error-message">
            {`Error happened during Move To Truck: ${error.message}`}
          </span>
        );
      },
      refetchQueries: [WhseOpenOrdersProcessedDocument]
    }
  );

  const isAllSelectedRowsReady = useMemo(() => {
    return selectedRows.length > 0 && selectedRows.every((row) => row.status === 'READY_TO_SHIP');
  }, [selectedRows]);

  const onMoveToTruckClick = async () => {
    if (!isAllSelectedRowsReady) return;

    await moveToTruckMutation({
      variables: {
        input: {
          orderNumbers: selectedRows.map((row) => row.orderNumber)
        }
      }
    });
  };

  const editSelectOptions = [
    { label: 'Needs review', value: 'Needs review' },
    { label: 'Cancel', value: 'Cancel' },
    { label: 'Ship Date/RDD', value: 'Ship Date/RDD' }
  ];

  const [editWarehouseOpenOrdersMutation, { loading: editWarehouseOpenOrdersLoading }] =
    useMutation(EditWarehouseOpenOrdersDocument, {
      onCompleted: () => {
        setSelectedRows([]);
      },
      refetchQueries: [WhseOpenOrdersProcessedDocument]
    });

  const onEditSelectChange = (value: (typeof editSelectOptions)[number]['value']) => {
    if (selectedRows.length === 0) return;

    switch (value) {
      case 'Needs review':
        return editWarehouseOpenOrdersMutation({
          variables: {
            input: {
              orderNumbers: selectedRows.map((x) => x.orderNumber),
              editOption: 'needs_review'
            }
          }
        });
      case 'Cancel':
        return editWarehouseOpenOrdersMutation({
          variables: {
            input: {
              orderNumbers: selectedRows.map((x) => x.orderNumber),
              editOption: 'cancel'
            }
          }
        });
      case 'Ship Date/RDD':
        setIsDateDrawerOpen(true);
    }
  };

  //TODO: find a more descriptive name for this function
  const onSubmit = async (values: ShipDateRDDFormValues) => {
    await editWarehouseOpenOrdersMutation({
      variables: {
        input: {
          orderNumbers: selectedRows.map((x) => x.orderNumber),
          editOption: 'ship_date_rdd',
          shipDate: values.shipDate?.format('YYYY-MM-DD'),
          suggestedRdd: values.suggestedRdd?.format('YYYY-MM-DD')
        }
      }
    });
    setIsDateDrawerOpen(false);
  };

  //TODO: find a more descriptive name for this function
  const onClose = () => {
    setIsDateDrawerOpen(false);
  };

  const tableFilters: Filter[] = useMemo(
    () => [
      {
        name: 'Status',
        type: 'multiple',
        field: 'statuses',
        options:
          dashboardInfoData?.openOrdersDashboardInfo?.filterOptions?.statusOptions?.map((item) => ({
            name: item || '',
            value: item || ''
          })) || []
      },
      {
        name: 'Ship To',
        short: 'Ship To',
        type: 'multiple',
        field: 'shipTos',
        options:
          dashboardInfoData?.openOrdersDashboardInfo?.filterOptions?.shipToOptions?.map((item) => ({
            name: item || '',
            value: item || ''
          })) || []
      }
    ],
    [dashboardInfoData]
  );

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <AlloySpin
        data-testid="warehouse-order-processing-page-loader-spinner"
        spinning={processOrdersAndInventoryLoading}
      >
        <div className={s.header}>
          <div data-testid="warehouse-order-processing-page-title" className={s.page_title}>
            <PageHeader className={s.page_title}>Warehouse Order Processing</PageHeader>
            <LastUpdated />
          </div>
        </div>

        <div className={s.page_toggle}>
          <AlloySegmented
            data-testid="warehouse-order-processing-tabs"
            options={tabs}
            value={mode as DisplayMode}
            onChange={(value) => onModeChange(value)}
          />
        </div>

        {modeWithType === 'orders' && (
          <>
            <div
              data-testid="open-orders-table-tools-container"
              className={s.table_tools_container}
            >
              <div
                data-testid="open-orders-table-search-and-filters-container"
                className={s.search_and_filters_container}
              >
                <MultipleValuesInput
                  data-testid="open-orders-table-search"
                  placeholder="Search by Material ID or Order Number"
                  value={nonEmptySearch}
                  onChange={setSearchTerms}
                  allowClear={true}
                  prefix={<SearchOutlined width="14px" height="14px" />}
                  splitInputValue={/[^0-9a-zA-Z-,.()&]+/g}
                />
                <TableFilters
                  filters={tableFilters}
                  queryParamName={FILTERS_QUERY_PARAM_STRING}
                  loading={false}
                />
              </div>

              <div
                data-testid="open-orders-table-actions-container"
                className={s.table_actions_container}
              >
                <AlloySelect
                  data-testid="open-orders-edit-button"
                  value="Edit"
                  disabled={selectedRows.length === 0 || editWarehouseOpenOrdersLoading}
                  options={editSelectOptions}
                  onChange={onEditSelectChange}
                  popupMatchSelectWidth={false}
                />
                <ShipDateRDDDrawer
                  open={isDateDrawerOpen}
                  onSubmit={onSubmit}
                  onClose={onClose}
                  orders={selectedRows}
                  pending={editWarehouseOpenOrdersLoading}
                />

                <AlloyTooltip
                  data-testid="move-to-truck-warning-tooltip"
                  title={
                    !isAllSelectedRowsReady
                      ? 'Only Ready to Ship orders could be moved to truck'
                      : ''
                  }
                >
                  <AlloyButton
                    data-testid="move-to-truck-button"
                    loading={moveToTruckLoading}
                    disabled={!isAllSelectedRowsReady}
                    onClick={onMoveToTruckClick}
                    type="primary"
                  >
                    Move to Truck
                  </AlloyButton>
                </AlloyTooltip>
                <AlloyButton
                  data-testid="upload-files-button"
                  type={'primary'}
                  onClick={() => setUploadFileModalVisible(true)}
                >
                  Upload
                </AlloyButton>
              </div>
            </div>

            <div
              data-testid="open-orders-quantity-display-and-paginator-container"
              className={s.quantity_display_and_paginator_container}
            >
              <div>
                <QuantityDisplay
                  data-testid="orders-table-quantity-display"
                  count={
                    selectedRows?.length > 0
                      ? selectedRows.length
                      : data?.whseOpenOrdersProcessed?.totalCount
                  }
                  titleSingular={`${selectedRows?.length > 0 ? 'Selected ' : ''} Order`}
                  titleMultiple={`${selectedRows?.length > 0 ? 'Selected ' : ''} Orders`}
                />
              </div>
              <Paginator
                pageSize={pageSize || ORDERS_DEFAULT_PAGE_SIZE}
                hasNextPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasNextPage}
                hasPreviousPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasPreviousPage}
                handlePageSizeChange={handlePageSizeChange}
                prevPage={prevPage}
                nextPage={nextPage}
                onlyButtons={false}
              />
            </div>

            <AlloyTable
              data-testid="open-orders-table"
              className={s.open_orders_table}
              loading={loading}
              scroll={{ x: TABLE_WIDTH }}
              sticky
              pagination={false}
              columns={ordersTableColumns.filter(notEmpty)}
              dataSource={openOrdersList}
              expandable={{
                columnTitle: currentPageOrdersExpandableKeys.length > 0 && (
                  <ExpandButton
                    expanded={areAllKeysExpandedOnThePage}
                    onClick={(expanded) => {
                      return expanded
                        ? setExpandedRowKeys(
                            expandedRowKeys.filter(
                              (x) => !currentPageOrdersExpandableKeys.includes(x)
                            )
                          )
                        : setExpandedRowKeys([
                            ...new Set([...currentPageOrdersExpandableKeys, ...expandedRowKeys])
                          ]);
                    }}
                    collapseMessage="Collapse all"
                    expandMessage="Expand all"
                  />
                ),
                expandedRowKeys: expandedRowKeys,
                expandedRowRender: ({ materials }) => (
                  <AlloyTable //TODO: table header color change if possible
                    loading={loading}
                    data-testid="open-order-materials-table"
                    className={s.open_order_materials_table}
                    //sticky //TODO: understand if this is needed -- should probably be over a certain number of rows only
                    columns={openOrderMaterialColumns.filter(notEmpty)}
                    dataSource={materials?.map((material) => material).filter(notEmpty)}
                    pagination={false}
                    rowKey={getExpandedRowKey}
                  />
                ),
                onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
                rowExpandable: ({ materials }) => !!materials
              }}
              onChange={(_pagination, _filters, sorter) => {
                const column =
                  (sorter as SorterResult<WhseOpenOrdersProcessed>).columnKey || 'SHIP_TO';

                const order =
                  (sorter as SorterResult<WhseOpenOrdersProcessed>).order === 'ascend' ||
                  !(sorter as SorterResult<WhseOpenOrdersProcessed>).order
                    ? 'ASC'
                    : 'DESC';

                handleTableSorting(column.toString(), order);
              }}
              rowKey={getRowKey}
              rowSelection={{
                preserveSelectedRowKeys: true,
                onChange: (_keys, rows) => {
                  setSelectedRows(rows);
                },
                type: 'checkbox',
                selectedRowKeys: selectedRows?.map((row) => row.orderNumber),
                // @ts-expect-error: CheckboxProps partial type has no properties in common with { data-testid: string } type
                getCheckboxProps(record) {
                  return { 'data-testid': record.orderNumber };
                }
              }}
              locale={{
                emptyText: (
                  <Empty
                    data-testid="open-orders-table-empty-state"
                    className={s.table_empty_state}
                    description={EmptyTableText}
                  >
                    {UploadButton}
                  </Empty>
                )
              }}
            />

            <div data-testid="open-orders-table-bottom-paginator" className={s.bottom_paginator}>
              <Paginator
                pageSize={pageSize || ORDERS_DEFAULT_PAGE_SIZE}
                hasNextPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasNextPage}
                hasPreviousPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasPreviousPage}
                handlePageSizeChange={handlePageSizeChange}
                prevPage={prevPage}
                nextPage={nextPage}
                onlyButtons={true}
              />
            </div>
          </>
        )}

        {modeWithType === 'materials' && <MaterialsView />}

        {modeWithType === 'cuts' && <CutsView />}

        <AlloyModal
          data-testid="upload-orders-and-inventory-modal"
          title={
            <span data-testid="upload-orders-and-inventory-modal-title">
              {'Upload Orders & Inventory'}
            </span>
          }
          className={s.upload_file_modal}
          open={uploadFileModalVisible}
          afterClose={() => clearModalWindowState()}
          onCancel={() => setUploadFileModalVisible(false)}
          destroyOnClose
          classNames={{
            header: s.modal_header,
            body: s.modal_body,
            content: s.modal_content
          }}
          footer={UploadFilesFooter}
        >
          {ordersFileUploadStatus === 'overwrite' ? (
            <div>
              <div className={s.duplicates_alert}>
                <AlloyAlert
                  data-testid="duplicate-orders-warning-message"
                  type={'warning'}
                  message={`${ordersForOverrides.length} duplicates detected`}
                />
              </div>
              <div data-testid="duplicates-description-text" className={s.duplicates_description}>
                Select the orders numbers you would like to overwrite with the new data uploaded.
              </div>
              <div>
                <div>
                  <AlloyTable
                    data-testid="overwrite-orders-table"
                    columns={overrideColumns}
                    dataSource={overrideTableData}
                    rowKey={(record) => record.orderNumber}
                    pagination={{
                      pageSizeOptions: OVERRIDE_PAGE_SIZE_OPTIONS,
                      position: ['bottomRight'],
                      showSizeChanger: true,
                      onChange: (_page, pageSize) => setOverrideTablePageSize(pageSize),
                      pageSize: overrideTablePageSize,
                      size: 'small'
                    }}
                    rowSelection={{
                      type: 'checkbox',
                      onSelectAll: (selected, selectedRows) => {
                        setSelectedOrdersForOverride(selectedRows.map((row) => row.orderNumber));
                      },
                      selectedRowKeys: selectedOrdersForOverride,
                      onChange: (_keys, rows) => {
                        setSelectedOrdersForOverride(rows.map((row) => row.orderNumber));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                data-testid="upload-orders-and-inventory-modal-subtitle"
                className={s.upload_file_modal_description}
              >
                Upload SAP orders and/or Inventory exports
              </div>
              <section className={s.upload_section}>
                <div
                  data-testid="upload-orders-and-inventory-modal-section-title"
                  className={s.upload_section_title}
                >
                  Files to upload
                </div>
                {/* TODO: rename this class */}
                <div className={s.upload_section_element}>
                  <img
                    data-testid="upload-inventory-icon"
                    className={s.upload_section_element_icon} //TODO: rename this class
                    alt="upload icon"
                    src={UploadIcon}
                  />
                  {inventoryFileUploadStatus === 'done' ||
                  inventoryFileUploadStatus === 'loading' ? (
                    <>
                      {/* TODO: rename this class */}
                      <div className={s.upload_section_element_description}>
                        <span data-testid="inventory_file_name">{inventoryFile?.name}</span>
                      </div>
                      {inventoryFileUploadStatus === 'loading' ? (
                        <div data-testid="uploading-inventory-spinner">
                          <AlloySpin /> Uploading
                        </div>
                      ) : null}
                      <div>
                        <img
                          data-testid="cancel-uploading-inventory-icon"
                          alt="cancel icon"
                          className={s.cancel_icon}
                          onClick={() => resetInventoryFileUpload()}
                          src={CancelIcon}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        data-testid="upload-inventory-description-container"
                        className={s.upload_section_element_description}
                      >
                        <span data-testid="upload-inventory-description">{'Upload inventory'}</span>
                        <div data-testid="supported-inventory-files-description">
                          Support .csv, .xls, .xlsx only
                        </div>
                      </div>
                      <AlloyUpload {...uploadInventoryProps}>
                        <AlloyButton data-testid="upload-inventory-button" type={'link'}>
                          Inventory export
                        </AlloyButton>
                      </AlloyUpload>
                    </>
                  )}
                </div>
                {/* TODO: rename this class */}
                <div className={s.upload_section_element}>
                  <img
                    data-testid="upload-sap-orders-icon"
                    className={s.upload_section_element_icon}
                    alt="upload icon"
                    src={UploadIcon}
                  />
                  {ordersFileUploadStatus === 'done' || ordersFileUploadStatus === 'loading' ? (
                    <>
                      {/* TODO: rename this class */}
                      <div className={s.upload_section_element_description}>
                        <span data-testid="sap-orders-file-name">{ordersFile?.name}</span>
                      </div>
                      {ordersFileUploadStatus === 'loading' ? (
                        <div data-testid="uploading-sap-orders-spinner">
                          Uploading <LoadingOutlined />
                        </div>
                      ) : null}
                      <div>
                        <img
                          data-testid="cancel-uploading-sap-orders-icon"
                          className={s.cancel_icon}
                          alt="cancel icon"
                          onClick={() => resetOrdersFileUpload()}
                          src={CancelIcon}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        data-testid="upload-sap-orders-description-container"
                        className={s.upload_section_element_description}
                      >
                        <span data-testid="upload-sap-orders-description">
                          {'Upload SAP orders'}
                        </span>
                        <div data-testid="supported-inventory-files-description">
                          Support .csv, .xls, .xlsx only
                        </div>
                      </div>
                      <AlloyUpload {...uploadOrdersProps}>
                        <AlloyButton
                          data-testid="upload-sap-orders-button"
                          disabled={inventoryFileUploadStatus !== 'done'}
                          type={'link'}
                        >
                          SAP orders
                        </AlloyButton>
                      </AlloyUpload>
                    </>
                  )}
                </div>
              </section>
            </>
          )}
        </AlloyModal>
      </AlloySpin>
    </>
  );
};
