import React, { useState, useEffect, useMemo } from 'react';
import './styles.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import LoaderSpinner from 'components/LoaderSpinner';
import { notEmpty } from 'common/helpers/notEmpty';
import { SplitSalesOrderItemDocument } from './gql/__generated__/splitSalesOrderItem.mutation';
import { UpdateItemsAcceptedUnitsDocument } from './gql/__generated__/updateItemsAcceptedUnits.mutation';
import {
  GetPurchaseOrdersByIdsMultipleUpcEditDocument,
  GetPurchaseOrdersByIdsMultipleUpcEditQuery
} from './gql/__generated__/getPurchaseOrdersByIdsMultipleUpcEdit.query';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { safeNumberComparator, safeLocaleCompare } from 'common/helpers/comparators';
import { PurchaseOrderRefetchQueries } from 'common/constants';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { App } from 'ant5';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import clsx from 'clsx';

type PurchaseOrder = InferNodeType<GetPurchaseOrdersByIdsMultipleUpcEditQuery, 'purchaseOrders'>;
type SalesOrder = PurchaseOrder['salesOrders'][number];
type SalesOrderItem = SalesOrder['salesOrderItems'][number];
type DistributionCenter = NonNullable<SalesOrder['distributionCenter']>;
type PurchaseOrderItem = PurchaseOrder['purchaseOrderItems'][number];

type DistributionCenterItem = Omit<SalesOrderItem, 'salesOrder'> & {
  purchaseOrderItems: PurchaseOrderItemWithSoItem[];
} & { salesOrder: { id: string; distributionCenter?: DistributionCenter | null } };
type DistributionCenterWithItems = Partial<Pick<DistributionCenter, 'code' | 'id' | 'name'>> & {
  items?: Record<string, DistributionCenterItem>;
};

interface PurchaseOrderItemWithSoItem extends PurchaseOrderItem {
  soItemId?: string;
  purchaseOrder?: PurchaseOrder;
}

interface SoItemSplit {
  id: string;
  externalId: string;
  purchaseOrder: PurchaseOrder;
  poItem?: PurchaseOrderItem;
  splits: Split[];
  touched?: boolean;
}

interface Split {
  productId: string;
  salesOrder: SalesOrder;
  soItem?: SalesOrderItem;
  acceptedUnits: number;
  enabled: boolean;
}

interface SalesOrderItemWithPoInfo extends SalesOrderItem {
  purchaseOrder: PurchaseOrder;
  purchaseOrderItem?: PurchaseOrderItemWithSoItem;
  purchaseOrderItems: PurchaseOrderItem[];
  selectedProductId: string;
  originalProductId: string;
  salesOrder: SalesOrder;
}

const { Summary } = AlloyTable;
const { Cell } = Summary;

interface MultipleUpcEditProps {
  purchaseOrderIds: string[];
  onClose?: () => void;
}

export const MultipleUpcEdit = ({ purchaseOrderIds, onClose }: MultipleUpcEditProps) => {
  const { notification } = App.useApp();
  const { data, loading } = useQuery(GetPurchaseOrdersByIdsMultipleUpcEditDocument, {
    variables: { ids: purchaseOrderIds },
    skip: !purchaseOrderIds || !purchaseOrderIds.length
  });

  const poData = useMemo(() => getNodesFromEdges(data?.purchaseOrders), [data?.purchaseOrders]);

  const [splitSoItem] = useMutation(SplitSalesOrderItemDocument, {
    refetchQueries: PurchaseOrderRefetchQueries
  });
  const [updateAcceptedUnits] = useMutation(UpdateItemsAcceptedUnitsDocument);
  const [saving, setSaving] = useState(false);
  const [distributionCenters, setDistributionCenters] = useState<
    Record<string, DistributionCenterWithItems | undefined | null>
  >({});
  const [upcItems, setUpcItems] = useState<SoItemSplit[]>([]);

  const itemCount = Object.values(distributionCenters).reduce((count, dc) => {
    return (count += Object.values(dc?.items || {}).length);
  }, 0);

  const modalTitle = (
    <div className="multiple-upc-title">
      <div className="title">Multiple UPC available</div>
      <div className="subtitle">{itemCount} item(s)</div>
    </div>
  );

  useEffect(() => {
    if (Object.values(distributionCenters || {}).length) {
      setDistributionCenters({});
      return;
    }

    if (!poData) return;

    const soItems: SoItemSplit[] = [];
    poData.forEach((po) => {
      po.salesOrders.forEach((so) => {
        so.salesOrderItems.forEach((saleOrderItem) => {
          const soItem = saleOrderItem as SalesOrderItemWithPoInfo;
          soItem.salesOrder = so;
          const soItemSplit: SoItemSplit = {
            id: soItem.id,
            externalId: soItem.externalId || '',
            purchaseOrder: po,
            splits: []
          };
          soItem.purchaseOrder = po;
          soItem.purchaseOrderItem = po.purchaseOrderItems.find((poItem) => {
            return poItem.externalId === soItem.externalId;
          }) as PurchaseOrderItemWithSoItem;

          soItemSplit.poItem = soItem.purchaseOrderItem;

          if (soItem.purchaseOrderItem) {
            soItem.purchaseOrderItem.purchaseOrder = po;
          }

          soItem.purchaseOrderItems = [];
          if (soItem.purchaseOrderItem) {
            soItem.purchaseOrderItem.soItemId = soItem.id;
            soItem.purchaseOrderItems.push(soItem.purchaseOrderItem);
          }
          soItem.selectedProductId = soItem.purchaseOrderItem?.product?.id || '';
          soItem.originalProductId = soItem.selectedProductId;

          if ((soItem.vendorProduct?.substitutionProducts || []).length > 1) {
            soItem.vendorProduct?.substitutionProducts.forEach((subItem) => {
              const currentSoItem = so.salesOrderItems.find(
                (sItem) => sItem?.product?.id === subItem.product.id
              );
              const acceptedUnits = Number(currentSoItem?.acceptedUnits) || 0;

              soItemSplit.splits.push({
                productId: subItem.product?.id,
                soItem: currentSoItem,
                salesOrder: so,
                acceptedUnits,
                enabled:
                  acceptedUnits > 0 || soItem.purchaseOrderItem?.product?.id === subItem.product.id
              });
            });

            soItems.push(soItemSplit);

            if (
              !distributionCenters[so.distributionCenter?.code || so.distributionCenterCode || '']
            ) {
              distributionCenters[so.distributionCenter?.code || so.distributionCenterCode || ''] =
                so.distributionCenter;
            }
            if (
              !distributionCenters[so.distributionCenter?.code || so.distributionCenterCode || '']
                ?.items
            ) {
              distributionCenters[so.distributionCenter?.code || ''] = {
                ...distributionCenters[so.distributionCenter?.code || ''],
                items: {}
              };
            }
            const items =
              distributionCenters[so.distributionCenter?.code || so.distributionCenterCode || '']
                ?.items || {};

            if (soItem.externalId && !items[soItem.externalId]) {
              items[soItem.externalId] = soItem;

              items[soItem.externalId]?.vendorProduct?.substitutionProducts.sort((a, b) =>
                safeNumberComparator(a.substitutionOrder, b.substitutionOrder)
              );
            } else {
              if (soItem.purchaseOrderItem) {
                const exists = items[soItem.externalId || ''].purchaseOrderItems.find(
                  (poItem) => poItem.id === soItem.purchaseOrderItem?.id
                );
                if (!exists) {
                  soItem.purchaseOrderItem.soItemId = soItem.id;
                  items[soItem.externalId || ''].purchaseOrderItems.push(soItem.purchaseOrderItem);
                }
              }
            }
          }
        });
      });
    });

    setDistributionCenters(distributionCenters);
    setUpcItems(soItems);
  }, [poData, distributionCenters]);

  const cancel = () => {
    if (onClose) onClose();
  };

  const requestSoItemAcceptedUnits = (updates: Split[]) => {
    return new Promise<void>((resolve, reject) => {
      updateAcceptedUnits({
        variables: {
          input: {
            salesOrderItemUpdates: updates.map((product) => ({
              acceptedUnits: product.acceptedUnits,
              id: product.soItem?.id || ''
            }))
          }
        }
      })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.error(error);
          Sentry.addBreadcrumb({
            category: 'request',
            message: `failed to save upc edit changes`
          });
          reject(updates);
        })
        .finally(() => {
          setSaving(false);
        });
    });
  };

  const requestSoItemSplit = async (
    soItem: SoItemSplit
  ): Promise<{ externalId: string; id: string; salesOrder: { id: string } }[]> => {
    try {
      const result = await splitSoItem({
        variables: {
          input: {
            id: soItem.id,
            splits: soItem.splits.map((split) => {
              return {
                productId: split.productId,
                acceptedUnits: split.acceptedUnits
              };
            })
          }
        }
      });
      return (
        result.data?.splitSalesOrderItem?.salesOrderItems.map((x) => ({
          ...x,
          externalId: x.externalId || ''
        })) || []
      );
    } catch {
      console.error(`failed to save sales item split for ${JSON.stringify(soItem)}`);
      throw soItem;
    }
  };

  /*when UPC splits are made 2 mutation are used. When both items in the split currently exist on
  the sales order updateItemsAcceptedUnits can be used. However, because that mutation requires a sales order ID,
  it is necessary to use splitSalesOrderItem when only 1 item in the split exists. This mutation will create the new item on the sales order. */
  const onOk = async () => {
    setSaving(true);

    const failed: SoItemSplit | Split[] = [];
    const requests: Promise<
      | {
          externalId: string;
          id: string;
          salesOrder: {
            id: string;
          };
        }[]
      | void
    >[] = [];
    //filter out any sales order items that have not been updated
    const touched = upcItems.filter((state) => state.touched);

    /*split request must be made independently, but update requests can be made with all updates in a single request
    also updates to SO items on the same SO should all be made together for an update. If not made together a race condition is
    created on the backend. */
    const soUpdatesRequired = touched.reduce((updatesRequired, soItem) => {
      const productOnSalesOrder: { existing: Split[]; needed: Split[] } = {
        existing: [],
        needed: []
      };

      soItem.splits.forEach((split) => {
        if (
          split.salesOrder.salesOrderItems.find((soItem) => soItem?.product?.id === split.productId)
        ) {
          if (split.soItem?.acceptedUnits !== split.acceptedUnits)
            productOnSalesOrder.existing.push(split);
        } else {
          productOnSalesOrder.needed.push(split);
        }
      });

      if (productOnSalesOrder.needed.length) {
        requests.push(requestSoItemSplit(soItem));
        return updatesRequired;
      } else {
        return updatesRequired.concat(productOnSalesOrder.existing);
      }
    }, [] as Split[]);

    if (soUpdatesRequired.length) {
      requests.push(requestSoItemAcceptedUnits(soUpdatesRequired));
    }

    await Promise.allSettled(requests)
      .catch((errors) => {
        failed.concat(errors);
        console.error(errors);
      })
      .finally(() => {
        setSaving(true);
      });

    if (failed.length) {
      notification.error({
        message: 'Failed to save',
        description: `(${failed.length}) of the changes could not be saved. An error has been logged for review`
      });
    } else if (requests.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: `None of the displayed items have been updated yet. Please make any desired changes and try again.`
      });
    } else {
      notification.success({
        message: 'Successfully Updated',
        description: `UPC split successfully updated for all items`
      });
      if (onClose) onClose();
    }
  };

  const footer = () => {
    const disabled = upcItems
      .filter((upcItem) => upcItem.touched)
      .some((upcItem) => {
        const totalQty = upcItem.splits.reduce((total, item) => (total += item.acceptedUnits), 0);
        return upcItem.poItem?.quantityOrdered && totalQty > upcItem.poItem.quantityOrdered;
      });

    return (
      <div className="footer">
        <AlloyButton onClick={cancel}>{upcItems.length ? 'Cancel' : 'Close'}</AlloyButton>

        {upcItems.length > 0 && (
          <AlloyTooltip title={disabled ? 'Please check for any error above' : ''}>
            <AlloyButton
              disabled={disabled}
              onClick={onOk}
              loading={saving}
              type="primary"
              className="multiple_upc_modal_submit_button"
            >
              Submit Split
            </AlloyButton>
          </AlloyTooltip>
        )}
      </div>
    );
  };

  const DCs = Object.values(distributionCenters);

  return (
    <>
      <AlloyModal
        className="multiple-upc-modal"
        title={!loading && upcItems.length ? modalTitle : null}
        onCancel={cancel}
        onOk={onOk}
        confirmLoading={saving}
        footer={!loading ? footer() : null}
        open={true}
        width="900px"
      >
        {loading && (
          <div className="loader">
            <LoaderSpinner />
            <div className="desc">retrieving purchase orders</div>
          </div>
        )}

        {!loading && !upcItems.length && (
          <div className="missing">
            {/* The selected purchase order(s) do not contain products with multiple UPCs */}
            There are no multi-UPC products in this list
          </div>
        )}

        {!loading &&
          DCs.filter(notEmpty).map((dc) => {
            const rowData = Object.values(dc?.items || {}).sort((a, b) =>
              safeLocaleCompare(a?.externalId, b?.externalId)
            );

            return (
              <div className="distribution-center" key={`${dc.id}-dc`}>
                <div className="title">{dc.name}</div>
                {rowData.map((item) => {
                  return (
                    <div key={item.id}>
                      <div className="item-header">
                        <div className="name">{item.product?.name}</div>
                        <div className="external-id">
                          <b>External ID</b>:{item.externalId}
                        </div>
                      </div>
                      <MultipleUpcTable
                        item={item}
                        soSplit={upcItems.find((upcItem) => upcItem.id === item.id)}
                        upcItems={upcItems}
                        onChange={(update: SoItemSplit) => {
                          const index = upcItems.findIndex((upcItem) => upcItem.id === update?.id);
                          upcItems[index] = update;
                          setUpcItems([...upcItems]);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
      </AlloyModal>
    </>
  );
};

interface MultipleUpcTableProps {
  item: DistributionCenterItem;
  soSplit?: SoItemSplit;
  onChange?: (update: SoItemSplit) => void;
  upcItems: SoItemSplit[];
}

const MultipleUpcTable = ({ item, soSplit, onChange, upcItems }: MultipleUpcTableProps) => {
  const columns: ColumnsType<PurchaseOrderItemWithSoItem> = [
    {
      title: 'PO',
      key: 'purchase_order',
      render: (_, poItem) => (
        <div className="sub-row align_left">{poItem?.purchaseOrder?.customerPo}</div>
      )
    },
    {
      title: 'Ordered',
      key: 'ordered_qty',
      align: 'center' as const,
      render: (_, poItem) => {
        const upcItem = upcItems.find((upcItem) => upcItem.id === poItem.soItemId);
        const totalQty =
          upcItem?.splits.reduce((total, split) => (total += split.acceptedUnits), 0) || 0;
        const overTotal = totalQty > (poItem?.quantityOrdered || 0);
        return (
          <div className={`ordered-qty ${overTotal ? 'errored' : ''}`}>
            <div className="qty">
              <span>
                {poItem.quantityOrdered} {poItem.submittedUnitOfMeasure}
              </span>
              {overTotal && (
                <AlloyTooltip
                  title={`The total for Custom Qty (${totalQty}) should not exceed the ordered Qty (${poItem.quantityOrdered})`}
                >
                  <QuestionCircleOutlined className="unknown" />
                </AlloyTooltip>
              )}
            </div>
          </div>
        );
      }
    },
    {
      title: 'UPC',
      key: 'upc',
      className: 'empty-cell',
      render: (_, poItem) => {
        const state = upcItems.find((upcItem) => upcItem.id === poItem.soItemId);
        return (
          <div>
            {state?.splits?.map((subProd) => {
              const targetSoItem = poItem.products.find(
                (product) => product.id === subProd.productId
              );
              const onPo = poItem?.product?.id === subProd.productId;
              return (
                <div
                  className="sub-row align_left"
                  key={`${targetSoItem?.id}-${targetSoItem?.upc}`}
                >
                  <span>{targetSoItem?.upc}</span>
                  <div className="on-po">{onPo ? ` (on PO)` : ''}</div>
                </div>
              );
            })}
          </div>
        );
      }
    },
    {
      title: 'AVAIL INV',
      key: 'inventory-qty',
      className: 'empty-cell',
      align: 'center' as const,
      render: (_, poItem) => {
        const state = upcItems.find((upcItem) => upcItem.id === poItem.soItemId);

        return (
          <div>
            {state?.splits?.map((subProd) => {
              const targetSoItem = poItem.products.find(
                (product) => product.id === subProd.productId
              );

              const inventory = targetSoItem?.inventory.find(
                (dc) => dc.dcCode === item.salesOrder?.distributionCenter?.code
              )?.inventoryOnHand;

              return (
                <div className="sub-row" key={`${targetSoItem?.id}-${targetSoItem?.upc}`}>
                  {!!inventory &&
                    `${inventory >= 0 ? inventory : 0} ${poItem.submittedUnitOfMeasure}`}
                  {!inventory && (
                    <AlloyTooltip title="Inventory level unavailable">
                      <QuestionCircleOutlined className="unknown" />
                    </AlloyTooltip>
                  )}
                </div>
              );
            })}
          </div>
        );
      }
    },
    {
      title: 'Custom Qty',
      key: 'custom-qty',
      className: 'empty-cell',
      width: '160px',
      render: (_, poItem) => {
        const upcItem = upcItems.find((upcItem) => upcItem.id === poItem.soItemId);
        const totalQty =
          upcItem?.splits.reduce((total, split) => (total += split.acceptedUnits), 0) || 0;
        const overTotal = totalQty > (poItem.quantityOrdered || 0);

        return (
          <div>
            {upcItem?.splits?.map((state) => {
              const targetSoItem = poItem.products.find(
                (product) => product.id === state.productId
              );

              return (
                <div className="sub-row" key={`${targetSoItem?.id}-${targetSoItem?.upc}`}>
                  <AlloyInput
                    type="number"
                    onChange={(e) => {
                      upcItem.touched = true;
                      state.acceptedUnits = Number(e.target.value);
                      if (onChange && soSplit) onChange(soSplit);
                    }}
                    className={clsx(overTotal ? 'warning' : '', 'qty_input')}
                    disabled={!state?.enabled}
                    value={state.acceptedUnits}
                    defaultValue={state?.acceptedUnits || 0}
                  />
                  <div className="uom">{poItem.submittedUnitOfMeasure}</div>
                </div>
              );
            })}
          </div>
        );
      }
    },
    {
      title: '',
      key: 'select',
      className: 'empty-cell',
      width: '50px',
      render: (_, poItem) => {
        const upcItem = upcItems.find((upcItem) => upcItem.id === poItem.soItemId);

        return (
          <div>
            {upcItem?.splits?.map((state) => {
              return (
                <div className="sub-row" key={`${poItem.id}-${state.productId}`}>
                  <AlloyCheckbox
                    checked={state?.enabled}
                    onChange={() => {
                      upcItem.touched = true;
                      if (state.enabled) {
                        state.acceptedUnits = 0;
                      }
                      state.enabled = !state.enabled;
                      if (onChange && soSplit) onChange(soSplit);
                    }}
                  />
                </div>
              );
            })}
          </div>
        );
      }
    }
  ];

  return (
    <AlloyTable
      className="item-table"
      pagination={false}
      columns={columns}
      rowKey={(poItem) => poItem.id}
      bordered={true}
      dataSource={item.purchaseOrderItems}
      summary={(rows) => {
        return (
          <Summary.Row className="summary">
            <Cell index={0} colSpan={1}></Cell>
            <Cell index={1} colSpan={1} className="center">
              {rows.reduce((total, product) => (total += product.quantityOrdered || 0), 0)}{' '}
              {rows[0]?.submittedUnitOfMeasure}
            </Cell>
            <Cell index={2} colSpan={1}></Cell>
            <Cell index={3} colSpan={1}></Cell>
            <Cell index={4} colSpan={1} className="center">
              {rows.reduce((total, poItem) => {
                const upcItem = upcItems.find((upcItem) => upcItem.id === poItem.soItemId);

                const rowTotal =
                  upcItem?.splits.reduce((total, split) => {
                    if (split.enabled) return (total += split.acceptedUnits);
                    else return total;
                  }, 0) || 0;

                return (total += rowTotal);
              }, 0)}{' '}
              {rows[0]?.submittedUnitOfMeasure}
            </Cell>
            <Cell index={5} colSpan={1}></Cell>
          </Summary.Row>
        );
      }}
    />
  );
};
