import React, { useMemo, useState } from 'react';
import s from './Graph.module.scss';
import { FiscalCalendarWeekInputWithWeekOnly } from 'components/PeriodCalendar/helpers';
import { chunk, groupBy, union } from 'lodash-es';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import { useQuery } from '@apollo/client';
import {
  ChargebacksReportDetailsByPeriodForGraphDocument,
  ChargebacksReportDetailsByPeriodForGraphQuery
} from './gql/__generated__/chargebacksReportDetailsByPeriodForGraph.query';
import currency from 'currency.js';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid,
  TooltipProps,
  Label
} from 'recharts';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { getColorWithApproximation } from 'common/helpers/palette';
import { notEmpty } from 'common/helpers/notEmpty';
import clsx from 'clsx';
import { ChargebackDimension } from 'graphql/__generated__/types';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import {
  formatCurrency,
  parseYearWeek,
  stringifyBackendValue,
  stringifyYearWeek,
  stringifyYearWeekForGraph
} from 'pages/Chargebacks/helpers';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import { useChargebacksFiltersFromQueryParam } from '../Filters/hooks';
import { ALL_AVAILABLE_CHARGEBACK_DIMENSIONS, filterChargebacksData } from '../Filters/helpers';
import { useDeepCompareEffect } from 'use-deep-compare';
import { yearWeekSorter } from 'common/helpers/periodHelper';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

type ChargebackReportByPeriod =
  ChargebacksReportDetailsByPeriodForGraphQuery['chargebackDetailsReportsByPeriod'][number];

// TODO: fetch from BE?
const DEFAULT_BUS = ['FLNA', 'Gatorade', 'PBC', 'Quaker'];

const AXIS_COLOR = '#B1B1B1';
const __name__ = '__name__';

type AvailableDimensions = keyof Pick<
  ChargebackReportByPeriod,
  'distributionCenter' | 'issueType' | 'shipTo' | 'status'
>;

const dimensionToField: { [K in ChargebackDimension]?: AvailableDimensions } = {
  DISTRIBUTION_CENTER: 'distributionCenter',
  ISSUE_TYPE: 'issueType',
  STATUS: 'status',
  SHIP_TO: 'shipTo'
} as const;

const dimensions: { label: string; value: ChargebackDimension }[] = [
  {
    label: 'Distribution Center',
    value: 'DISTRIBUTION_CENTER'
  },
  {
    label: 'Issue Type',
    value: 'ISSUE_TYPE'
  },
  {
    label: 'Ship-To',
    value: 'SHIP_TO'
  },
  {
    label: 'Status',
    value: 'STATUS'
  }
] as const;

const getBarId = (name: string) => `${name}_bar_chart`;

// Fetch everything upfront – at least no fetching later.
const GRAPH_DIMENSIONS: ChargebackDimension[] = [
  'BUSINESS_UNIT',
  'ISSUE_TYPE',
  'STATUS',
  'DISTRIBUTION_CENTER',
  'SHIP_TO'
];
// We also must fetch all dimensions which are fetched in table, otherwise we won't split data properly.
// TODO: decide if we want to fetch only selected table dimensions
const GRAPH_NECESSARY_DIMENSIONS = union(GRAPH_DIMENSIONS, ALL_AVAILABLE_CHARGEBACK_DIMENSIONS);

export const Graph = ({
  fiscalCalendarWeeks
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
}) => {
  const [selectedFieldDimensions] = useChargebacksFiltersFromQueryParam();
  const [disabledSeries, setDisabledSeries] = useState<string[]>([]);

  const handleLegendClick = (dataKey: string) => {
    if (disabledSeries.includes(dataKey)) {
      setDisabledSeries(disabledSeries.filter((el) => el !== dataKey));
    } else {
      setDisabledSeries((prev) => [...prev, dataKey]);
    }
  };

  const [dimension, setDimension] = useQueryParam(
    'graph_dimension',
    withDefault(StringParam, 'ISSUE_TYPE')
  );
  const selectedField = useMemo(
    () => dimensionToField[dimension as ChargebackDimension] || 'issueType',
    [dimension]
  );

  const chargebacksGraphs = useQuery(ChargebacksReportDetailsByPeriodForGraphDocument, {
    variables: {
      dimensions: GRAPH_NECESSARY_DIMENSIONS,
      filters: {
        fiscalCalendarWeeks,
        countryCode: 'US'
      }
    }
  });

  const [bu, setBu] = useQueryParam('graph_bu', withDefault(StringParam, 'all'));

  useDeepCompareEffect(
    () => setDisabledSeries([]),
    [fiscalCalendarWeeks, dimension, selectedFieldDimensions.filters]
  );

  const allBusSelected = bu === 'all';

  const { graphData, isGraphEmpty, series, areMultipleYearsSelected, grandTotal } = useMemo(() => {
    const fiscalData = chargebacksGraphs.data?.chargebackDetailsReportsByPeriod || [];

    const areMultipleYearsSelected = (fiscalCalendarWeeks || []).some(
      (x) => x.year !== fiscalCalendarWeeks?.[0]?.year
    );

    const filteredByBuData = fiscalData.filter(
      (x) => allBusSelected || (x.businessUnit || '').toLocaleLowerCase() === bu.toLocaleLowerCase()
    );

    const filteredData = filterChargebacksData(filteredByBuData, selectedFieldDimensions.filters, [
      'businessUnit' // We are NOT filtering by business unit – we want to show all of them, always.
    ]);

    const allReasons = [...new Set(fiscalData.map((x) => x[selectedField]).filter(notEmpty))].sort(
      (a, b) => safeLocaleCompare(a, b)
    );

    const legendColors: { [key: string]: string } = {};
    allReasons.forEach((x, idx) => {
      legendColors[x] = getColorWithApproximation(idx, allReasons.length)?.color;
    });

    const groupedData = groupBy(filteredData, (x) =>
      allBusSelected ? x.businessUnit : stringifyYearWeek(x.fiscalCalendarWeek)
    );

    const calculateGraphData = (name: string, items: ChargebackReportByPeriod[]) => {
      const result: { [key: string]: number } = {};
      items.forEach((item) => {
        const field = item[selectedField];
        if (field) {
          result[field] = currency(result[field] || 0).add(item.financialCharge).value;
        }
      });
      return { ...result, [__name__]: name };
    };

    const unsortedGraphData =
      bu === 'all'
        ? DEFAULT_BUS.map((unit) => calculateGraphData(unit, groupedData[unit] || []))
        : fiscalCalendarWeeks.map((weekYear) => {
            const name = stringifyYearWeek(weekYear);
            return calculateGraphData(name, groupedData[name] || []);
          });

    const totals = unsortedGraphData.reduce(
      (accumulator, currentObject) => {
        for (const [key, value] of Object.entries(currentObject)) {
          // Ensure we use currency instances for addition
          if (accumulator[key] !== undefined) {
            accumulator[key] = accumulator[key].add(value);
          } else {
            accumulator[key] = currency(value);
          }
        }
        return accumulator;
      },
      {} as { [key: string]: currency }
    );

    const grandTotal = Object.values(totals).reduce((acc, val) => {
      return acc.add(val);
    }, currency(0)).value;

    // We are reversing series so it matches alphabetical ordering, where first entry is on top
    // and last is on the bottom
    const series = [...new Set(filteredData.map((x) => x[selectedField]).filter(notEmpty))]
      .map((name: string) => ({
        name,
        color: legendColors[name],
        totalValue: totals[name].value
      }))
      .sort((a, b) => safeLocaleCompare(a.name, b.name))
      .reverse();

    const graphData = unsortedGraphData.sort((a, b) =>
      allBusSelected
        ? safeLocaleCompare(a[__name__], b[__name__])
        : yearWeekSorter(parseYearWeek(a[__name__]), parseYearWeek(b[__name__]))
    );

    // Each object in graph has "__name__", but everything else is optional
    const isGraphEmpty = !graphData.some((obj) => Object.keys(obj).length > 1);

    return { graphData, series, areMultipleYearsSelected, isGraphEmpty, grandTotal };
  }, [
    chargebacksGraphs.data?.chargebackDetailsReportsByPeriod,
    fiscalCalendarWeeks,
    selectedFieldDimensions.filters,
    bu,
    allBusSelected,
    selectedField
  ]);

  return (
    <div>
      <div className={s.graph_top}>
        <h2 className={s.title}>
          Chargebacks by{' '}
          <AlloySelect
            value={dimension}
            onChange={(value) => {
              setDimension(value);
            }}
            variant="borderless"
            dropdownStyle={{ minWidth: '150px', fontWeight: 'bold' }}
            options={dimensions}
            className={s.borderlessSelect}
          />
        </h2>
        <div className={s.selector}>
          <AlloySegmented
            value={bu}
            onChange={(value) => setBu(value)}
            options={[
              {
                label: 'All BUs',
                value: 'all'
              },
              ...DEFAULT_BUS.map((bu) => ({
                label: bu,
                value: bu
              }))
            ]}
          />
        </div>
      </div>
      <AlloySpin spinning={chargebacksGraphs.loading}>
        <CustomLegend
          handleLegendClick={handleLegendClick}
          series={series}
          disabledSeries={disabledSeries}
          grandTotal={grandTotal}
          key={selectedField}
        />
        <div style={{ width: '100%', height: '360px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 0,
                right: 0,
                left: 20,
                bottom: allBusSelected ? 5 : areMultipleYearsSelected ? 75 : 25
              }}
              maxBarSize={320}
            >
              <CartesianGrid horizontal={true} vertical={false} stroke="#dcdcdc" strokeWidth={1} />
              <XAxis
                dataKey={__name__}
                tickFormatter={(value) =>
                  allBusSelected
                    ? value
                    : stringifyYearWeekForGraph(value, areMultipleYearsSelected)
                }
                angle={allBusSelected ? 0 : -90}
                textAnchor={allBusSelected ? 'middle' : 'end'}
                dx={allBusSelected ? 0 : -5}
                tickLine={false}
                stroke={AXIS_COLOR}
                tick={{ fill: 'black' }}
              >
                {/* Not sure if it's the best approach but this way we see the graph all the time */}
                {!chargebacksGraphs.loading && (chargebacksGraphs.error || isGraphEmpty) ? (
                  <Label
                    value={
                      chargebacksGraphs.error
                        ? 'Could not load data due to an error'
                        : 'No data available'
                    }
                    position="center"
                    style={{ transform: `translate(0px, -160px)` }}
                  />
                ) : (
                  <></>
                )}
              </XAxis>
              <YAxis
                stroke={AXIS_COLOR}
                tickLine={false}
                tickFormatter={(label) => formatCurrency(label, label < 100 && label !== 0 ? 1 : 0)}
                padding={{ top: 20 }}
                scale="linear"
              />
              <Tooltip
                content={<CustomTooltip />}
                wrapperStyle={{ zIndex: 10 }}
                cursor={{ fill: 'rgba(5,151,242, 0.2)' }}
              />
              {series.map(({ name, color }) => (
                <Bar
                  key={name}
                  dataKey={name}
                  stackId="a"
                  fill={color}
                  isAnimationActive={false}
                  hide={disabledSeries.includes(name)}
                  // Unfortunately currently it is not possible to pass testId, so we use className for that.
                  className={clsx(s.bar, `test_id_${name}_bar_chart`)}
                  id={getBarId(name)}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </AlloySpin>
    </div>
  );
};

const CustomLegend = ({
  handleLegendClick,
  series,
  disabledSeries,
  grandTotal
}: {
  handleLegendClick: (name: string) => void;
  series: { color: string; name: string; totalValue: number }[];
  disabledSeries: string[];
  grandTotal: number;
}) => {
  if (!series) return <></>;
  const [shouldFilter, setShouldFilter] = useState(true);

  // Adding a class directly to styles is NOT a React-way.
  // However, it looks to be the best approach if there are too many items. At least,
  // it works fast :)
  // Possibly, it could be achieved with some pub-sub state management? not sure.
  const handleMouseEnter = (value: string) => {
    const style = document.createElement('style');
    style.id = `chargebacks_hover_style_${value}`;
    style.innerHTML = `#${getBarId(value)} { opacity: 0.5; }`;
    document.head.appendChild(style);
  };

  const handleMouseLeave = (value: string) => {
    const style = document.getElementById(`chargebacks_hover_style_${value}`);
    if (style) {
      style.remove();
    }
  };

  const THRESHOLD_PERCENTAGE = 0.001;
  const threshold = currency(grandTotal).multiply(THRESHOLD_PERCENTAGE).value;
  const filteredLegend = useMemo(
    () => series.filter((x) => series.length <= 40 || x.totalValue >= threshold),
    [series, threshold]
  );
  const diff = series.length - filteredLegend.length;
  const displayLegend = useMemo(
    () => (shouldFilter ? [...filteredLegend] : [...series]).reverse(),
    [filteredLegend, series, shouldFilter]
  );

  return (
    <div className={s.legend}>
      {/* We reverse the array so order matches the "barchart" order */}
      {displayLegend.map((entry, index) => (
        <AlloyTooltip
          key={`item-${index}`}
          title={`Total value: ${formatCurrency(entry.totalValue)}`}
        >
          <button
            className={clsx(s.item, { [s.not_active]: disabledSeries.includes(entry.name) })}
            onClick={() => handleLegendClick(entry.name)}
            onMouseEnter={() => handleMouseEnter(entry.name)}
            onMouseLeave={() => handleMouseLeave(entry.name)}
          >
            <div className={s.color} style={{ backgroundColor: entry.color }} />
            {stringifyBackendValue(entry.name)}
          </button>
        </AlloyTooltip>
      ))}
      {filteredLegend.length !== series.length && (
        <AlloyTooltip title="Items which are less than 0.1% of total are hidden from legend by default, but are still displayed in graph.">
          <span>
            {shouldFilter && `...and ${diff} more.`}{' '}
            <AlloyButton type="link" onClick={() => setShouldFilter(!shouldFilter)} size="small">
              {shouldFilter ? 'Show' : 'Hide'}
            </AlloyButton>
          </span>
        </AlloyTooltip>
      )}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, '__name__'>) => {
  const COLUMN_LENGTH = 17;
  if (!(active && payload && payload.length)) return null;

  const total = payload
    .map((x) => x.value)
    .filter(notEmpty)
    .reduce((acc, val) => {
      return currency(acc).add(val);
    }, currency(0));

  const sortedPayload = [...payload].sort((a, b) => safeNumberComparator(b.value, a.value));

  const items = chunk(sortedPayload, COLUMN_LENGTH);
  const displayedItems = items.slice(0, 2);
  const displayedItemsFlat = displayedItems.flat();
  const lastItem = displayedItemsFlat[displayedItemsFlat.length - 1];
  const hiddenItemsLength = items.slice(2).flat().length;

  return (
    <div className={s.tooltip}>
      <div className={s.value}>
        {label}: {formatCurrency(total)}
      </div>
      <div className={s.items_wrapper}>
        {displayedItems.map((column, idx) => (
          <div key={idx}>
            {column.map((entry) => (
              <div key={`item-${entry.name}`} className={s.item}>
                <div className={s.color} style={{ backgroundColor: entry.color }} />
                {stringifyBackendValue(entry.name)}:{' '}
                <span className={s.value}>{formatCurrency(entry.value)}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      {hiddenItemsLength > 0 && lastItem && (
        <div className={s.more}>
          ...and {hiddenItemsLength} with less than {formatCurrency(lastItem.value)}
        </div>
      )}
    </div>
  );
};
